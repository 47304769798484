import * as types from './types'

export default {
  [types.LISTADO_SUCESSO] (state, payload) {
    state.lista = payload
  },

  [types.SELECIONAR] (state, payload) {
    let index = state.selecionadas.indexOf(payload)

    if (index === -1) {
      state.selecionadas.push(payload)
    } else {
      state.selecionadas.splice(index, 1)
    }
  },
  [types.SELECIONAR_RESET] (state) {
    state.selecionadas = []
  },

  [types.EXCLUIR_PREPARAR] (state) {
    state.excluindo = state.excluindo.concat(state.selecionadas)
    state.selecionadas = []
  },
  [types.EXCLUIR_RESET] (state) {
    state.excluindo = []
  },
  [types.EXCLUIR_SUCESSO] (state) {
    state.lista.items = state.lista.items.filter(p => state.excluindo.indexOf(p.id) === -1)
    state.lista.count -= state.excluindo.length
    state.excluindo = []
  }
}
