export const LISTAR = 'LISTAR'
export const LISTADO_SUCESSO = 'LISTADO_SUCESSO'

export const BUSCAR = 'BUSCAR'
export const BUSCAR_SUCESSO = 'BUSCAR_SUCESSO'

export const REGISTRAR_HISTORICO = 'REGISTRAR_HISTORICO'

export const ENVIAR_EMAIL = 'ENVIAR_EMAIL'
export const LISTAR_CONVERSA = 'LISTAR_CONVERSA'
export const RESET_CONVERSA = 'RESET_CONVERSA'

export const SALVAR = 'SALVAR'

export const SELECIONAR = 'SELECIONAR'
export const EXCLUIR = 'EXCLUIR'
export const EXCLUIR_SUCESSO = 'EXCLUIR_SUCESSO'
