export default class NegocioFilter {
    constructor (value) {
        Object.assign(this, value)
    }

    busca;
    conditions = [];

    toQueryParams () {
        const conditions = this.conditions.map(p => ({ ...p, value: p.value?.toString() ?? "" }))

        return {
            busca: this.busca,
            skip: this.skip,
            take: this.take,
            conditions
        }
    }
}
