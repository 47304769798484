import axios from 'axios'
import { useQuery, gql } from '@/shared/services/graphQLClient'
import { NotFoundError, UnauthorizedError } from '@/shared/errors'

var CancelToken = axios.CancelToken
var cancel

export default class {
    listar (params) {
        if (cancel) {
            cancel('Requisição sobreposta')
        }
        return axios
            .get('api/contatos', {
                params: {
                    ...params,
                    cache: false
                },
                cancelToken: new CancelToken(c => {
                    cancel = c
                })
            })
    }

    async buscar (id) {
        const query = gql`query GetContato($id: UUID!)
        {
            contato(id: $id) {
                id
                nome
                email
                documento
                cidade
                telefones
                avatar
                observacao
                responsavel {
                    id
                    nome
                    avatar
                }
                timeline {
                    eventType: __typename
                    timestamp                    
                    usuario: criadoPor {
                        id
                        nome
                        email
                        avatar
                    }
                    ... on AnotacaoRegistrada {
                        mensagem
                    }
                    ... on TarefaRegistrada {
                        id
                        titulo
                        venceEm
                        descricao
                        concluido
                    }
                    ... on ConversaRegistrada {
                        conversaId: id
                        titulo
                        descricao
                        origem
                    }
                    ... on CotacaoRegistrada {
                        id
                        modalidade
                        cidade
                        vidas
                    }
                }
            }
        }`

        try {
            const response = await useQuery(query, { id });
            const contato = response.contato

            if (contato == null)
                throw new NotFoundError()

            return contato
        }
        catch (ex) {
            if (ex.graphQLErrors?.some(p => p.extensions.code == "AUTH_NOT_AUTHORIZED"))
                throw new UnauthorizedError()

            throw ex
        }
    }

    async buscarExistente (email) {
        const response = await axios.get('api/contatos/checar-existente', { params: { email, cache: false } })

        return response.data.items.filter(p => p.email === email)[0]
    }

    registrarHistorico (anotacao) {
        return axios.post(`api/anotacoes`, anotacao)
            .then((response) => response.data)
    }

    salvar (contato) {
        let request = contato.id ?
            axios.put('api/contatos', contato) :
            axios.post('api/contatos', contato)

        return request.then(response => response.data)
    }

    excluir (ids) {
        return axios.delete('api/contatos', {
            data: {
                contatoIds: ids
            }
        })
    }

    avatar (contatoId, file) {
        let url = `api/contatos/${contatoId}/avatar`
        let formData = new FormData()
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        formData.append('file', file)

        return axios.put(url, formData, config).then(response => response.headers.location)
    }

    async importar (file) {
        let url = `api/contatos/importar`
        let formData = new FormData()
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        formData.append('file', file)

        const response = await axios.put(url, formData, config)

        return response.data
    }
}