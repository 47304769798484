import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import core from '@/pages/core/store'
import auth from '@/pages/auth/store'
import conta from '@/pages/conta/store'
import produto from '@/pages/produto/store'
import cotacao from '@/pages/cotacao/store'
import comissao from '@/pages/comissao/store'
import dashboard from '@/pages/home/store'
import crm from '@/pages/crm/contato/store'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    strict: debug,
    plugins: [createPersistedState({
        paths: [
            'auth.accessToken',
            'auth.idToken',
            'auth.expiresAt',
            'auth.returnUrl',
            'auth.cupom',
            'auth.cadastroState',
            'auth.tenant',
            'core.drawer.mini',
            'crm.negocios.filtro',
            'produto.filtro',
            'dashboard.tarefas.filtro',
            'dashboard.redeCredenciadaMapa.coordenadas',
            'dashboard.redeCredenciadaMapa.filtro'
        ]
    })],
    modules: {
        core,
        auth,
        conta,
        produto,
        cotacao,
        comissao,
        dashboard,
        crm
    }
})
