export class List extends Array {
    static fromObject (object) {
        const array = Object.entries(object).map(entry => ({ key: entry[0], ...entry[1] }))

        return List.from(array)
    }

    static fromMap (object) {
        const array = Object.entries(object).map(entry => ({ key: entry[0], value: entry[1] }))

        return List.from(array)
    }

    groupBy (keyGetter) {
        const map = new Map();

        this.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });

        return List.from(map).map(p => ({
            key: p[0],
            items: List.from(p[1])
        }));
    }

    distinct () {
        return this.filter((x, i, a) => a.indexOf(x) === i)
    }

    distinctBy (keyGetter) {
        return this.filter((x, i, a) => a.map(p => keyGetter(p)).indexOf(keyGetter(x)) === i)
    }

    sortBy (keyGetter) {
        return this.sort((a, b) => {
            const keyA = keyGetter(a)
            const keyB = keyGetter(b)

            if (typeof keyA === 'string')
                return keyA.localeCompare(keyB);

            return keyA > keyB ? 1 : -1;
        });
    }

    sortToStart (keyGetter) {
        const item = this.find(a => keyGetter(a))

        if (!item)
            return this

        return new List(item, ...this.filter(a => a !== item))
    }

    sortToEnd (keyGetter) {
        const item = this.find(a => keyGetter(a))

        if (!item)
            return this

        return new List(...this.filter(a => a !== item), item)
    }
}
