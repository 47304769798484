import axios from 'axios'
import { serialize } from '@/shared/services/serializeToFormData'

const baseUri = 'api/crm/conversas'

export default class {
    async buscar (conversaId) {
        const response = await axios.get(`${baseUri}/${conversaId}`, {
            params: { cache: false }
        })

        return response.data
    }

    async listar (payload, user) {
        const qs = new URLSearchParams(payload)
        const response = await axios.get(`${baseUri}?${qs}`, { params: { cache: false } })

        return response.data.map(p => ({
            ...p,
            lido: p.lidoPor.includes(user.id)
        }))
    }

    excluir (conversaId, mensagemId) {
        return axios.delete(`${baseUri}/${conversaId}?mensagemId=${encodeURIComponent(mensagemId)}`)
    }

    async enviar (payload) {
        const data = serialize(payload)

        data.append('destinatarios', JSON.stringify(payload.destinatarios))

        const config = { headers: { 'content-type': 'multipart/form-data' } }
        const response = await axios.post(`${baseUri}/${payload.conversaId}`, data, config)

        return response.data
    }
}