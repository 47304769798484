
export default {
    default: [
        {
            path: '/crm/contatos',
            component: () => import(/* webpackChunkName: "crm-chunk" */ './contato/ContatoLista')
        },
        {
            path: '/crm/contatos/:id',
            component: () => import(/* webpackChunkName: "crm-chunk" */ './contato/Contato')
        },
        {
            path: '/crm/conversas',
            component: () => import(/* webpackChunkName: "inbox-chunk" */ './conversa')
        },
        {
            name: 'negocio',
            path: '/crm/negocios/:negocioId?',
            component: () => import(/* webpackChunkName: "negocio-chunk" */ './negocio/pages/NegocioPage'),
            props: true
        },
        {
            path: '/crm/cross-selling',
            component: () => import(/* webpackChunkName: "negocio-chunk" */ './cross-selling/CrossSellingPage'),
        },
        {
            path: '/crm/dashboard',
            component: () => import(/* webpackChunkName: "negocio-chunk" */ './negocio/pages/dashboard/DashboardPage')
        },
        {
            path: '/crm/automacao',
            component: () => import(/* webpackChunkName: "negocio-chunk" */ './automacao/AutomacaoPage')
        }
    ],
    root: [
        {
            path: '/forms/u/:usuarioId',
            component: () => import(/* webpackChunkName: "crm-chunk" */ './contato/components/landing-page/LandingPage'),
            props: true
        }
    ]
}