export default {
    lista: [{
        key: 'SaudePF',
        urlKey: 'saude-pf',
        ramo: 'Saúde',
        nome: 'PF'
    },
    {
        key: 'SaudePme',
        urlKey: 'saude-pme',
        ramo: 'Saúde',
        nome: 'PME'
    },
    {
        key: 'SaudeAdesao',
        urlKey: 'saude-adesao',
        ramo: 'Saúde',
        nome: 'Adesão'
    },
    {
        key: 'OdontoFamiliar',
        urlKey: 'odonto-pf',
        ramo: 'Odonto',
        nome: 'PF'
    },
    {
        key: 'OdontoPme',
        urlKey: 'odonto-pme',
        ramo: 'Odonto',
        nome: 'PME'
    },
    {
        key: 'OdontoAdesao',
        urlKey: 'odonto-adesao',
        ramo: 'Odonto',
        nome: 'Adesão'
    }
    ],
    filterByRamo (ramo) {
        return this.lista.filter(p => p.ramo === ramo)
    },
    findByKey (key) {
        key = key.replace(/_/g, '').toLowerCase()
        return this.lista.filter(p => p.key.toLowerCase() === key || p.urlKey.toLowerCase() === key)[0]
    },
    nomeCompleto (key) {
        let modalidade = this.findByKey(key)
        return modalidade.ramo + ' ' + modalidade.nome
    },
    isAdesao (key) {
        return key.toLowerCase().endsWith('adesao')
    },
    descricao (key, administradora, entidade) {
        const suffix = administradora ? `${administradora} - ` : ''

        if (entidade)
            return `${suffix}${entidade}`
        else
            return `${suffix}${this.nomeCompleto(key)}`
    }
}