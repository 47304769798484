import store from '@/store'

const registerModule = (to, from, next) => {
    let moduleName = 'suporte'
    if (store._modules.root._children[moduleName]) {
        return next()
    }

    return import( /* webpackChunkName: "suporte-chunk" */ '@/pages/suporte/store').then(m => {
        store.registerModule(moduleName, m.default)
        next()
    })
}

export default [{
    path: '/suporte',
    component: () => import( /* webpackChunkName: "suporte-chunk" */ './Dashboard'),
    beforeEnter: (to, from, next) => {
        const user = store.getters['conta/usuario']
        if (user && user.email.endsWith('trindadetecnologia.com.br')) {
            return registerModule(to, from, next)
        }

        return next('/')
    }
}]