import {
    ApolloLink,
    Observable,
} from '@apollo/client/core';
import { print } from 'graphql';
import { createClient } from 'graphql-sse';

export class SSELink extends ApolloLink {
    client;

    constructor (options) {
        super();
        this.client = createClient(options);
    }

    request (operation) {
        return new Observable((sink) => {
            return this.client.subscribe(
                { ...operation, query: print(operation.query) },
                {
                    next: sink.next.bind(sink),
                    complete: sink.complete.bind(sink),
                    error: sink.error.bind(sink),
                },
            );
        });
    }
}
