export const USUARIO_LISTAR = 'USUARIO_LISTAR'
export const USUARIO_LISTAR_SUCCESS = 'USUARIO_LISTAR_SUCCESS'

export const PERFIL_ALTERAR = 'PERFIL_ALTERAR'
export const CONVITE_REENVIAR = 'CONVITE_REENVIAR'

export const USUARIO_REMOVER = 'USUARIO_REMOVER'
export const USUARIO_REMOVER_SUCCESS = 'USUARIO_REMOVER_SUCCESS'

export const USUARIO_INCLUIR = 'USUARIO_INCLUIR'
export const USUARIO_ATUALIZAR = 'USUARIO_ATUALIZAR'
export const USUARIO_INCLUIR_SUCCESS = 'USUARIO_INCLUIR_SUCCESS'