import atual from './atual'
import lista from './lista'

export default {
  namespaced: true,
  modules: {
    atual,
    lista
  }
}
