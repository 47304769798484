import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import tarefas from '../../tarefa/store'
import conversas from '../../conversa/store'
import negocios from '../../negocio/store'

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        tarefas,
        conversas,
        negocios
    }
}