import Vue from 'vue'
import store from '@/store'
import packageInfo from '@/../package.json'

const production = process.env.VUE_APP_ENV === 'production'

import VueTagManager from "vue-tag-manager"

export function createDataLayer (user) {
    const dataLayer = {}

    if (user?.id) {
        dataLayer.userId = user.id
        dataLayer.userName = user.nome
        dataLayer.userEmail = user.email
        dataLayer.assinaturaId = user.corretoraId
        dataLayer.assinaturaTrial = user.assinatura.trial
        dataLayer.assinaturaPlano = user.assinatura.plano.nome
        dataLayer.userRole = user.perfil
        dataLayer.appVersion = packageInfo.version
    }

    return dataLayer
}

setTimeout(() => {
    if (!production)
        return

    const user = store.getters['conta/usuario']
    const dataLayer = createDataLayer(user)

    Vue.use(VueTagManager, {
        gtmId: 'GTM-MQHSKD2',
        dataLayer
    })
}, 500);