import axios from 'axios'
import { gql, useQuery } from '@/shared/services/graphQLClient'

export default class {
    registrar (tarefa) {
        return axios
            .post(`api/tarefas`, tarefa)
            .then(response => response.data)
            .then(data => ({
                id: data.tarefaId,
                ...tarefa
            }))
    }

    async listar (filter) {
        const query = gql`
            query Tarefas($after: String, $input: TarefaQueryInput!) {
                tarefas(input: $input, after: $after) {
                    nodes {
                        id
                        descricao
                        concluido
                        titulo
                        venceEm
                        contato {
                            id
                            nome
                            avatar
                        }
                        responsavel {
                            id
                            nome
                            avatar
                        }
                        negocio {
                            id
                            nome
                            etapa {
                                id
                                nome
                            }
                        }
                    },
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }`

        filter.input.status = filter.input.status?.toUpperCase()

        const { tarefas } = await useQuery(query, filter)

        return tarefas
    }

    atualizar (tarefa) {
        return axios.put(`api/tarefas/${tarefa.id}`, tarefa)
    }

    alternar (payload) {
        return axios.put(`api/tarefas/${payload.tarefaId}/alternar`, {
            tarefaId: payload.tarefaId,
            concluida: payload.concluido // TODO: Padronizar endpoint.
        })
    }

    excluir (tarefaId) {
        return axios.delete(`api/tarefas/${tarefaId}`)
    }
}