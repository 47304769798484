<template>
    <div class="page-content">
        <slot name="toolbar">
            <v-app-bar app dark flat color="primary" :hide-on-scroll="hideOnScroll" :scroll-off-screen="scrollOffScreen">
                <app-drawer-button v-if="!navigateBack"></app-drawer-button>
                <v-btn icon @click="back" title="Voltar" v-else>
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title class="mr-8">
                    <slot name="title">{{title}}</slot>
                </v-toolbar-title>
                <slot name="search"></slot>
                <v-spacer></v-spacer>
                <slot name="actions"></slot>
                <slot name="extension" slot="extension"></slot>
            </v-app-bar>
        </slot>

        <v-main v-if="loading">
            <v-container class="fill-height" fluid>
                <v-row justify="center" align="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
            </v-container>
        </v-main>

        <v-main v-else>
            <slot></slot>
        </v-main>
    </div>
</template>

<script>
    const setTitle = (title) => {
        document.title = 'Painel do Corretor'
        if (title) {
            document.title = title + ' - Painel do Corretor'
        }
    }
    export default {
        name: 'page-content',
        props: {
            title: String,
            loading: Boolean,
            hideOnScroll: Boolean,
            scrollOffScreen: Boolean,
            navigateBack: [Boolean, String, Function]
        },
        methods: {
            back () {
                const type = typeof this.navigateBack

                switch (type) {
                    case "function": return this.navigateBack()
                    case "string": return this.$navigate(this.navigateBack)
                    default: return history.back()
                }
            }
        },
        mounted () {
            setTitle(this.title)
        },
        watch: {
            title (newTitle) {
                setTitle(newTitle)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-content {
        flex: 1;
        display: flex;
        > main {
            flex: 1;
            display: flex;
        }
    }
</style>