import axios from 'axios'
import LRUCache from 'lru-cache'
const cacheEnable = true
const cache = new LRUCache()

axios.interceptors.request.use(request => {
    // On request, return the cached version, if any
    // Only cache GET requests
    if (cacheEnable && request.method === 'get') {
        let key = getCacheKey(request);
        let cached = cache.get(key)

        if (cached) {
            request.data = cached

            // console.debug(`"${request.url}" served from cache:`, cached)

            // Set the request adapter to send the cached response and prevent the request from actually running
            request.adapter = () => {
                return Promise.resolve({
                    data: cached,
                    status: request.status,
                    statusText: request.statusText,
                    headers: request.headers,
                    config: request,
                    request: request
                })
            }
        }
    }

    return request
}, error => Promise.reject(error))

axios.interceptors.response.use(response => {
    // On response, set or delete the cache
    if (cacheEnable) {
        let key = getCacheKey(response.config);
        let cacheOptOut = response.config.params && response.config.params.cache === false

        if (cacheOptOut || response.config.method !== 'get') {
            deleteCache(key, response);
        } else {
            cache.set(key, response.data)
        }
    }

    return response
}, error => Promise.reject(error))

const getCacheKey = (response) => {
    let key = response.url;

    if (response.params) {
        key += '?' + param(response.params);
    }

    if (response.headers.Authorization) {
        key += response.headers.Authorization;
    }

    return key;
}

const deleteCache = (key, response) => {
    // For post, put or delete, just delete the cached version of the url
    // e.g. posting to `/users` would delete the `/users` cache, so when you ask for users again you get the real version
    cache.del(response.config.url);
    // also, when making a post,put or delete request to `/users/1`, would try to delete the `/users` for the same reason
    const uri = key.replace(process.env.VUE_APP_API_ROOT, '');
    const parentUri = /(.*)\/([a-z0-9-]+)\/?$/ig.exec(uri);
    if (parentUri) {
        cache.del(`${process.env.VUE_APP_API_ROOT}${parentUri[1]}`);
    }
    // Delete similar url that just have query string diferences
    // Specially useful for things like Laravel's `with` param
    // e.g. `/users?with=permissions` will get cached but the post to `/users` wont remove it from the cache
    // so I look all the cached url's and try to match it without the querystrings
    const urls = cache.keys(); // Object.keys(cache.debug())
    for (const _url of urls) {
        if (_url.match(/^[^?]+/)[0] === response.config.url) {
            cache.del(_url);
        }
    }
}

const param = (obj) => {
    return Object.entries(obj)/*.filter(p => p[1])*/.map(p => p[0]).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
}

// Referência: https://gist.github.com/javisperez/4bb09e0437994a659bbcd06f90eeebbf