import NegicioApi from '../api'

var api = new NegicioApi()

const toMap = (array, p = 'id') => array.length ? Object.assign(...array.map(entry => ({ [entry[p]]: entry }))) : {}

export default {
    async listar ({ state, commit, dispatch }, payload) {
        commit('SET_FILTRO', payload)

        if (!state.etapas.length) {
            await dispatch('listarEtapas')
            await dispatch('listarEtiquetas')
        }

        const items = await api.listarPorEtapas(state.etapas, payload)

        commit('SET_BOARD', items)

        return items
    },

    async scroll ({ commit }, { etapas, filtro }) {
        const negocios = await api.listarColuna({ ...filtro, etapa: etapas[0] })

        commit('ADD_TO_BOARD', { negocios, etapa: etapas[0] })

        return negocios
    },

    async selecionar ({ commit }, id) {
        commit('SET_NEGOCIO', undefined)
        const negocio = await api.get(id)
        commit('SET_NEGOCIO', negocio)

        return negocio
    },

    async listarEtiquetas ({ commit }) {
        const data = await api.listarEtiquetas()
        const items = toMap(data)

        commit('SET_ETIQUETAS', items)

        return items
    },

    async listarEtapas ({ commit }) {
        const items = await api.listarEtapas()

        commit('SET_ETAPAS', items)

        return items
    },

    async salvar (context, payload) {
        const response = await api.salvar(payload)
        const data = { id: response.data.negocioId, ...payload }

        context.commit('ADD_NEGOCIO', data)

        return data
    },

    async selecionarNegocio ({ commit }, payload) {
        commit('SELECIONAR_NEGOCIO', payload)
    },

    async desselecionarNegocios ({ commit }) {
        commit('DESSELECIONAR_NEGOCIOS')
    },

    async excluirNegocios ({ commit }, payload) {
        return api
            .excluirNegocios(payload)
            .then(() => commit('DELETE_NEGOCIOS', payload))
    },

    async mover (context, payload) {
        context.commit('MOVER_NEGOCIO', payload)

        const actual = await api.get(payload.id)
        const negocio = { ...actual, etapa: payload.etapa }

        return api
            .salvar(negocio)
            .catch(error => context.dispatch('NOTIFY', error, { root: true }))
    },

    async salvarEtiqueta ({ commit, dispatch }, payload) {
        return api
            .salvarEtiqueta(payload)
            .then(commit('SAVE_ETIQUETA', payload))
            .catch(error => dispatch('NOTIFY', error, { root: true }))
    },

    async excluirEtiqueta ({ commit, dispatch }, payload) {
        return api
            .excluirEtiqueta(payload)
            .then(() => commit('DELETE_ETIQUETA', payload))
            .catch(error => dispatch('NOTIFY', error, { root: true }))
    },

    async salvarEtapa ({ commit, dispatch }, payload) {
        return api
            .salvarEtapa(payload)
            .then(() => commit('SAVE_ETAPA', payload))
            .catch(error => dispatch('NOTIFY', error, { root: true }))
    },

    async excluirEtapa ({ commit, dispatch }, payload) {
        return api
            .excluirEtapa(payload)
            .then(() => commit('DELETE_ETAPA', payload))
            .catch(error => dispatch('NOTIFY', error, { root: true }))
    },

    async adicionarAnotacao ({ state, commit }, payload) {
        const response = await api.adicionarAnotacao({
            ...payload,
            usuario: payload.criadoPor,
            negocioId: state.negocio.id,
            contatoId: state.negocio.contato?.id
        })

        commit('ANOTACAO_ADICIONADA', {
            id: response.data.anotacaoId,
            ...payload
        })
    },

    async adicionarAnotacaoAudio ({ state, commit }, payload) {
        const form = new FormData()

        const item = {
            ...payload,
            negocioId: state.negocio.id,
            contatoId: state.negocio.contato?.id
        }

        for (const key in item)
            form.append(key, item[key])

        const { data: response } = await api.adicionarAnotacaoAudio(form)

        commit('ANOTACAO_AUDIO_ADICIONADA', {
            ...item,
            id: response.id,
            audio: window.URL.createObjectURL(item.file),
        })
    }
}