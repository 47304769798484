export const FILTRO_ATUALIZADO = 'FILTRO_ATUALIZADO'
export const COORDENADA_ATUALIZADA = 'COORDENADA_ATUALIZADA'

export const PLANOS_ATUALIZAR = 'PLANOS_ATUALIZAR'
export const PLANOS_ATUALIZADOS = 'PLANOS_ATUALIZADOS'

export const CREDENCIADOS_LOCALIZAR = 'CREDENCIADOS_LOCALIZAR'
export const CREDENCIADOS_LOCALIZADOS = 'CREDENCIADOS_LOCALIZADOS'

export const USUARIO_RECUPERADO = 'USUARIO_RECUPERADO'
