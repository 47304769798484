export const percentage = function (value, decimals = 2) {
    if (!value) value = 0

    value = value * 100
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals) + '%'
}


export const distinct = (x, i, a) => {
    return a.indexOf(x) === i
}

export const debounce = (fn, time) => {
    let timeout

    return function () {
        const functionCall = () => fn.apply(this, arguments)

        clearTimeout(timeout)
        timeout = setTimeout(functionCall, time)
    }
}

export const isLocalhost = () => Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export const uriEncode = (data) => {
    if (!data) return undefined;

    const jsonData = JSON.stringify(data)
    const encodedData = Buffer.from(jsonData, 'utf8').toString('base64')

    return encodeURIComponent(encodedData)
}

export const uriDecode = (encodedUri) => {
    if (!encodedUri) return undefined;

    const base64Data = decodeURIComponent(encodedUri)
    const stringData = Buffer.from(base64Data, 'base64').toString('utf8')

    return JSON.parse(stringData)
}

export default {
    percentage,
    distinct,
    debounce,
    uriDecode,
    uriEncode
}

Object.assign(String.prototype, {
    normalizeToSearch () {
        return this.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    equalsSearch (otherString) {
        return this.normalizeToSearch() === otherString.normalizeToSearch()
    }
})
