export default (item, queryText, itemText) => {
    const hasValue = val => (val != null ? val.toString() : '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

    const text = hasValue(itemText)
    const query = hasValue(queryText)

    return text.toString()
        .toLowerCase()
        .indexOf(query) > -1
}
