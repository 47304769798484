<template>
    <v-select
        dense
        hide-details
        item-text="nome"
        item-value="id"
        menu-props="offset-y"
        :items="$user.tenants"
        :value="value"
        @change="selecionar"
        v-bind="$attrs">

        <template v-slot:item="{  item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">

                <v-list-item-content>
                    {{item.nome}}
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip color="red"
                        text-color="white"
                        x-small
                        v-if="!item.ativo">Inativa</v-chip>
                </v-list-item-action>
            </v-list-item>
        </template>
    </v-select>

</template>

<script>
    export default {
        computed: {
            value () {
                return this.$store.state.auth.tenant || this.$user.tenants[0].id
            }
        },
        methods: {
            async selecionar (tenantId) {
                this.$store.commit('SELECIONAR_TENANT', tenantId)
                location.reload()
            }
        }
    }
</script>