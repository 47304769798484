import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import usuarios from '../components/usuario-page/store'
import equipes from '../components/equipe-page/store'

class LoadingManager {
    constructor () {
        this.loadingKeys = []
    }

    start (key) {
        this.loadingKeys.push(key)
    }

    complete (key) {
        let index = this.loadingKeys.indexOf(key)
        this.loadingKeys.splice(index, 1)
    }

    isLoading (key) {
        return this.loadingKeys.indexOf(key) > -1
    }
}

const initialState = {
    loadingManager: new LoadingManager(),
    usuario: undefined,
    assinatura: undefined,
    operadorasSelecionadas: undefined
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
    modules: {
        usuarios,
        equipes
    }
}
