<template>
    <v-app-bar-nav-icon @click.stop="click"></v-app-bar-nav-icon>
</template>

<script>
    import { debounce } from '@/shared/functions'

    export default {
        name: 'app-drawer-button',
        data () {
            return {
                mobileBreakpoint: 960,
                persistent: false
            }
        },
        computed: {
            drawer: {
                get () {
                    return this.$store.state.core.drawer.opened
                },
                set (value) {
                    if (this.$store.state.core.drawer.opened !== value) {
                        this.$store.commit('TOGGLE_DRAWER', value)
                    }
                }
            },
            mini: {
                get () {
                    return this.$store.state.core.drawer.mini
                },
                set (value) {
                    if (this.$store.state.core.drawer.mini !== value) {
                        this.$store.commit('TOGGLE_DRAWER_MINI', value)
                    }
                }
            }
        },
        methods: {
            click () {
                if (this.persistent) {
                    this.mini = !this.mini
                } else {
                    this.drawer = !this.drawer
                }
            },
            onResize: debounce(function () {
                this.persistent = window.innerWidth > this.mobileBreakpoint
            }, 250)
        },
        mounted () {
            this.onResize()
            window.addEventListener('resize', this.onResize)
        },

        beforeDestroy () {
            window.removeEventListener('resize', this.onResize)
        }
    }
</script>