import {
    ApolloClient,
    createHttpLink,
    gql,
    InMemoryCache,
    split
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import store from '../../../store';
import { SSELink } from './linkSse';

const uri = `${process.env.VUE_APP_API_ROOT}graphql`
const apolloClient = () => {
    const authLink = setContext((_, { headers }) => {
        const token = store.state.auth.accessToken
        const tenant = store.state.auth.tenant

        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
                'X-Tenant': tenant
            }
        }
    })

    const httpLink = createHttpLink({ uri })
    const sseLink = new SSELink({
        url: uri,
        headers: () => {
            return {
                Authorization: `Bearer ${store.state.auth.accessToken}`
            }
        }
    })

    const link = split(({ query }) => {
        const definition = getMainDefinition(query)

        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    }, sseLink, httpLink)

    return new ApolloClient({
        link: authLink.concat(link),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache' //TODO: Buscar meio de usar o cache do apollo
            }
        }
    })
}
const client = apolloClient()

const useQuery = async (query, variables) => {
    const result = await client.query({ query, variables })

    return result.data
}

const subscribeEnabled = false
const useSubscription = (query, variables, next) => {
    if (!subscribeEnabled)
        return;

    const observer = client.subscribe({ query, variables })

    return observer.subscribe({
        next (payload) {
            next(Object.values(payload?.data)[0])
        }
    })
}

export { gql, useQuery, useSubscription };

