import * as types from './types'
import produtoService from '@/shared/services/produto'

export default {
    [types.PLANOS_ATUALIZAR] ({
        state,
        commit
    }) {
        commit(types.PLANOS_ATUALIZADOS, undefined)

        produtoService
            .localizarPlanosPorRegiao(state.filtro, state.coordenadas.latitude, state.coordenadas.longitude)
            .then(response => commit(types.PLANOS_ATUALIZADOS, response.data))
    },

    [types.CREDENCIADOS_LOCALIZAR] ({
        state,
        commit,
        dispatch
    }) {
        commit(types.CREDENCIADOS_LOCALIZAR)

        produtoService
            .localizarCredeciadosProximos(state.filtro, state.coordenadas.latitude, state.coordenadas.longitude)
            .then(response => {
                let pontosAtuais = state.credenciados.map(p => p.latitude + p.longitude)
                let pontosNovos = response.data.map(p => p.latitude + p.longitude)
                let quantidadeAlterada = pontosAtuais.length !== pontosNovos.length
                let pontosAlterados = pontosNovos.some(p => pontosAtuais.indexOf(p) === -1)
                let houveAlteracao = quantidadeAlterada || pontosAlterados

                commit(types.CREDENCIADOS_LOCALIZADOS, {
                    credenciados: response.data,
                    houveAlteracao
                })

                dispatch(types.PLANOS_ATUALIZAR)
            })
    },

    [types.USUARIO_RECUPERADO]: {
        root: true,
        handler: (context, payload) => context.commit(types.USUARIO_RECUPERADO, payload)
    }
}