<template>

    <app-layout-error>
        <template v-slot:title>
            <img src="/img/logos/logotipo.svg" alt="Painel do Corretor" class="logotipo mb-5">
        </template>
        <p>
            Seu período de avaliação chegou ao fim.
            <br />Para continuar utilizando o Painel, basta ativar sua assinatura.
            <br> Caso tenha qualquer dúvida, por favor envie um e-mail
            <br /> para
            <a href="mailto:contato@paineldocorretor.com.br?subject=Painel do Corretor - Fim do período de avaliação">contato@paineldocorretor.com.br</a>
        </p>

        <app-tenant class="tenant-select" :menu-props="{ offsetY: true }" />

        <template v-slot:actions>
            <v-btn color="primary" v-if="$user.isAdmin" to="/conta/assinatura?action=ativar-plano">
                <v-icon class="mr-2">mdi-check</v-icon> Sim, quero ativar minha assinatura!
            </v-btn>
            <v-btn v-else disabled>Por favor, entre em contato com o administrador da conta</v-btn>
            <v-btn to="/conta/entrar" text>Sair</v-btn>
        </template>
    </app-layout-error>

</template>

<script>
    import AppTenant from './layout/app-menu/AppTenant.vue'
    export default {
        components: { AppTenant },
        mounted () {
            if (!this.$user.assinatura.expirada && !this.$route.query.skipRedirection)
                this.$router.push('/').catch(() => { });
        }
    }
</script>

<style lang="scss" scoped>
    img.logotipo {
        width: 350px;
    }

    .tenant-select {
        width: 250px;
        position: absolute;
        right: 0;
        top: 0;
    }
</style>