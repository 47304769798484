import Vue from 'vue'
import Board from '../models/Board';

export default {
    SET_BOARD (state, payload) {
        state.board = payload
    },
    ADD_TO_BOARD (state, payload) {
        const etapa = state.board.find(p => p.etapa.id == payload.etapa.id)

        etapa.negocios = etapa.negocios.concat(payload.negocios)
    },
    MOVE_TO_BOARD (state, payload) {
        const etapaAnterior = state.board.find(p => p.etapa.id == payload.etapaAnterior.id)
        const negocio = etapaAnterior.negocios.find(p => p.id === payload.aggregateId)

        if (!negocio)
            return

        const etapaPosterior = state.board.find(p => p.etapa.id == payload.etapaPosterior.id)
        etapaAnterior.negocios = etapaAnterior.negocios.filter(p => p.id !== negocio.id)
        etapaPosterior.negocios = [negocio].concat(etapaPosterior.negocios)
    },
    SET_ETAPAS (state, payload) {
        state.etapas = payload
    },
    SET_ETIQUETAS (state, payload) {
        state.etiquetas = payload
    },
    SET_FILTRO (state, payload) {
        state.filtro = payload
    },
    SET_NEGOCIO (state, payload) {
        state.negocio = payload
    },
    ADD_NEGOCIO (state, payload) {
        if (!state.board.length)
            return

        const board = new Board(state.board)

        board.setNegocio(payload)
    },
    SELECIONAR_NEGOCIO ({ negociosSelecionados }, payload) {
        const negocioIndex = negociosSelecionados.findIndex(n => n === payload)

        if (negocioIndex < 0)
            return negociosSelecionados.push(payload)

        negociosSelecionados.splice(negocioIndex, 1)
    },
    DESSELECIONAR_NEGOCIOS (state) {
        state.negociosSelecionados = []
    },
    MOVER_NEGOCIO (state, payload) {
        const board = new Board(state.board)

        board.setNegocio(payload, payload.posicao)
    },
    DELETE_NEGOCIOS (state, payload) {
        const board = new Board(state.board)

        board.removeNegocios(payload)

        state.negociosSelecionados = []
    },
    SAVE_ETIQUETA (state, payload) {
        const listaEtiquetas = Object.values(state.etiquetas)
        const etiquetaExistente = listaEtiquetas.find(e => payload.nome.equalsSearch(e.nome) && e.id !== payload.id)

        if (etiquetaExistente) {
            const etiquetaDuplicada = listaEtiquetas.find(e => e.id === payload.id)
            Vue.delete(state.etiquetas, etiquetaDuplicada.id)

            return
        }

        Vue.set(state.etiquetas, payload.id, payload)
    },
    DELETE_ETIQUETA (state, payload) {
        Vue.delete(state.etiquetas, payload.id)
    },
    SAVE_ETAPA (state, payload) {
        state.etapas = state.etapas.filter(p => p.id !== payload.id)

        if (payload.index > -1)
            state.etapas.splice(payload.index, 0, payload)
        else
            state.etapas.push(payload)
    },
    DELETE_ETAPA (state, payload) {
        state.etapas = state.etapas.filter(p => p.id !== payload.id)
    },
    ANOTACAO_ADICIONADA (state, payload) {
        state.negocio.timeline.unshift({
            ...payload,
            timestamp: payload.criadoEm,
            __typename: 'AnotacaoRegistrada'
        })
    },
    ANOTACAO_AUDIO_ADICIONADA (state, payload) {
        state.negocio.timeline.unshift({
            ...payload,
            timestamp: payload.criadoEm,
            __typename: 'AnotacaoAudioRegistrada'
        })
    }
}