import axios from 'axios'
import router from '../../router'
import encodeReturnUrl from '@/shared/encodeReturnUrl'
import url from './apiBaseUrl'

let retryCount = 0
const retryLimit = 5
const ignoreUrls = ['/conta/entrar', 'share']

axios.interceptors.response.use(
    (request) => request,
    (error) => {
        const path = router.currentRoute.path
        const ignore = ignoreUrls.some(url => path.indexOf(url) !== -1)
        const statusCode = !ignore && error.response?.status
        const method = error.config?.method.toLowerCase()

        if (error.message === 'Network Error' && method === 'get' && retryCount < retryLimit) {
            retryCount++
            console.log(`Network Error: ${retryCount}ª tentativa.`)
            return setTimeout(() => axios.request(error.config), retryCount * 500);
        }

        if (shouldRedirectOn(error))
            redirect(statusCode)

        throw error
    }
)

function shouldRedirectOn (error) {
    return error.config.url.includes(url)
}

function redirect (statusCode) {
    switch (statusCode) {
        case 401:
            router.push('/conta/entrar?returnUrl=' + encodeReturnUrl(router.currentRoute))
            break;
        case 403:
            router.push('/erro/status/403?returnUrl=' + encodeReturnUrl(router.currentRoute))
            break;
        case 404:
            router.push('/erro/status/404?returnUrl=' + encodeReturnUrl(router.currentRoute))
            break;
    }
}