import Vue from 'vue'
import modalidade from '@/pages/produto/model/modalidade'
import { formatDate, dateDistanceFormat } from './formatters/formatDate'

Vue.filter('date', formatDate)
Vue.filter('dateDistance', dateDistanceFormat)
Vue.filter('highlight', function (words, query) {
    if (!query) {
        return words
    }

    let regEx = new RegExp('(' + query + ')', 'ig')
    return words.replace(regEx, '<span class="highlight">$1</span>')
})
Vue.filter('modalidade', (value, operadora, entidade) => modalidade.descricao(value, operadora, entidade))
Vue.filter('faixaEtaria', value => value.replace('-199', ' ou +').replace('-', ' a '))
Vue.filter('acomodacao', (value, format) => {
    if (value in acomodacao === false)
        return undefined

    return acomodacao[value][format || 'nome']
})

const acomodacao = {
    NaoSeAplica: {
        letra: 'Amb',
        sigla: 'Amb',
        nome: 'Ambulatorial'
    },
    Enfermaria: {
        letra: 'E',
        sigla: 'Enf',
        nome: 'Enfermaria'
    },
    Apartamento: {
        letra: 'A',
        sigla: 'Apto',
        nome: 'Apartamento'
    },
    Odonto: {
        letra: 'O',
        sigla: 'Odonto',
        nome: 'Odonto'
    }
}

Vue.filter('currency', (value, symbol = '') => formatNumber(value, symbol))
Vue.filter('percent', value => formatNumber(value * 100) + '%')

Vue.filter('boolean', (value) => {
    if (value === null || value === undefined) {
        return ''
    }

    return value ? 'Sim' : 'Não'
})

Vue.filter('envioStatusIcon', (value) => {
    switch (value) {
        case 'Visualizado':
            return 'mdi-eye-check'
        case 'Falha':
            return 'mdi-email-alert'
        case 'Clicado':
            return 'mdi-cursor-default-click-outline'
        default:
            return 'mdi-email-outline'
    }
})

Vue.filter('sanitize', (value) => {
    return value
        .replace(/style=".*?"/gi, '')
        .replace(/style='.*?'/gi, '')
        .replace(/class=".*?"/gi, '')
        .replace(/class='.*?'/gi, '')
})

const formatNumber = (value, symbol = '') => {
    if (!value || value === 0) {
        return '-'
    }

    let val = (value / 1).toFixed(2).replace('.', ',')
    return symbol + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}