import Service from './service'
import uuidv4 from '@/shared/services/uuid'

var service = new Service()

export default {
    namespaced: true,
    state: {
        atual: [],
        recebido: [],
        enviado: [],
        excluido: []
    },
    getters: {
        atual: state => state.atual,
        recebido: state => state.recebido,
        enviado: state => state.enviado,
        excluido: state => state.excluido
    },
    mutations: {
        buscar (state, payload) {
            state.atual = payload
        },
        listar (state, payload) {
            const pasta = payload.pasta.toLowerCase()
            const lista = state[pasta]
            const novosItems = payload.items.filter(p => lista.findIndex(q => q.id === p.id) === -1)

            state[pasta] = lista.concat(novosItems)
        },
        excluir (state, payload) {
            const pasta = payload.pasta.toLowerCase()
            const item = state[pasta].find(p => p.id === payload.id)

            state[pasta] = state[pasta].filter(p => p !== item)
            state.excluido.push(item)
        }
    },
    actions: {
        async buscar ({ commit }, payload) {
            commit('buscar', [])
            const items = await service.buscar(payload)
            commit('buscar', items)

            return items
        },

        async listar ({ commit, rootGetters }, payload) {
            const user = rootGetters['conta/usuario']
            const items = await service.listar(payload, user)
            const response = { items, pasta: payload.pasta }

            commit('listar', response)

            return response
        },

        async enviar ({ state, commit, rootGetters }, payload) {
            payload.conversaId = payload.conversaId || uuidv4()
            payload.mensagemId = payload.mensagemId || uuidv4()

            const user = rootGetters['conta/usuario']
            const original = [...state.atual]
            const mensagem = {
                ...payload,
                origem: 'Enviado',
                status: 'Enviado',
                enviadoEm: new Date().toISOString(),
                remetente: { nome: user.nome, email: user.email, avatar: user.avatar },
                destinatarios: payload.destinatarios.map(p => ({ ...p, status: 'Enviado' })),
                anexos: payload.files.map(p => ({
                    nome: p.name,
                    uri: URL.createObjectURL(p)
                }))
            }

            commit('buscar', [mensagem, ...original])
            commit('listar', { pasta: 'enviado', items: [mensagem] })

            try {
                await service.enviar(payload);
            } catch (error) {
                commit('buscar', original);
                return Promise.reject(error);
            }
        },

        async excluir ({ commit }, payload) {
            await service.excluir(payload.conversaId, payload.id)

            commit('excluir', payload)
        }
    }
}