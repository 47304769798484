export default {
    data () {
        return {
            overflow: '',
            viewportSize: 0
        }
    },
    computed: {
        showLeftArrow () {
            return this.overflow === 'both' || this.overflow === 'left'
        },
        showRightArrow () {
            return this.overflow === 'both' || this.overflow === 'right'
        }
    },
    methods: {
        refreshOverflow () {
            if (!this.$refs.body)
                return

            let {
                overflow,
                viewportSize
            } = determineOverflow(this.$refs.body, this.$refs.bodyOverflow)

            if (this.overflow !== overflow) {
                this.overflow = overflow
            }
            if (this.viewportSize !== viewportSize) {
                this.viewportSize = viewportSize
            }
        },
        sideScroll (direction) {
            sideScroll(this.$refs.body, direction, 25, 100, 10)
        }
    },
    mounted () {
        if (!this.$refs.body)
            return

        setTimeout(this.refreshOverflow, 100)

        this.$refs.body.addEventListener('scroll', scroll.bind(this, this.$refs.body, this.$refs.bodyOverflow, this.refreshOverflow))
        window.addEventListener('resize', this.refreshOverflow)
    },

    beforeDestroy () {
        window.removeEventListener('resize', this.refreshOverflow)
    }
}

var ticking = false

function scroll (body, content, callback) {
    let lastPosition = window.scrollY
    if (!ticking) {
        window.requestAnimationFrame(function () {
            callback(lastPosition)
            ticking = false
        })
    }
    ticking = true
}

function sideScroll (element, direction, speed, distance, step) {
    let scrollAmount = 0
    var slideTimer = setInterval(function () {
        if (direction === 'left') {
            element.scrollLeft -= step
        } else {
            element.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
            window.clearInterval(slideTimer)
        }
    }, speed)
}

function determineOverflow (container, content) {
    var containerMetrics = container.getBoundingClientRect()
    var containerMetricsRight = Math.floor(containerMetrics.right)
    var containerMetricsLeft = Math.floor(containerMetrics.left)
    var contentMetrics = content.getBoundingClientRect()
    var contentMetricsRight = Math.floor(contentMetrics.right)
    var contentMetricsLeft = Math.floor(contentMetrics.left)
    var viewportSize = containerMetrics.right - containerMetrics.left
    var overflow = 'none'

    if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
        overflow = 'both'
    } else if (contentMetricsLeft < containerMetricsLeft) {
        overflow = 'left'
    } else if (contentMetricsRight > containerMetricsRight) {
        overflow = 'right'
    }

    return {
        overflow,
        viewportSize
    }
}