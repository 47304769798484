<template>

    <app-layout-error :title="title" image="/img/bug-robot.png">
        <p>{{ error.message }}</p>
        <v-btn color="primary" to="/">Voltar para o início</v-btn>
    </app-layout-error>

</template>

<script>
    export default {
        props: {
            status: String
        },
        computed: {
            error () {
                return errors[+this.status] ?? {
                    title: 'Ops, algo deu errado',
                    message: 'Houve uma falha inesperada. Por favor contate o suporte.'
                }
            },
            title () {
                return `${this.status} - ${this.error.title}`
            }
        }
    }

    const errors = {
        403: {
            title: 'Item restrito',
            message: `Seu perfil não possui permissão para acessar este item.
                      Em caso de dúvidas, por favor entre em contato com o administrador da conta.`
        },
        404: {
            title: 'Página não encontrada',
            message: `O item solicitado pode ter sido removido ou estar temporariamente indisponível
                      Por favor, não deixe de nos avisar caso encontre algo grave!`
        }
    }
</script>
