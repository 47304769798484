import '../mixins/user.mixin'

export default {
    assinatura: state => state.assinatura,
    operadorasSelecionadas: state => state.operadorasSelecionadas,
    plano: state => state.plano,
    usuario: (state) => {
        if (!state.usuario) {
            return undefined
        }

        let usuario = Object.assign({}, state.usuario)

        if (usuario.assinatura) {
            usuario.assinatura.desativada = usuario.assinatura.termino && new Date(usuario.assinatura.termino) < new Date()
            usuario.assinatura.expirada = usuario.assinatura.trial && usuario.assinatura.desativada
        }

        return usuario
    },

    vencimentoLoading: state => state.loadingManager.isLoading('vencimento'),
    pagamentoLoading: state => state.loadingManager.isLoading('pagamento'),
    planoLoading: state => state.loadingManager.isLoading('plano')
}