export const FILTRO_ATUALIZAR = '[Produto] Atualizar filtro X'
export const FILTRO_ATUALIZADO = '[Produto] Filtro atualizado'
export const FILTRO_TOGGLE = '[Produto] Filtro toggle'

export const LISTAR = '[Produto] Listar'
export const LISTADO_SUCESSO = '[Produto] Listado'
export const SELECIONAR = '[Produto] Selecionar'
export const SELECIONAR_SHARE = '[Produto] Selecionar share'
export const CARREGAR_VALORES = 'CARREGAR_VALORES'
export const CARREGAR_REDE = 'CARREGAR_REDE'
export const CARREGAR_MANUAL = 'CARREGAR_MANUAL'
export const CARREGAR_ARQUIVOS = 'CARREGAR_ARQUIVOS'

export const VALORES_RECUPERADOS = '[Produto] Valores recuperados'
export const REDE_RECUPERADA = '[Produto] Rede recuperada'
export const MANUAL_RECUPERADO = '[Produto] Manual recuperado'
export const ARQUIVOS_RECUPERADOS = '[Produto] Arquivos recuperados'

export const USUARIO_RECUPERADO = 'USUARIO_RECUPERADO'
