import * as types from './types'
import CorretoraService from '../services/corretora-service'
import UsuarioService from '../services/usuario-service'
import AssinaturaService from '../services/assinatura-service'
import decode from 'jwt-decode'
import router from '../../../router'

const corretoraService = new CorretoraService()
const usuarioService = new UsuarioService()
const assinaturaService = new AssinaturaService()

export default {
    [types.USUARIO_RECUPERAR] (context) {
        return usuarioService
            .recuperar()
            .then(response => {
                context.dispatch(types.USUARIO_RECUPERADO, response.data, { root: true })
                return response.data
            })
            .catch(error => {
                if (error.response?.status !== 403)
                    throw error

                const user = decode(context.rootState.auth.idToken)
                return router.push({
                    path: '/conta/finalizar',
                    query: {
                        name: user.name,
                        email: user.email
                    }
                })
            })
    },

    [types.USUARIO_RECUPERADO]: {
        root: true,
        handler: (context, payload) => {
            context.commit('SELECIONAR_TENANT', payload.corretoraId, { root: true })
            context.commit(types.USUARIO_RECUPERADO, payload)
        }
    },

    [types.USUARIO_ALTERAR] (context, payload) {
        return usuarioService.salvar(payload).then(response => context.dispatch(types.USUARIO_RECUPERADO,
            response.data,
            {
                root: true
            }))
    },

    [types.CORRETORA_ALTERAR] (context, payload) {
        return corretoraService.salvar(payload).then(response => context.dispatch(types.USUARIO_RECUPERADO,
            {
                ...response.data,
                corretora: response.data.nome
            },
            {
                root: true
            }))
    },

    [types.EMAIL_ALTERAR] (context, payload) {
        return usuarioService.alterarEmail(payload)
    },

    [types.LOGOTIPO_ALTERAR] (context, payload) {
        return usuarioService.alterarLogotipo(payload).then(response => context.dispatch(types.USUARIO_RECUPERAR, response.data))
    },

    [types.ALTERAR_AVATAR] (context, payload) {
        return usuarioService
            .alterarAvatar(payload)
            .then(uri => {
                context.commit(types.ALTERAR_AVATAR, uri)
                return uri
            })
    },

    [types.ASSINATURA_RECUPERAR] (context) {
        return assinaturaService.recuperar().then(response => context.commit(types.ASSINATURA_RECUPERADA, response.data))
    },

    [types.OPERADORAS_RECUPERAR] (context) {
        return assinaturaService.recuperarOperadoras().then(response => context.commit(types.OPERADORAS_RECUPERAR_SUCCESS, response.data))
    },
    [types.OPERADORAS_SELECIONAR] (context, payload) {
        context.commit(types.OPERADORAS_SELECIONAR, payload)
        return assinaturaService.selecionarOperadora(payload) // .then(response => context.commit(types.OPERADORAS_SELECIONAR, response.data))
    },

    [types.VENCIMENTO_ALTERAR] (context, payload) {
        context.commit(types.VENCIMENTO_ALTERAR_PENDING)

        return assinaturaService.alterarVencimento(payload)
            .then(
                () => context.commit(types.VENCIMENTO_ALTERAR_SUCCESS, payload),
                error => context.commit(types.VENCIMENTO_ALTERAR_FAIL, {
                    error
                })
            )
    },

    [types.PAGAMENTO_ALTERAR] (context, payload) {
        context.commit(types.PAGAMENTO_ALTERAR_PENDING)

        return assinaturaService.alterarMetodoPagamento(payload)
            .then(
                response => {
                    context.dispatch(types.USUARIO_RECUPERAR)
                    return context.commit(types.PAGAMENTO_ALTERAR_SUCCESS, response.data)
                },
                error => context.commit(types.PAGAMENTO_ALTERAR_FAIL, {
                    error
                })
            )
    },

    [types.PLANO_ALTERAR] (context, payload) {
        context.commit(types.PLANO_ALTERAR_PENDING)

        return assinaturaService.alterarPlano(payload).then(() => context.commit(types.PLANO_ALTERAR_SUCCESS, payload))
    },

    [types.ASSINATURA_ATIVAR] (context, payload) {
        return assinaturaService.ativar(payload)
            .then(() => context.commit(types.PLANO_ALTERAR_SUCCESS, payload))
            .then(() => context.dispatch(types.USUARIO_RECUPERAR))
    },

    [types.ASSINATURA_REATIVAR] (context) {
        return assinaturaService.reativar().then(() => context.dispatch(types.USUARIO_RECUPERAR))
    },

    async[types.ASSINATURA_ENCERRAR] (context, payload) {
        const url = `https://share.hsforms.com/11iTtX5plRESrwgKVtyTZsw4a56c?email=${context.state.usuario.email}`

        await assinaturaService.encerrar(payload)
        await context.dispatch('[Auth] Sair', {}, { root: true })
        location.href = url
    }
}