import * as types from './types'

export default {
    [types.FILTRO_ATUALIZADO] (state, filtro) {
        state.filtro = Object.assign({}, filtro)
    },
    [types.FILTRO_TOGGLE] (state) {
        state.filtroSlim = !state.filtroSlim
    },
    [types.LISTADO_SUCESSO] (state, operadoras) {
        state.lista = operadoras
    },
    [types.SELECIONAR] (state, produto) {
        state.selecionado = produto
    },
    [types.VALORES_RECUPERADOS] (state, valores) {
        state.selecionado = {
            ...state.selecionado,
            valores
        }
    },
    [types.REDE_RECUPERADA] (state, rede) {
        state.selecionado = {
            ...state.selecionado,
            rede
        }
    },
    [types.MANUAL_RECUPERADO] (state, manual) {
        state.selecionado = {
            ...state.selecionado,
            manual
        }
    },
    [types.ARQUIVOS_RECUPERADOS] (state, arquivos) {
        state.selecionado = {
            ...state.selecionado,
            arquivos
        }
    },
    [types.USUARIO_RECUPERADO] (state, payload) {
        if (!state.filtro.cidade && payload.cidade) {
            state.filtro.cidade = payload.cidade
        }
    }
}
