import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  lista: undefined,
  selecionadas: [],
  excluindo: []
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
