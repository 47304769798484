import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"app":"","dark":"","fixed":"","mini-variant":_vm.mini,"mobile-breakpoint":_vm.mobileBreakpoint,"touchless":_vm.touchless},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VDivider,{attrs:{"light":""}}),_c(VList,{staticClass:"pa-0",attrs:{"dense":""}},[_c(VListItem,{on:{"click":_vm.navigateToConfiguracao}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-cog")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Configurações")])],1)],1),_c(VListItem,{on:{"click":_vm.support}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-help-circle-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Ajuda")])],1),_c('app-help',{model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}})],1),(!_vm.$isMobile)?_c(VListItem,{staticClass:"mt-2",on:{"click":function($event){_vm.mini = !_vm.mini}}},[_c(VListItemIcon,[(!_vm.mini)?_c(VIcon,[_vm._v("mdi-chevron-left")]):_c(VIcon,[_vm._v("mdi-chevron-right")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Minimizar")])],1)],1):_vm._e()],1),_c('app-menu-install',{ref:"installButton"})]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{staticClass:"pa-0",attrs:{"dense":""}},[_c(VListItem,{staticClass:"px-2 py-1"},[_c(VListItemAvatar,{staticClass:"pointer",on:{"click":function($event){return _vm.$navigate('/conta')}}},[_c('avatar',{attrs:{"src":_vm.$user.avatar,"username":_vm.$user.nome,"size":40}})],1),_c(VListItemContent,[_c('app-tenant-menu',{on:{"info":function($event){_vm.infoDialog = true}}})],1)],1),_c(VDivider),_vm._l((_vm.items),function(item){return [(item.subLinks)?_c('AppMenuGroup',_vm._b({key:item.url},'AppMenuGroup',item,false)):_c('AppMenuItem',_vm._b({key:item.title},'AppMenuItem',item,false))]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }