export const RECUPERAR = 'RECUPERAR'
export const RECUPERAR_SUCESSO = 'Recuperada com sucesso'

export const CONTATO_VINCULAR = 'CONTATO_VINCULAR'
export const GRUPO_INCLUIR = 'GRUPO_INCLUIR'
export const GRUPO_REMOVER = 'GRUPO_REMOVER'
export const GRUPO_ATUALIZAR = 'GRUPO_ATUALIZAR'

export const SALVAR = 'SALVAR'
export const SALVAR_SUCESSO = 'SALVAR_SUCESSO'
export const SALVAR_FALHA = 'SALVAR_FALHA'

export const FILTRO_ATUALIZADO = 'Filtro atualizado'
export const DEFINIR_LOCALIZACAO_PADRAO = 'DEFINIR_LOCALIZACAO_PADRAO'

export const CENARIO_SELECIONAR = 'CENARIO_SELECIONAR'
export const CENARIO_SELECIONADO = 'CENARIO_SELECIONADO'
export const CENARIO_ADICIONAR = 'CENARIO_ADICIONAR'
export const CENARIO_REMOVER = 'CENARIO_REMOVER'
export const CENARIOS_ATUALIZADOS = 'CENARIOS_ATUALIZADOS'

export const RECUPERAR_OPERADORAS = 'RECUPERAR_OPERADORAS'

export const ALTERAR_FAIXA_ETARIA = 'Alterar faixa etária'

export const REDE_TOGGLE = 'REDE_TOGGLE'
export const OPCOES_ATUALIZADAS = 'OPCOES_ATUALIZADAS'

export const ENVIAR = 'ENVIAR'

export const MANUAL_RECUPERAR = 'MANUAL_RECUPERAR'
export const MANUAL_RECUPERAR_SUCESSO = 'MANUAL_RECUPERAR_SUCESSO'

export const SET_COTACAO = 'SET_COTACAO'