import cloneDeep from 'clone-deep'
import * as types from './types'
import CotacaoService from '../service'
import produtoService from '@/shared/services/produto'
import uuidv4 from '@/shared/services/uuid'
import CidadeService from '@/shared/services/cidade'
import router from '@/router'
import mapearCotacaoAsync from './helpers/mapearCotacaoAsync'

var cotacaoService = new CotacaoService()
var cidadeService = new CidadeService()

export default {
    async [types.RECUPERAR] (context, payload) {
        context.commit(types.RECUPERAR_SUCESSO, undefined)

        try {
            const cotacao = await cotacaoService.recuperar(payload.id, payload.authorize)

            const result = { ...cotacao, ...payload }

            await context.dispatch(types.RECUPERAR_SUCESSO, result)

            return result
        }
        catch (error) {
            if (error.response && error.response.status === 404)
                return router.push('/erro/status/404')

            throw error;
        }
    },

    async [types.RECUPERAR_SUCESSO] (context, payload) {
        context.commit('SET_PUBLIC_TOKEN', 'Token ' + payload.token, { root: true })

        const cotacao = await mapearCotacaoAsync(payload)

        context.commit(types.RECUPERAR_SUCESSO, cotacao)

        return cotacao
    },

    async [types.RECUPERAR_OPERADORAS] (context) {

        // Atualizar opções disponíveis em cada cenário
        const cenarios = Object.entries(context.state.selecionada.grupos[0].cenarios).map(p => ({ key: p[0], ...p[1] }))
        const vidas = context.getters.cotacao.grupos.map(p => p.vidasTotal).reduce((p, q) => p + q)
        cenarios.forEach(async cenario => {
            const filtro = {
                ...context.state.selecionada.filtro,
                modalidade: cenario.modalidade,
                entidade: cenario.entidade
            }
            const opcoes = await produtoService.recuperarCenariosDaOperadora(cenario.operadora, filtro, vidas)

            context.commit(types.CENARIOS_ATUALIZADOS, { key: cenario.key, ...opcoes })

            // Atualizar opção selecionada em cada cenário
            context.state.selecionada.grupos.forEach(grupo => {
                const opcaoAtual = grupo.cenarios[cenario.key]
                const opcaoEquivalente = localizarPlanoEquivalente(opcoes, opcaoAtual)
                const deveAtualizar = !opcaoEquivalente || (opcaoAtual && opcaoAtual.id !== opcaoEquivalente.id)

                if (deveAtualizar) {
                    context.dispatch(types.CENARIO_SELECIONAR, {
                        grupoIndex: context.state.selecionada.grupos.indexOf(grupo),
                        cenarioKey: cenario.key,
                        opcao: opcaoEquivalente
                    })
                }
            })
        })
    },

    async [types.CENARIO_ADICIONAR] (context, payload) {
        const filtro = { ...context.state.selecionada.filtro, modalidade: payload.opcao.modalidade, entidade: payload.opcao.entidade };
        const cenario = await produtoService.recuperarCenariosDaOperadora(payload.operadoraId, filtro, context.getters.cotacao.vidasTotal)
        const data = {
            opcao: payload.opcao,
            cenario: {
                key: uuidv4(),
                ...cenario
            }
        }

        context.commit(types.CENARIO_ADICIONAR, data)
        context.state.selecionada.grupos.forEach((_, i) =>
            context.dispatch(types.CENARIO_SELECIONAR, {
                grupoIndex: i,
                cenarioKey: data.cenario.key,
                opcao: data.opcao
            })
        )
    },

    async [types.CENARIO_SELECIONAR] (context, payload) {
        let opcao = { ...payload.opcao }
        context.commit(types.CENARIO_SELECIONADO, {
            ...payload,
            opcao: { ...payload.opcao, atualizando: true }
        })

        if (payload.opcao) {
            const filtro = {
                ...context.state.selecionada.filtro,
                modalidade: payload.opcao.modalidade,
                entidade: payload.opcao.entidade
            }

            const responses = await Promise.all([
                produtoService.getValores(payload.opcao.administradora, payload.opcao.produto, filtro),
                produtoService.getRedeCredencida(payload.opcao.administradora, payload.opcao.produto, filtro)
            ])

            const valorResponse = responses[0]
            const rede = responses[1]
            const tabela = valorResponse.data.find(p => p.id === payload.opcao.tabela && p.planos.some(q => q.id == payload.opcao.plano))

            if (tabela) {
                const plano = tabela.planos.find(p => p.id === payload.opcao.plano)

                opcao = {
                    ...payload.opcao,
                    atualizando: false,
                    redeCredenciada: {
                        legenda: rede.legenda,
                        credenciados: rede.credenciados,
                        planos: rede.planos.filter(p => p.id === payload.opcao.plano)
                    },
                    valores: Object.keys(plano.valores).map(q => ({
                        de: parseInt(q.split('-')[0]),
                        ate: parseInt(q.split('-')[1]),
                        valor: plano.valores[q]
                    }))
                }
            }
        }

        context.commit(types.CENARIO_SELECIONADO, {
            ...payload,
            opcao
        })
    },

    async ['CENARIO_DESTACAR'] (context, payload) {
        // let opcao = { ...payload.opcao }
        context.commit('CENARIO_DESTACAR', payload)
    },

    [types.FILTRO_ATUALIZADO] (context, payload) {
        const filtroAtual = context.state.selecionada.filtro
        const filtro = { ...filtroAtual, ...payload }

        if (filtroAtual.cidade !== filtro.cidade) {
            context.dispatch(types.DEFINIR_LOCALIZACAO_PADRAO, filtro.cidade)
        }

        context.commit(types.FILTRO_ATUALIZADO, filtro)
        context.dispatch(types.RECUPERAR_OPERADORAS)
    },

    async [types.DEFINIR_LOCALIZACAO_PADRAO] (context, payload) {
        const cidades = await cidadeService.get(payload)

        if (cidades.length != 1)
            return

        context.commit(types.REDE_TOGGLE, {
            regioes: [cidades[0].regiao]
        })
    },

    [types.ALTERAR_FAIXA_ETARIA] (context, payload) {
        context.commit(types.ALTERAR_FAIXA_ETARIA, payload)
        context.dispatch(types.RECUPERAR_OPERADORAS)
    },

    [types.GRUPO_INCLUIR] (context) {
        const length = context.state.selecionada.grupos.length
        const clone = cloneDeep(context.state.selecionada.grupos[0])

        clone.nome = 'Grupo #' + (length + 1)
        clone.vidas = Object.assign(
            ...Object.entries(clone.vidas).map(p => ({
                [p[0]]: 0
            }))
        )

        context.commit(types.GRUPO_INCLUIR, clone)
        context.dispatch(types.RECUPERAR_OPERADORAS)

        return Promise.resolve(clone)
    },

    [types.GRUPO_REMOVER] (context, payload) {
        context.commit(types.GRUPO_REMOVER, payload)
        context.dispatch(types.RECUPERAR_OPERADORAS)
    },

    [types.GRUPO_ATUALIZAR] (context, payload) {
        context.commit(types.GRUPO_ATUALIZAR, payload)
        context.dispatch(types.RECUPERAR_OPERADORAS)
    },

    [types.SALVAR] (context) {
        context.commit(types.SALVAR)

        const cotacao = cloneDeep(context.state.selecionada)
        cotacao.cenarios.map(p => delete p.opcoes)

        const payload = {
            id: cotacao.id,
            contato: cotacao.contato,
            destaque: cotacao.destaque,
            negocioId: cotacao.negocioId,
            endereco: cotacao.endereco,
            filtro: cotacao.filtro,
            opcoes: cotacao.opcoes,
            cenarios: cotacao.cenarios,
            grupos: cotacao.grupos.map(p => ({
                nome: p.nome,
                regioes: p.regioes,
                vidas: p.vidas,
                cenarios: p.cenarios
            }))
        }

        return cotacaoService
            .salvar(payload)
            .then(
                response => {
                    context.commit(types.SALVAR_SUCESSO, response.data)
                    return Promise.resolve(response.data)
                },
                error => {
                    context.commit(types.SALVAR_FALHA)
                    throw error
                })
    },

    [types.ENVIAR] (context, payload) {
        const data = {
            ...payload,
            destinatarios: payload.emails
        }

        return cotacaoService.enviar(data)
    },

    async [types.MANUAL_RECUPERAR] (context, payload) {
        const filtro = {
            ...context.state.selecionada.filtro,
            modalidade: payload.modalidade,
            entidade: payload.entidade
        }

        const response = await produtoService.getManual(payload.administradora, payload.produto, filtro)

        context.commit(types.MANUAL_RECUPERAR_SUCESSO, {
            ...payload,
            informacoes: response.data
        })

        return response.data
    }
}

function localizarPlanoEquivalente (cenario, opcao) {
    if (!cenario || !opcao)
        return undefined

    const equivalentes = cenario.opcoes
        .reduce((t, p) => t.concat(p), [])
        .map(p => p.planos)
        .reduce((t, p) => t.concat(p), [])
        .filter(p => p.produto === opcao.produto && p.plano === opcao.plano)
        .sort(p => p.coparticipacao === opcao.coparticipacao ? -1 : 0)

    return equivalentes.find(p => p.id === opcao.id) ||
        equivalentes.find(p => p.id === opcao.lastId) ||
        equivalentes[0]
}