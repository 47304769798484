import axios from 'axios'
import { List } from '@/shared/List'

const baseUrl = (operadora, produto, filtro) => `api/operadoras/${operadora}/${filtro.modalidade}/${produto}/`

export default {

    async get (operadora, produto, filtro) {
        const url = baseUrl(operadora, produto, filtro)
        const response = await axios.get(url, { params: filtro })
        const data = response.data
        const result = { ...data.produto, operadoraNome: data.administradora.nome }

        return result
    },

    getValores (operadora, produto, filtro) {
        let url = baseUrl(operadora, produto, filtro) + 'valores'
        return axios
            .get(url, {
                params: filtro
            })
    },

    async getRedeCredencida (operadora, produto, filtro) {
        const url = baseUrl(operadora, produto, filtro) + 'rede-credenciada'
        const response = await axios.get(url, { params: filtro })

        if (!response.data)
            return { legenda: {}, planos: [] }

        return {
            legenda: response.data.legenda,
            credenciados: response.data.credenciados,
            planos: response.data.planos.map(plano => ({
                ...plano,
                credenciados: response.data.credenciados
                    .filter(credenciado => credenciado.id in plano.atendimentos)
                    .map(credenciado => ({
                        ...credenciado,
                        atendimentos: plano.atendimentos[credenciado.id]
                    }))
            }))
        }
    },

    getManual (operadora, produto, filtro, informacoesRestritas = false) {
        const url = baseUrl(operadora, produto, filtro) + 'manual'
        const params = {
            ...filtro,
            informacoesRestritas
        }

        return axios.get(url, { params })
    },

    getArquivos (operadora, produto, filtro) {
        let url = baseUrl(operadora, produto, filtro) + 'anexos'
        return axios
            .get(url, {
                params: filtro
            })
    },

    async recuperarCenariosDaOperadora (operadoraId, filtro, vidas) {
        const url = `api/cotacao/cenarios`
        const response = await axios.get(url, {
            params: {
                ...filtro,
                vidas,
                operadoraId
            }
        })

        return mapearCenarios(response.data, filtro.modalidade)
    },

    localizarCredeciadosProximos (filtro, lat, lng) {
        let url = `api/credenciados/proximos/${lat}/${lng}`

        return axios
            .get(url, {
                params: filtro
            })
    },

    localizarPlanosPorRegiao (filtro, lat, lng) {
        let url = `api/cotacao/cenarios/${lat}/${lng}`

        return axios
            .get(url, {
                params: filtro
            })
    },

    getProfissoes (entidade, administradoraId, produtoId, filtro) {
        let url = `api/entidades/${entidade}/profissoes`
        let params = {
            ...filtro,
            administradoraId,
            produtoId
        }

        return axios
            .get(url, {
                params: params
            })
    },

    enviarPorEmail (payload) {
        let url = `api/produto-link/enviar`

        return axios.post(url, payload)
    },

    registrarLinkCompartilhamento (payload) {
        let url = `api/produto-link`

        return axios.post(url, payload)
    },

    recuperarLocalizacao (cidade) {
        let url = `api/cidades/${cidade}/localizacao`

        return axios.get(url)
    },

    async listarOperadoras (filtro) {
        if (!filtro.cidade)
            return []

        const response = await axios.get('api/operadoras/', { params: filtro })

        return response.data
    },

    async listarProdutos (filtro, operadoraId) {
        const response = await axios.get(`api/operadoras/${operadoraId}/produtos`, { params: filtro })

        return List.from(response.data)
    }
}

function mapearCenarios (cenario, modalidade) {
    return {
        ...cenario,
        opcoes: cenario.opcoes.map(opcao => ({
            ...opcao,
            planos: opcao.planos.map(plano => ({
                ...plano,
                id: getCenarioOpcaoId(opcao, plano),
                modalidade: modalidade,
                entidade: opcao.entidade,
                administradora: opcao.administradora.id,
                operadora: cenario.operadora.id,
                produto: opcao.produto.key,
                tabela: opcao.tabela.id,
                plano: plano.id,
                coparticipacao: opcao.tabela.coparticipacaoTipo,
                mei: opcao.tabela.mei
            }))
        }))
    }
}

function getCenarioOpcaoId (opcao, plano) {
    let id = `${opcao.produto.key}-${opcao.tabela.id}-${plano.id}`

    if (opcao.entidade)
        id += `-${opcao.entidade}`

    return id
}