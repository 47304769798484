import format from 'date-fns/format'
import pt from 'date-fns/locale/pt'
import parseISO from 'date-fns/parseISO'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import isTomorrow from 'date-fns/isTomorrow'
import isSameWeek from 'date-fns/isSameWeek'
import startOfWeek from 'date-fns/startOfWeek'
import isSameMonth from 'date-fns/isSameMonth'
import addDays from 'date-fns/addDays'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

export function formatDate (value, dateFormat = 'dd/MM/yyyy HH:mm') {
    if (!value)
        return ''

    const parsedValue = value instanceof Date ? value : parseISO(value)

    return format(parsedValue, dateFormat, {
        locale: pt
    })
}

export function dateDistanceFormat (value) {
    const now = new Date()
    const parsedValue = value instanceof Date ? value : parseISO(value)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const diff = now - new Date(value)
    let distance = 'há ' + formatDistanceStrict(now, parsedValue, { locale: pt })

    if (new Date(value) < today)
        return format(parsedValue, 'eee, dd \'de\' MMM', { locale: pt })

    if (diff < 60000)
        distance = 'agora'

    return `${format(parsedValue, 'HH:mm')} (${distance})`
}

export function dateDistanceFormatStrict (value) {
    const now = new Date()
    const parsedValue = value instanceof Date ? value : parseISO(value)
    const distance = 'há ' + formatDistanceStrict(now, parsedValue, { locale: pt })

    if (distance.includes('segundos'))
        return 'agora'

    return distance
}

export const relativeDateFormat = (value) => {
    const date = value instanceof Date ? value : parseISO(value)
    const now = new Date()
    const startWeek = addDays(startOfWeek(now), -1)

    if (isToday(date))
        return 'Hoje'

    if (isYesterday(date))
        return 'Ontem'

    if (isTomorrow(date))
        return 'Amanhã'

    if (isSameWeek(date, now))
        return 'Esta semana'

    if (isSameWeek(date, startWeek))
        return 'Semana passada'

    if (isSameMonth(date, now))
        return 'Este mês'

    return format(date, 'eee, dd \'de\' MMM', { locale: pt })
}

export const relativeDateWithHourFormat = (value) => {
    const date = value instanceof Date ? value : parseISO(value)
    const hours = format(date, 'HH:mm', { locale: pt })
    let formatedDate = format(date, 'dd/MM', { locale: pt })

    if (isToday(date))
        formatedDate = 'Hoje'

    if (isYesterday(date))
        formatedDate = 'Ontem'

    if (isTomorrow(date))
        formatedDate = 'Amanhã'

    return `${formatedDate}, ${hours}`
}