import Vue from 'vue'
import autosize from 'autosize'

const TEXTAREA_TAGNAME = 'TEXTAREA'

Vue.directive('autosize', {
  inserted: function (el) {
    let textarea = el

    if (el.tagName !== TEXTAREA_TAGNAME) {
      textarea = el.querySelector(TEXTAREA_TAGNAME)
    }

    autosize(textarea)
  }
})
