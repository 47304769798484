import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const initialState = {
    lista: []
}

export default {
    state: initialState,
    getters,
    mutations,
    actions
}
