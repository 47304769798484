import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c(VListItem,{attrs:{"href":_vm.href,"to":_vm.url}},[(_vm.icon)?_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.icon)}})],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.title)}})],1),(_vm.betaLabel)?_c(VListItemAction,[_c(VChip,{staticClass:"purple",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.release.toLowerCase()))])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }