import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import initialState from './initial-state'

export default {
  state: initialState,
  getters,
  mutations,
  actions
}
