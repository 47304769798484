import { gql } from '@/shared/services/graphQLClient'

export const NegociosEtapasQuery = gql`
query NegociosPorEtapa($request: NegociosFilterInput!) {
    negocios: negociosElastic(request: $request) {
        quantidade
        valor
        items {
            id
            nome
            criadoEm
            fechamento
            contato {
                id
                nome
                avatar
            }
            etapa {
                id
                nome
            }
            produto {
                nome    
            }
            etiquetas {
                nome
            }  
            valor            
        }
    }
}`

export const NegociosSimpleQuery = gql`
query Negocios($request: NegociosFilterInput!) {
    negocios: negociosSimple(request: $request) {
        items {
            id
            nome
            criadoEm
            fechamento
            contato {
                id
                nome
                avatar
            }
            etapa {
                id
                nome
            }
            produto {
                nome    
            }
            etiquetas {
                nome
            }  
            valor            
        }
    }
}`

export const NegociosQuery = gql`
query Negocios($request: NegociosQueryInput!) {
    negocios(request: $request) {
        items {
            id
            nome
            criadoEm
            fechamento
            valor
            etapa {
                id
                nome
            }
            produto {
                nome    
            }
            etiquetas {
                nome
                cor
            }              
        }
    }
}`

export const NegociosExportacaoQuery = gql`
query NegociosExportacao($request: NegociosFilterInput!) {
    negocios: negociosElastic(request: $request) {
        items {
            id            
            nome
            contrato
            etapa {
                nome
            }
            criadoEm
            fechamento
            valor
            contato {
                id
                nome
                email
                documento
                cidade
                telefones
                avatar
            }
            vendedor {
                email
            }
            produto {
                id
                nome
                ramo
            }
            etiquetas {
                nome
            }
            anotacoes {
                criadoEm
                mensagem
                criadoPor {
                    nome
                }
            }
        }
    }
}`

export const NegocioDetailQuery = gql`
query Negocio($id: UUID!) {
    negocio(id: $id) {
        id
        nome
        contrato
        fechamento
        produto {
            id
            nome    
            ramo
        }
        contato {
            id
            nome
            email
            avatar
            telefones
        }
        etapa {
            id
        }
        valor
        vendedor {
            id
            nome
            email
            avatar
        }
        etiquetas {
            nome
        }   
        timeline {
            __typename
            timestamp 
            origem
            origemId
            usuario: criadoPor {
                nome
                avatar
            }
            ... on  NegocioTransferido {
                vendedorAnterior {
                    nome
                }
                vendedorPosterior {
                    nome
                }
            }   
            ... on  NegocioMovido {
                etapaAnterior {
                    nome
                    ordem       
                    probabilidade             
                }
                etapaPosterior {
                    nome
                    ordem
                    probabilidade
                }
            }   
            ... on AnotacaoRegistrada{
                mensagem
            }
            ... on AnotacaoAudioRegistrada{
                audio
            }
        }      
    }
}`

export const SumarioQuery = gql`
 query Sumario($request: NegociosFilterInput!) {
    sumario(request: $request)
    {
        data: criacao
        quantidade
        valor,
        etapa {
            id
            nome
            probabilidade
        }
    }
 }`

export const SumarioFechamentoQuery = gql`
query SumarioFechamento($request: NegociosFilterInput!) {
    sumario(request: $request)
    {
        data: fechamento
        quantidade
        valor,
        etapa {
            id
            nome
            probabilidade
        }
    }
}`

export const SumarioEtiquetaQuery = gql`
query SumarioCriacao($request: NegociosFilterInput!) {
    sumario(request: $request)
    {
        quantidade
        valor,
        etapa {
            id
            nome
            probabilidade
        }
    }
}`

export const SumarioRankingQuery = gql`
query SumarioRanking($request: NegociosFilterInput!) {
    sumario(request: $request)
    {
        quantidade
        valor,
        vendedor {
            id
            nome
        },
        etapa {
            id
            nome
            probabilidade
        }
    }
}`