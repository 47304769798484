<template>
    <v-combobox
        chips
        multiple
        no-filter
        ref="input"
        v-model="emails"
        v-bind="$attrs"
        item-value="email"
        item-text="email"
        :items="items"
        :search-input.sync="search"
        autocomplete="new-password"
        @input="resetSearch">

        <template v-slot:no-data>
            <v-list-item v-if="search" @click="$refs.input.blur()">
                <email-input-chip :value="{ email: search }" />
            </v-list-item>
        </template>

        <template v-slot:selection="data">
            <email-input-chip :value="data.item" @close="remove" />
        </template>

        <template v-slot:item="data">
            <email-input-chip :value="data.item" format="complete" />
        </template>

    </v-combobox>
</template>

<script>
    import axios from 'axios'
    import { debounce } from '@/shared/functions'

    export default {
        name: 'email-input',
        props: {
            autocomplete: Boolean,
            value: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                items: [],
                search: ''
            }
        },
        computed: {
            emails: {
                get () { return this.value },
                set (value) {
                    const validateEmail = this.$_rules.email()
                    const emails = value
                        .map(item => item.email ? item : { email: item })
                        .map(item => ({ ...item, email: item.email.toLowerCase().replace(/,/g, ''), contatoId: item.id }))
                        .filter(p => p.email !== '' && validateEmail(p.email) === true)

                    this.$emit('input', emails)
                }
            }
        },
        methods: {
            remove (item) {
                this.emails = this.emails.filter(p => p !== item)
            },
            resetSearch () {
                this.items = []
                this.search = ''
            }
        },
        watch: {
            search: debounce(async function (value) {
                if (!this.autocomplete || !value)
                    return

                const response = await axios.get(`api/contatos?searchText=${value}&take=20`)
                const emailAtuais = this.value.map(p => p.email)

                this.items = response.data.items.filter(p => p.email && !emailAtuais.includes(p.email))
            }, 250)
        },
        components: {
            EmailInputChip: () => import( /* webpackChunkName: "conta-chunk" */ './EmailInputChip')
        }
    }
</script>