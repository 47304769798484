import Vue from 'vue'

const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
const rules = {
    required: (message = 'Campo obrigatório') => v => !!v || message,
    notEmpty: (message = 'Campo obrigatório') => v => v?.length > 0 || message,
    minLength: (length, message = `O campo requer ${length} caracteres`) => v => v.length >= length || message,
    email: (message = 'E-mail inválido') => v => !v || EMAIL_REGEX.test(v) || message
}

Vue.mixin({
    computed: {
        $_rules () {
            return rules
        }
    }
})

export default rules