import axios from 'axios'
import store from '../../store'
import apiBaseUrl from './apiBaseUrl'
import packageInfo from '@/../package.json'

const queryString = require('query-string')

axios.defaults.paramsSerializer = function (params) {
    return queryString.stringify(params)
}

axios.interceptors.request.use(
    (request) => {
        if (request.url.startsWith('api')) {
            request.url = apiBaseUrl + request.url

            const token = store.state.auth.accessToken ?? store.state.auth.publicToken
            if (token != null && 'Authorization' in request.headers == false) {
                request.headers.Authorization = token.includes(' ') ? token : `Bearer ${token}`
                request.headers['X-Tenant'] = store.state.auth.tenant
            }

            request.headers['X-AppVersion'] = packageInfo.version
            request.headers['X-MixPanel'] = encodeURI(JSON.stringify(window.mixpanelProperties))
        }

        return request
    },
    (err) => Promise.reject(err)
)
