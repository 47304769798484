export const ENTRAR = '[Auth] Entrar'
export const LOGIN_SOCIAL = '[Auth] Entrar com rede social'
export const LOGADO = '[Auth] Logado com sucesso'
export const LOGADO_FAIL = '[Auth] Falha ao logar'
export const SET_PUBLIC_TOKEN = 'SET_PUBLIC_TOKEN'

export const SAIR = '[Auth] Sair'
export const DESLOGADO = '[Auth] Deslogado com sucesso'

export const SENHA_REDEFINIR = 'SENHA_REDEFINIR'

export const INDICAR_RETURN_URL = 'INDICAR_RETURN_URL'
export const INDICAR_CUPOM = 'INDICAR_CUPOM'
export const INDICAR_TRIAL = 'INDICAR_TRIAL'
export const SELECIONAR_TENANT = 'SELECIONAR_TENANT'

export const REGISTRAR_CONTA = 'REGISTRAR_CONTA'
export const REGISTRAR_CORRETORA = 'REGISTRAR_CORRETORA'
