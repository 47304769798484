import Vue from 'vue'
import GoogleMap from './GoogleMap'
import GoogleAutocomplete from 'vue-google-autocomplete'
import Avatar from 'vue-avatar'
import draggable from "vuedraggable";
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component(GoogleMap.name, GoogleMap)
Vue.component(GoogleAutocomplete.name, GoogleAutocomplete)
Vue.component('avatar', Avatar)
Vue.component('draggable', draggable)

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
    locale: 'pt-BR',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})