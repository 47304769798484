import Vue from 'vue';
import App from './App';

import PortalVue from 'portal-vue';
import VueSecureHTML from 'vue-html-secure';
import './components';
import './mixins/navigable';
import './mixins/queryString.mixin';
import './mixins/tracking.mixin';
import './pages/crm/contato/components';
import './plugins/axios';
import './plugins/bugsnag';
import './plugins/globals';
import './plugins/registerServiceWorker';
import './plugins/vueAnalytics';
import vuetify from './plugins/vuetify';
import './plugins/webFontLoader';
import router from './router';
import './shared/directives';
import './shared/filters';
import store from './store';

Vue.config.productionTip = false
Vue.use(require('vue-script2'))
Vue.use(PortalVue)
Vue.use(VueSecureHTML);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')