<template>
    <transition name="fade" mode="out-in">
        <v-btn
            key="b1"
            v-bind="$attrs"
            @click="confirmando = true"
            v-if="!confirmando">
            <slot></slot>
        </v-btn>
        <v-btn
            key="b2"
            v-bind="$attrs"
            color="error"
            @click="confirmar"
            v-text="confirmText"
            v-else>
        </v-btn>
    </transition>
</template>

<script>
    export default {
        props: {
            confirmText: { type: String, default: 'Excluir realmente?' }
        },
        data () {
            return {
                confirmando: false
            }
        },
        methods: {
            confirmar () {
                this.confirmando = false
                this.$emit('confirm')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade-leave-active,
    .fade-enter-active {
        transition: opacity 0.25s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>