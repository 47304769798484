<template>
    <v-app>
        <v-main class="white">
            <v-container class="host pa-0" :style="[baseStyles]">
                <v-toolbar color="white" flat>
                    <slot name="toolbarLogo"></slot>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="print" class="grey--text text--darken-2">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                </v-toolbar>

                <div class="header">
                    <v-container class="py-0">
                        <v-row no-gutters>
                            <v-col class="pt-0 text-center text-sm-left">
                                <img :src="logotipo" alt="Logotipo" class="logotipo hidden-sm-and-up mb-5">
                                <slot name="info"></slot>
                            </v-col>

                            <v-col class="pt-0 hidden-xs-only text-right">
                                <img :src="logotipo" alt="Logotipo" class="logotipo">
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <slot></slot>

            </v-container>
        </v-main>

        <footer>
            <v-row class="text-sm-left text-center">
                <v-col cols="12" md="8">
                    <small>
                        Informativo de caráter referencial: valores, regras de comercialização, rede credenciada e condições contratuais são determinadas
                        pelas seguradoras/operadoras e podem ser alterados pelas mesmas a qualquer momento. Reservamos o direito
                        de corrigir eventuais erros, não vinculando esta oferta à prestação do serviço, que se dará somente no ato da assinatura do contrato.
                    </small>
                </v-col>

                <v-col cols="12" md="4" class="text-sm-right text-center">
                    <small>
                        ©{{new Date().getFullYear()}}
                        <a href="http://paineldocorretor.com.br" target="_blank" rel="noopener">Painel do Corretor</a>
                    </small>
                </v-col>
            </v-row>
        </footer>
    </v-app>
</template>

<style lang="scss" scoped>
    .toolbar {
        height: auto;
    }

    .container,
    footer {
        max-width: 27.9cm;
    }

    .container {
        position: relative;

        @media (max-width: 600px) {
            padding: 0;
        }
    }

    .logotipo {
        max-height: 150px;
        max-width: 250px;
    }

    .header {
        background-color: white;
        margin-bottom: -1px;
        box-shadow: 0 10px 40px 0px #e2e2e2;

        a {
            color: inherit;
            text-decoration: none;
        }

        .flex:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            padding-right: 5px;

            div {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    @media (max-width: 1264px) {
        .host > .container {
            padding: 0;
            max-width: 100%;
        }
    }

    @media (min-width: 1264px) {
        .toolbar {
            background-color: transparent !important;
        }
    }

    .host ::v-deep .v-expansion-panel {
        box-shadow: none;
    }

    footer {
        background-color: transparent;
        margin: 0 auto;
        line-height: 16px;
        padding: 10px;
        color: grey;

        small a {
            color: inherit !important;
            text-decoration: none;
        }
    }

    @media (max-width: 600px) {
        footer {
            padding: 20px;

            div > div:first-child small {
                display: block;
                margin-bottom: 20px;
            }
        }
    }
</style>

<script>
    export default {
        name: 'app-share-layout2',
        props: {
            corretor: Object,
            logotipo: String,
            print: Function
        },
        data () {
            return {
                tab: 'tab-valor'
            }
        },
        computed: {
            mobile () {
                return this.$vuetify.breakpoint.name === 'xs'
            },
            baseStyles () {
                return {
                    '--applicationLeft': this.$vuetify.application.left + 'px',
                    '--scrollWidth': this.$isMobile ? '0px' : '18px'
                }
            }
        },
        methods: {}
    }
</script>