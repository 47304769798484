import axios from 'axios'

export async function localizarPorCep (cep) {
    const url = 'https://api.postmon.com.br/v1/cep/' + cep

    return axios.get(url)
        .then(response => ({
            ...response.data,
            uf: response.data.estado
        }))
        .catch(() => ({}))

}
