// Referência: https://github.com/vuetifyjs/vuetify/blob/dev/src/mixins/toggleable.js

export function factory (prop = 'opened', event = 'update:opened') {
    return {
        name: 'toggleable',

        model: {
            prop,
            event
        },

        props: {
            [prop]: {
                required: false
            }
        },

        data () {
            return {
                isActive: !!this[prop]
            }
        },

        watch: {
            [prop] (val) {
                this.isActive = !!val
            },
            isActive (val) {
                !!val !== this[prop] && this.$emit(event, val)
                this.$emit('toggle', val)

                if (val) {
                    this.$emit('open', val)
                } else {
                    this.$emit('close', val)
                }
            }
        }
    }
}

const Toggleable = factory()

export default Toggleable
