import { formatDate } from '@/shared/formatters/formatDate'
import { useQuery } from '@/shared/services/graphQLClient'
import axios from 'axios'
import combineQuery from 'graphql-combine-query'
import NegocioFilter from '../models/NegocioFilter'
import NegocioFiltro from '../models/NegocioFiltro'
import { NegocioDetailQuery, NegociosEtapasQuery, NegociosExportacaoQuery, NegociosQuery, NegociosSimpleQuery } from './queries'

const baseUri = 'api/crm/negocios'

export default class {
    async listarPorEtapas (etapas, filtro) {
        const options = new NegocioFilter(filtro).toQueryParams()
        const etapasToRequest = etapas.filter(p => !filtro.etapas || filtro.etapas.includes(p.id))
        const requests = etapasToRequest.map(etapa => ({ request: { ...options, conditions: [{ field: 'etapaId', operator: '', value: etapa.id }, ...options.conditions], take: 10 } }))
        const { document, variables } = combineQuery('FooBarQuery').addN(NegociosEtapasQuery, requests)
        const responseValues = requests.length ? Object.values(await useQuery(document, variables)) : []

        const createResult = (etapa, response) => {
            const result = {
                etapa,
                valor: 0,
                quantidade: 0,
                negocios: response ? response.items.map(p => ({
                    ...p,
                    etapa: p.etapa.id,
                    etiquetas: p.etiquetas.map(q => q?.nome)
                })) : []
            }

            return Object.assign(result, response)
        }

        return etapas.map(etapa => {
            return createResult(etapa, responseValues.find(p => p.items[0]?.etapa?.id == etapa.id))
        })
    }

    async listarColuna (params) {
        const etapa = params.etapa
        const options = new NegocioFilter(params).toQueryParams()

        const { negocios } = await useQuery(NegociosSimpleQuery, { request: { ...options, conditions: [{ field: 'etapaId', operator: '', value: etapa.id }, ...options.conditions] } })

        return negocios.items?.map(p => ({
            ...p,
            etiquetas: p.etiquetas.map(q => q?.nome)
        }))
    }

    async listar (params) {
        const options = new NegocioFiltro(params).toQueryParams()

        const { negocios } = await useQuery(NegociosQuery, { request: { ...options } })

        return negocios.items
    }

    async get (id) {
        const { negocio } = await useQuery(NegocioDetailQuery, { id })

        return {
            ...negocio,
            etapa: negocio.etapa.id,
            etiquetas: negocio.etiquetas.map(q => q?.nome)
        }
    }

    async salvar (negocio) {
        const method = negocio.id ? 'put' : 'post'

        return axios[method](`${baseUri}/${negocio.id || ''}`, {
            ...negocio,
            negocioId: negocio.id,
            vendedorId: negocio.vendedor?.id,
            produtoId: negocio.produto?.id,
            contatos: negocio.contatos?.map(p => ({ id: p.id }))
        })
    }

    async excluirNegocios (ids) {
        return axios.delete(`${baseUri}`, {
            data: { ids }
        })
    }

    async listarEtapas () {
        const result = await axios.get(`${baseUri}/etapas`, { params: { cache: false } })

        return result.data
    }

    async listarEtiquetas () {
        const result = await axios.get(`${baseUri}/etiquetas`, { params: { cache: false } })

        return result.data
    }

    salvarEtapa (etapa) {
        const payload = {
            ...etapa,
            etapaId: etapa.id
        }

        if (payload.index < 0)
            delete payload.index

        return axios.put(`${baseUri}/etapas/${etapa.id}`, payload)
    }

    salvarEtiqueta (etiqueta) {
        return axios.put(`${baseUri}/etiquetas/${etiqueta.id}`, {
            ...etiqueta,
            etiquetaId: etiqueta.id
        })
    }

    excluirEtapa (etapa) {
        return axios.delete(`${baseUri}/etapas/${etapa.id}`)
    }

    excluirEtiqueta (etiqueta) {
        return axios.delete(`${baseUri}/etiquetas/${etiqueta.id}`)
    }

    adicionarAnotacao (anotacao) {
        return axios.post(`api/anotacoes`, anotacao)
    }

    adicionarAnotacaoAudio (form) {
        return axios.post(`api/anotacoes/audio`, form, { "Content-Type": "multipart/form-data" })
    }

    async exportar (filtro) {
        const variables = new NegocioFilter(filtro).toQueryParams()
        const response = await useQuery(NegociosExportacaoQuery, { request: variables })
        const data = response.negocios.items.map(toExport)
        const csvString = convertArrayOfObjectsToCSV({ data })
        const filename = `negocios-${new Date().toISOString()}.csv`

        downloadCSV({ csvString, filename })

        return response
    }
}

function toExport (negocio) {
    const anotacoes = negocio.anotacoes
        .map(p => `• ${p.criadoPor?.nome ?? 'Bot'} em ${formatDate(p.criadoEm)}: ${p.mensagem.replace(/"/g, '')}`)
        .reverse()
        .join('\r\n---\r\n')

    return {
        'Id': negocio.id,
        'Contrato': negocio.contrato || '',
        'Nome': negocio.nome,
        'Etapa': negocio.etapa.nome,
        'CriadoEm': negocio.criadoEm,
        'Fechamento': negocio.fechamento || '',
        'Valor': negocio.valor,
        'Etiquetas': (negocio.etiquetas.map(p => p.nome) || []).join(', '),
        'Contato.Id': negocio.contato?.id || '',
        'Contato.Nome': negocio.contato?.nome || '',
        'Contato.Email': negocio.contato?.email || '',
        'Contato.Documento': negocio.contato?.documento || '',
        'Contato.Telefones': (negocio.contato?.telefones || []).join(', '),
        'Contato.Avatar': negocio.contato?.avatar || '',
        'Contato.Cidade': negocio.contato?.cidade || '',
        'ProdutoId': negocio.produto?.id || '',
        'Produto.Ramo': negocio.produto?.ramo || '',
        'Produto.Nome': negocio.produto?.nome || '',
        'Vendedor': negocio.vendedor?.email || '',
        'Observacao': `"${anotacoes}"`
    }
}

function convertArrayOfObjectsToCSV ({ data, columnDelimiter, lineDelimiter }) {
    let result, ctr, keys;

    if (!data || !data.length) {
        return null;
    }

    columnDelimiter = columnDelimiter || ';';
    lineDelimiter = lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
        ctr = 0;
        keys.forEach(function (key) {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

function downloadCSV ({ csvString, filename }) {
    if (!csvString) return;

    const universalBOM = "\uFEFF";
    const a = window.document.createElement('a');

    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));
    a.setAttribute('download', filename || 'export.csv');
    window.document.body.appendChild(a);
    a.click();
}