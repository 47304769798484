import ErrorStatus from './ErrorStatus'
import ErrorContaExpirada from './ErrorContaExpirada'
import ErrorDadosIncompletos from './ErrorDadosIncompletos'

export default [
    {
        path: '/erro/status/:status',
        component: ErrorStatus,
        props: true
    },
    {
        path: '/erro/conta-expirada',
        component: ErrorContaExpirada,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/erro/dados-incompletos',
        component: ErrorDadosIncompletos,
        meta: {
            requiresAuth: true
        }
    }
]