import faixas from '../../../produto/model/faixaEtaria'
import RedeMapper from '../../../produto/model/redeCredenciadaMapper'

export default {
    isNew: state => state.selecionada && state.selecionada.id === undefined,
    salvando: state => state.salvando,
    isDirty: state => state.isDirty,
    manuais: state => {
        let selecionados = state.selecionada.opcoes.manualSelecao

        return state.manuais.map(p => ({
            ...p,
            informacoes: selecionados.length ? p.informacoes.filter(q => selecionados.includes(q.tipoId)) : p.informacoes
        }))
    },

    cotacao: state => {
        if (!state.selecionada) {
            return undefined
        }

        let cenariosOrdenados = state.selecionada.cenarios.slice(0).sort((a, b) => a.operadora.nome.localeCompare(b.operadora.nome))
        let grupos = state.selecionada.grupos.map(grupo => ({
            ...grupo,
            index: state.selecionada.grupos.indexOf(grupo),
            cenarios: calcularCenarios(state, cenariosOrdenados, grupo, state.selecionada.somenteCenarioValido),
            vidasTotal: Object.entries(grupo.vidas).map(p => p[1]).reduce((p, q) => p + q, 0)
        }))

        cenariosOrdenados = cenariosOrdenados.filter(cenario => grupos.every(p => p.cenarios.find(q => q.key === cenario.key)))

        let cotacao = {
            ...state.selecionada,
            grupos: grupos,
            multiGrupo: grupos.length > 1,
            vidasTotal: grupos.map(p => p.vidasTotal).reduce((p, q) => p + q, 0),
            operadoras: agruparOperadoras(cenariosOrdenados),
            opcoesSelecionadas: Object.keys(state.selecionada.opcoes).filter(key => state.selecionada.opcoes[key]),
            cenarios: cenariosOrdenados.map(cenario => ({
                ...cenario,
                valorTotal: grupos.map(p => p.cenarios.find(q => q.key === cenario.key).valorTotal).reduce((p, q) => p + q, 0)
            }))
        }

        return cotacao
    }
}

const calcularCenarios = (state, cenariosOrdenados, grupo, somenteCenarioValido) => {
    const cenarios = cenariosOrdenados
        .map(cenario => {
            const grupoCenario = cenario.avisos.length === 0 ? grupo.cenarios[cenario.key] || {} : {}
            const redeMapper = new RedeMapper(grupoCenario.redeCredenciada)
            const valores = Object.assign({}, ...faixas.map(p => ({
                [p]: calcularValorFaixa(grupoCenario, p, grupo.vidas[p] || 0)
            })))

            const opcoes = cenario.opcoes
                .map(p => p.planos.map(q => ({
                    ...q,
                    iof: p.administradora.iof,
                    administradora: p.administradora,
                    produtoNome: p.produto.value.split(' - ')[0],
                    manutencao: p.manutencao,
                    modalidade: grupoCenario.modalidade
                })))
                .reduce((t, p) => t.concat(p), [])

            const selecionado = opcoes.find(p => p.id === grupoCenario.id) || {
                modalidade: grupoCenario.modalidade,
                entidade: grupoCenario.entidade,
                administradora: opcoes.length ? opcoes[0].administradora : undefined,
                iof: 0
            }

            return {
                ...cenario,
                avisos: validarCenario(grupo, cenario, valores, selecionado),
                atualizando: grupoCenario.atualizando,
                selecionado: selecionado,
                redeSumario: redeMapper.sumario(),
                redeCredenciada: grupoCenario.redeCredenciada,
                valores: valores,
                iof: selecionado.iof,
                valorTotal: Object.values(valores).reduce((p, t) => (t += p), 0) * (1 + selecionado.iof)
            }
        })

    if (somenteCenarioValido)
        return cenarios.filter(p => p.avisos.length === 0 && grupo.cenarios[p.key] && grupo.cenarios[p.key].valores && grupo.cenarios[p.key].valores.length)

    return cenarios
}

const calcularValorFaixa = (cenario, faixa, vidas) => {
    if (!cenario) {
        return 0
    }

    if (!cenario.valores) {
        return 0
    }

    let idade = parseInt(faixa.split('-')[0])
    let linha = cenario.valores.find(p => p.de <= idade && p.ate >= idade)
    if (!linha) {
        return null
    }

    return linha.valor * vidas
}

const vidasToArray = (vidas) => {
    return Object.entries(vidas)
        .map(p => ({
            key: p[0],
            de: parseInt(p[0].split('-')[0]),
            ate: parseInt(p[0].split('-')[1]),
            value: p[1]
        }))
}

const validarCenario = (grupo, cenario, valores, selecionado) => {
    const avisos = [...cenario.avisos];
    const faixasSelecionadas = vidasToArray(grupo.vidas).filter(p => p.value > 0)
    const vidaMin = Math.min(...faixasSelecionadas.map(p => p.de))
    const vidaMax = Math.max(...faixasSelecionadas.map(p => p.ate))

    const faixasAtendidas = vidasToArray(grupo.vidas).filter(p => valores[p.key] != null)
    const idadeMinAceita = Math.min(...faixasAtendidas.map(p => p.de))
    const idadeMaxAceita = Math.max(...faixasAtendidas.map(p => p.ate))

    if (selecionado.manutencao)
        avisos.push(`Em manutenção`)

    if (vidaMax > idadeMaxAceita)
        avisos.push(`Até ${idadeMaxAceita} anos`)

    if (vidaMin < idadeMinAceita)
        avisos.push(`A partir de ${idadeMinAceita} anos`)

    return avisos
}

const agruparOperadoras = (cenarios) => {
    let operadoras = []

    cenarios.forEach(cenario => {
        let operadora = operadoras.find(p => p.id === cenario.operadora.id)

        if (!operadora) {
            operadoras.push(operadora = {
                id: cenario.operadora.id,
                nome: cenario.operadora.nome,
                logotipo: cenario.operadora.logotipo,
                cenarios: 0,
                cor: cores[cenario.operadora.id]
            })
        }

        operadora.cenarios++
    })

    return operadoras.sort((a, b) => a.nome.localeCompare(b.nome))
}

const cores = {
    4: '#cc3727',
    5: '#f49f0d',
    6: '#d20124',
    37: '#3cc0bf',
    50: '#ed3439',
    91: '#b2daa9',
    93: '#0f5896',
    117: '#7593cd',
    1353: '#d61313',
    1356: '#e81a1e',
    1436: '#00a651',
    1463: '#e63323',
    1585: '#3fbb7c'
}