import store from '@/store'

const CotacaoLista = () => import( /* webpackChunkName: "cotacao-chunk" */ './CotacaoLista')
const Cotacao = () => import( /* webpackChunkName: "cotacao-chunk" */ './Cotacao')
const CotacaoShare = () => import( /* webpackChunkName: "cotacao-chunk" */ './CotacaoShare')
const CotacaoPrint = () => import( /* webpackChunkName: "cotacao-chunk" */ './CotacaoPrint')

const registerModule = (to, from, next) => {
    let moduleName = 'cotacao'
    if (store._modules.root._children[moduleName]) {
        return next()
    }
    import( /* webpackChunkName: "cotacao-chunk" */ '@/pages/cotacao/store').then(m => {
        store.registerModule(moduleName, m.default)
        next()
    })
}

export default {
    default: [{
            path: '/cotacoes',
            component: CotacaoLista,
            beforeEnter: registerModule
        },
        {
            path: '/cotacoes/:id',
            component: Cotacao,
            beforeEnter: registerModule
        }
    ],
    root: [

        {
            path: '/cotacoes/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/share',
            component: CotacaoShare,
            beforeEnter: registerModule,
            props: true
        }, {
            path: '/cotacoes/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/print',
            component: CotacaoPrint,
            beforeEnter: registerModule,
            props: true
        }
    ]
}