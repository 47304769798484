import * as types from './types'
import Service from '../apis/contatoApi'
import ConversaService from '../../conversa/store/service'
import uuidv4 from '@/shared/services/uuid'
import router from '@/router'
import { UnauthorizedError, NotFoundError } from '@/shared/errors'

var service = new Service()
var conversaService = new ConversaService()

export default {
    [types.LISTAR] (context, payload) {
        context.commit(types.LISTAR)

        return service
            .listar(payload)
            .then(response => context.commit(types.LISTADO_SUCESSO, {
                ...payload,
                ...response.data
            }))
    },

    [types.BUSCAR] (context, payload) {
        context.commit(types.BUSCAR)
        context.commit("tarefas/reset")

        return service
            .buscar(payload)
            .then(data => {
                context.dispatch("tarefas/listar", { contatoId: payload })
                context.commit(types.BUSCAR_SUCESSO, data)
            })
            .catch(ex => {
                if (ex instanceof NotFoundError)
                    return router.push('/erro/status/404')

                if (ex instanceof UnauthorizedError)
                    return router.push('/erro/status/403')

                throw ex;
            })
    },

    [types.LISTAR_CONVERSA] (context, payload) {
        return conversaService
            .listar(payload, { id: '' })
            .then(data => context.commit(types.LISTAR_CONVERSA, data))
    },

    [types.ENVIAR_EMAIL] ({ commit, rootGetters }, payload) {
        payload.conversaId = payload.conversaId || uuidv4()
        payload.mensagemId = payload.mensagemId || uuidv4()
        payload.destinatarios = payload.destinatarios.map(p => ({ ...p, contatoId: p.id }))

        const user = rootGetters['conta/usuario']
        const mensagem = {
            ...payload,
            origem: 'Enviado',
            status: 'Enviado',
            enviadoEm: new Date().toISOString(),
            remetente: { nome: user.nome, email: user.email, avatar: user.avatar },
            destinatarios: payload.destinatarios.map(p => ({ ...p, status: 'Enviado' })),
            anexos: payload.files.map(p => ({
                nome: p.name,
                uri: URL.createObjectURL(p)
            }))
        }

        commit(types.LISTAR_CONVERSA, mensagem)

        return conversaService.enviar(payload)
    },

    [types.REGISTRAR_HISTORICO] (context, payload) {
        return service.registrarHistorico(payload)
    },

    [types.SALVAR] (context, payload) {
        return service.salvar(payload)
    },

    [types.EXCLUIR] (context, payload) {
        // context.commit(types.EXCLUIR)
        if (confirm('Deseja realmente excluir os contatos selecionados?')) {
            return service
                .excluir(payload)
                .then(() => context.commit(types.EXCLUIR_SUCESSO, payload))
        }
    }
}