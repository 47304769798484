<template>
    <v-dialog v-model="isActive" max-width="600">
        <template v-slot:activator="activator">
            <slot name="activator" v-bind="activator" />
        </template>

        <v-row no-gutters>
            <v-col sm="4" class="hidden-xs-only">
                <v-row class="primary fill-height pt-12" justify="center">
                    <avatar :username="model.nome" :size="128" :src="model.avatar || '/img/unknown-avatar.png'" class="mb-5" color="grey" background-color="#eaeaea"></avatar>
                </v-row>
            </v-col>
            <v-col sm="8" cols="12">
                <v-card flat>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-card-title>
                            Contato
                            <v-spacer></v-spacer>
                            <v-btn icon :href="`/crm/contatos/${model.id}`" v-if="model.id">
                                <v-icon>mdi-open-in-new</v-icon>
                            </v-btn>
                            <v-btn icon @click="isActive = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text v-if="model">
                            <v-text-field label="Email" v-model="model.email" type="email" :rules="rules.email" prepend-icon="mdi-email-outline" @input="checkEmail" />
                            <v-text-field label="Nome*" v-model="model.nome" :rules="rules.nome" prepend-icon="mdi-account" />
                            <contato-telefone-lista v-model="model.telefones" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="confirmar" :loading="loading" :disabled="!valid">Confirmar</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-dialog>
</template>

<script>
    import axios from 'axios'
    import rules from '@/shared/rules'
    import Toggleable from '@/components/mixins/toggleable'
    import Service from '../apis/contatoApi'

    export default {
        name: 'contato-dialog',
        mixins: [Toggleable],
        props: {
            value: Boolean,
            contato: Object
        },
        data () {
            return {
                loading: false,
                valid: true,
                rules: {
                    nome: [rules.required()],
                    email: [rules.email()]
                },
                service: new Service()
            }
        },
        computed: {
            model: {
                get () {
                    return Object.assign({}, this.contato)
                },
                set (value) {
                    this.$emit('update:contato', value)
                }
            }
        },
        methods: {
            checkEmail (email) {
                var isEmailValid = rules.email()(email)
                if (isEmailValid !== true)
                    return

                this.service
                    .buscarExistente(email)
                    .then(response => {
                        this.model = {
                            ...this.model,
                            ...response
                        }
                    })
            },
            confirmar () {
                if (!this.$refs.form.validate()) {
                    return
                }

                this.loading = true

                const request = this.model.id ?
                    axios.put('api/contatos', this.model) :
                    axios.post('api/contatos', { ...this.model, responsavelId: this.$user.id })

                request
                    .then(response => {
                        this.isActive = false
                        this.$emit('change', response.data)
                        this.$emit('update:contato', response.data)
                    })
                    .catch(this.$notify)
                    .finally(() => (this.loading = false))
            }
        }
    }
</script>