<template>
    <v-snackbar :value="isActive" :color="color" :multi-line="multiLine">
        <span>{{ message }}</span>
        <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click.stop="onClick" :color="buttonColor">{{label}}</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'app-snackbar',
        props: {
            notification: [Object, String, Error],
            callback: Function,
            label: {
                type: String,
                default: 'Ok'
            }
        },
        data () {
            return {
                isActive: false
            }
        },
        computed: {
            isError () { return this.notification instanceof Error },
            color () { return this.isError ? 'red' : '' },
            buttonColor () { return this.isError ? '' : 'orange' },
            message () { return this.getMessage() },
            multiLine () { return this.message && this.message.length > 40 }
        },
        methods: {
            onClick () {
                this.isActive = false

                if (this.callback) {
                    this.$nextTick(this.callback)
                }
            },
            getMessage () {
                let notification = this.notification

                if (typeof notification === 'string') {
                    return notification
                }

                if (this.isError) {
                    if (notification.response) {
                        let errors = notification.response.data.errors
                        let message = notification.response.data.message ?? notification.response.data.detail

                        if (errors) {
                            if (errors instanceof Array)
                                return errors.map(p => p.message).join('<br>')

                            return Object.entries(errors).map(p => p[1].join('')).join('<br>')
                        }

                        if (message) {
                            return message
                        }

                        if (typeof notification.response.data === 'string') {
                            return notification.response.data
                        }
                    }

                    if (notification.data && notification.data.message) {
                        return notification.data.message
                    }

                    if (notification.message) {
                        return notification.message
                    }
                }

                return undefined
            }
        },
        watch: {
            notification () {
                this.isActive = this.message !== undefined
            }
        }
    }
</script>