<template>

    <app-layout-error title="alal">
        <template v-slot:title>
            <img src="/img/logos/logotipo.svg" alt="Painel do Corretor" class="logotipo mb-5">
        </template>

        <p>
            Para continuar utilizando esta assinatura,<br> é necessário completar os <strong>dados cadastrais</strong>.<br>
            Por favor, peça para que seu administrador acesse a conta.
        </p>

        <template v-slot:actions>
            <v-btn to="/conta/entrar" text>Sair</v-btn>
        </template>

    </app-layout-error>

</template>

<style lang="scss" scoped>
    img.logotipo {
        width: 350px;
    }
</style>

<script>
    export default {
    }
</script>
<style lang="scss" scoped>
</style>