import axios from 'axios'

export default class {
    async salvar (equipe) {
        const response = await axios.post('api/equipe', equipe)

        return response.data
    }

    async remover (equipeId) {
        const response = await axios.delete(`api/equipe/${equipeId}`)

        return response.data
    }
}