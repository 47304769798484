<template>
    <div class="picture-input" ref="fileform">
        <input type="file" name="myfile" @change="select($event.target.files[0])" accept="image/*" />
        <slot>
            <div class="preview">
                <img :src="image" />
            </div>
            <p class="label">{{label}}</p>
        </slot>
    </div>
</template>

<style lang="scss" scoped>
    @mixin flex() {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .picture-input {
        @include flex();
        max-width: 100%;
        display: flex;
        position: relative;

        .preview {
            @include flex();
            flex: 1;
            padding: 0 10px;
            margin: 0 auto;
        }

        .label {
            flex: 0;
            color: #b3b3b3;
            font-weight: 500;
        }

        input[type="file"] {
            position: absolute;
            opacity: 0;
            height: 100%;
            width: 100%;
            cursor: pointer;
        }
    }
</style>

<script>
    export default {
        props: {
            prefill: String,
            label: {
                type: String,
                default: 'Arraste seu logotipo ou clique aqui'
            },
            format: {
                type: RegExp,
                default () {
                    return /\.(jpe?g|png|gif|svg)$/i
                }
            }
        },
        data () {
            return {
                image: this.prefill
            }
        },
        mounted () {
            if (!isDragAndDropCapable()) {
                return
            }

            ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
                this.$refs.fileform.addEventListener(evt, e => {
                    e.preventDefault()
                    e.stopPropagation()
                }, false)
            })

            this.$refs.fileform.addEventListener('drop', (e) => {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.select(e.dataTransfer.files[i])
                }
            })
        },
        methods: {
            select (file) {
                if (!file || !this.format.test(file.name)) {
                    alert('Arquivo inválido')
                    return
                }

                this.$emit('change', file)

                let reader = new FileReader()
                reader.addEventListener('load', () => (this.image = reader.result), false)
                reader.readAsDataURL(file)
            }
        }
    }

    function isDragAndDropCapable () {
        var div = document.createElement('div')

        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window &&
            'FileReader' in window
    }
</script>