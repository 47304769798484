import * as types from './types'
import AuthService from '../services/auth-service'
import CorretoraService from '../services/corretora-service'
import router from '../../../router'

const auth = new AuthService()
const corretoraService = new CorretoraService()

export default {
    [types.LOGIN_SOCIAL] (context, payload) {
        context.commit(types.ENTRAR)
        context.commit('conta/USUARIO_LIMPAR')

        return auth.loginSocial(payload)
    },

    [types.ENTRAR] (context, payload) {
        context.commit(types.ENTRAR)
        context.commit('conta/USUARIO_LIMPAR')

        return auth
            .login(payload.username, payload.password)
            .then(data => context.dispatch(types.LOGADO, data))
            .catch(error => {
                context.commit(types.LOGADO_FAIL)
                return Promise.reject(error)
            })
    },

    [types.LOGADO] (context, payload) {
        context.commit(types.LOGADO, payload)

        return context
            .dispatch('conta/USUARIO_RECUPERAR')
            .then(() => router.push(context.state.returnUrl || '/'))
    },

    [types.SAIR] (context) {
        context.commit(types.DESLOGADO)
        context.commit('conta/USUARIO_LIMPAR')
        context.commit('TOGGLE_DRAWER', null)
    },

    [types.SENHA_REDEFINIR] (context, payload) {
        auth.redefinirSenha(payload)
    },

    [types.REGISTRAR_CONTA] (context, payload) {
        if (payload.ticket) {
            return auth.registrarContaComConvite(payload)
        } else {
            return auth
                .registrarConta(payload.email, payload.senha)
                .catch(error => Promise.reject(error))
        }
    },

    [types.REGISTRAR_CORRETORA] (context, payload) {
        return corretoraService
            .registrar(payload)
            .then(() => context.dispatch('conta/USUARIO_RECUPERAR'))
            .then(() => router.push('/?event=trial-iniciado'))
    },

    async [types.INDICAR_CUPOM] ({ commit }, payload) {
        if (!payload)
            return

        commit(types.INDICAR_CUPOM, undefined)
        const cupomValido = await auth.validarCupom(payload)

        if (cupomValido) {
            commit(types.INDICAR_CUPOM, payload)
        } else {
            commit('NOTIFY', new Error('Cupom não localizado'))
        }
    }
}