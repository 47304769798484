/* eslint-disable no-console */

import store from '@/store'
import {
    register
} from 'register-service-worker'

const isProduction = process.env.NODE_ENV === 'production'
const swEnabled = 'serviceWorker' in navigator

if (isProduction && swEnabled) {
    var refreshing = false
    var updateAvailable = false

    register('/service-worker.js', {
        ready () {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        cached () {
            console.log('Content has been cached for offline use.')
        },
        updated (registration) {
            console.log('New content is available; please refresh.')

            updateAvailable = true
            store.commit('NOTIFY', {
                notification: 'Atualização disponível!',
                label: 'Atualizar',
                callback: () => {
                    if (registration.waiting)
                        registration.waiting.postMessage('skipWaiting')
                }
            })
        },
        offline () {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        }
    })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing && updateAvailable) {
            refreshing = true;
            window.location.reload();
        }
    });

    if (location.search.includes('forceUpdate') && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage('unregister')
    }
}