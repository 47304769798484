<template>
    <div class="icon-alert text-center">
        <v-icon x-large class="mb-3" v-if="icon" :color="iconColor">{{icon}}</v-icon>
        <p :class="textClasses">
            <slot>
                <div class="title">{{ title }}</div>
                <span v-html-safe="text"></span>
            </slot>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'empty-alert',
        props: {
            color: String,
            title: String,
            iconColor: String,
            icon: { type: String, default: 'mdi-alert-circle-outline' },
            text: { type: String, default: `Nenhum registro localizado.<br> Por favor, verifique as opções de filtro e tente novamente.` }
        },
        computed: {
            textClasses () {
                return {
                    [`${this.color}--text`]: true
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>