<template>
    <v-row no-gutters>
        <v-col cols="12">
            <app-picture-input @change="alterarAvatar" class="mt-5">
                <avatar :username="model.nome" :src="model.avatar" :size="128" />
            </app-picture-input>
        </v-col>
        <v-col cols="12">
            <v-form v-model="valid" ref="form" lazy-validation class="form-readonly">
                <v-card-text v-if="model" class="pb-0">
                    <v-text-field label="Nome*" v-model="model.nome" :rules="rules.nome" />
                    <v-text-field label="Email" v-model="model.email" type="email" :rules="rules.email" />
                    <AppUsuarioAutocomplete
                        label="Responsável"
                        v-model="model.responsavel"
                        :disabled="!$user.isAdmin"
                        :showAvatar="false"
                        return-object />
                    <AppMaskField label="Documento"
                        v-model="model.documento"
                        placeholder="CPF ou CNPJ"
                        :mask="['###.###.###-##', '##.###.###/####-##']" />
                    <CidadeAutocomplete v-model="model.cidade" label="Cidade" />
                    <ContatoTelefoneLista v-model="model.telefones" icon="" />
                    <v-textarea label="Observação" v-model="model.observacao" spellcheck="false" v-autosize hide-details rows="2" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="confirmar" :disabled="!valid" :loading="loading">Confirmar</v-btn>
                </v-card-actions>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
    import rules from '@/shared/rules'
    import Service from '../apis/contatoApi'
    import AppUsuarioAutocomplete from '@/components/app-usuario-autocomplete'

    export default {
        props: {
            value: Object,
            loading: Boolean,
            color: {
                type: String,
                default: 'secondary'
            }
        },
        data () {
            return {
                model: Object.assign({}, this.value),
                service: new Service(),
                valid: true,
                rules: {
                    nome: [rules.required()],
                    email: [rules.email()]
                }
            }
        },
        methods: {
            alterarAvatar (image) {
                this.service.avatar(this.model.id, image).then(uri => {
                    this.$notify('Foto alterada com sucesso')
                    this.$set(this.model, 'avatar', uri)
                })
            },
            confirmar () {
                if (!this.$refs.form.validate())
                    return

                const payload = { ...this.model }

                payload.responsavelId = payload.responsavel.id

                this.$emit('confirm', payload)
            }
        },
        components: {
            AppUsuarioAutocomplete
        }
    }
</script>