<template>
    <v-list-item :href="href" :to="url" v-if="isVisible">
        <v-list-item-icon v-if="icon">
            <v-icon v-text="icon" />
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title v-text="title" />
        </v-list-item-content>
        <v-list-item-action v-if="betaLabel">
            <v-chip x-small class="purple">{{release.toLowerCase()}}</v-chip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    export default {
        props: {
            icon: String,
            title: String,
            url: String,
            href: String,
            perfil: String,
            release: {
                type: String,
                default: 'RTM',
                validator: (val) => ['ALPHA', 'BETA', 'RTM'].includes(val)
            }
        },
        computed: {
            betaLabel () { return this.release !== 'RTM' },
            isVisible () {
                const release = () => {
                    switch (this.release) {
                        case 'RTM':
                        case 'BETA': return true;

                        case 'ALPHA': return this.$user.email.endsWith("@trindadetecnologia.com.br");
                    }
                }

                const perfil = () => {
                    if (!this.perfil)
                        return true

                    return this.perfil === this.$user.perfil
                }

                return release() && perfil()
            }
        }
    }
</script>