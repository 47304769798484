import Vue from 'vue'
import { track, init } from '@/shared/services/tracking'

init()

Vue.mixin({
    methods: {
        $track (event, props) {
            if (!this.$user)
                return

            track(event, props)
        }
    }
})