export default {
    filtro: state => state.filtro,
    credenciados: state => state.credenciados,
    coordenadas: state => state.coordenadas,
    loading: state => state.localizandoCredenciados || state.planos === undefined,

    markers: state => state.credenciados.map(m => ({
        ...m,
        icon: getIcon(m)
    })),

    planos: state => (state.planos ? state.planos
        .map(item => ({
            ...item.plano,
            logotipo: item.operadora.logotipo,
            operadora: item.operadora.nome,
            valor: item.valorReferencia
        }))
        .sort((a, b) => a.valor - b.valor) : undefined)
}

function getIcon (crendeciado) {
    let color = crendeciado.nome.startsWith('Lab') ? 'blue' : 'red'
    return `/img/markers/map-marker-icon-${color}.png`
}