<template>
    <div class="host">
        <v-combobox
            v-model="contato"
            v-bind="$attrs"
            :items="items"
            :search-input.sync="search"
            :append-icon="appendIcon"
            :filter="filter"
            item-text="nome"
            autocomplete="off"
            ref="input"
            class="contato-input"
            return-object
            @click:append="toggleDialog">

            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title class="grey--text"> {{noDataText}} </v-list-item-title>
                </v-list-item>
            </template>

            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                    <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </template>

        </v-combobox>
        <contato-dialog v-model="isDialogOpened" :contato.sync="contato" v-if="showDetail"></contato-dialog>
    </div>
</template>

<script>
    import caseInsensitiveFilter from '@/components/shared/caseInsensitiveFilter';
    import { debounce } from '@/shared/functions';
    import axios from 'axios';

    export default {
        name: 'contato-input',
        props: {
            value: Object,
            searchInput: String,
            canInsert: Boolean,
            showDetail: Boolean
        },
        data () {
            return {
                search: this.value ? this.value.nome : '',
                isDialogOpened: false,
                items: [],
                filter: (item, queryText) => caseInsensitiveFilter(item, queryText, item.nome + ' - ' + item.email)
            }
        },
        computed: {
            appendIcon () { return this.showDetail ? 'mdi-account-details' : '' },
            contato: {
                get () {
                    return this.value
                },
                set (value) {
                    if (!value) {
                        this.$emit('input', undefined)
                        return;
                    }

                    if (typeof value == 'object') {
                        this.$emit('input', value)
                        return;
                    }

                    if (this.canInsert)
                        this.$emit('input', this.search)

                    if (this.search !== this.value?.nome)
                        setTimeout(() => this.search = this.value?.nome ?? '');
                }
            },
            noDataText () {
                return this.search ? 'Nenhum contato encontrado' : 'Digite para pesquisar'
            }
        },
        methods: {
            toggleDialog () {
                this.isDialogOpened = !this.isDialogOpened
            },
            remove (item) {
                this.$emit('remove', item)
                this.contato = this.contato.filter(p => p.id !== item.id)
            }
        },
        watch: {
            search: {
                immediate: true,
                handler: debounce(function (search) {
                    this.$emit('update:searchInput', search)

                    if (this.value?.nome === search)
                        return

                    if (search) { axios.get('api/contatos?searchText=' + search).then(response => this.items = response.data.items) }
                    else { this.items = [] }
                }, 500)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .host ::v-deep {
        .v-input__icon--append .v-icon {
            transform: none;
            cursor: pointer;
        }
    }
</style>