import * as types from './types'
import CotacaoService from '../service'

var cotacaoService = new CotacaoService()

export default {
    [types.LISTAR] (context, payload) {
        return cotacaoService
            .listar(payload)
            .then(response => context.commit(types.LISTADO_SUCESSO, {
                ...payload,
                ...response
            }))
    },

    [types.EXCLUIR] (context) {
        let excluir = () => {
            if (context.state.excluindo.length) {
                cotacaoService
                    .excluir(context.state.excluindo)
                    .then(() => context.commit(types.EXCLUIR_SUCESSO))
            }
        }

        context.commit(types.EXCLUIR_PREPARAR)
        setTimeout(excluir, 6000)
    }
}