export default {
    loading: false,
    accessToken: undefined,
    publicToken: undefined,
    idToken: undefined,
    expiresAt: undefined,
    returnUrl: undefined,
    cupom: undefined,
    cadastroState: {
        trial: true
    },
    tenant: ''
}
