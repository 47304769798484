import Vue from 'vue'

Vue.mixin({
    methods: {
        $navigate (to) {
            if (to.startsWith('http') || to.startsWith('blob')) {
                window.open(to, '_blank', 'noopener')
            } else if (to.startsWith('mailto')) {
                let win = window.open(to, '_blank', 'noopener')
                win.close()
            } else {
                this.$router.push(to)
            }
        }
    }
})