import modalidades from './modalidade'
const queryString = require('query-string')

export default {
    create () {
        return {
            ramo: 'Saúde',
            modalidade: 'SaudePF',
            cidade: 'São Paulo - SP',
            credenciados: undefined,
            coparticipacao: undefined,
            compulsorio: undefined,
            entidade: undefined,
            profissao: undefined
        }
    },
    fromUrl (route, user) {
        if (!route.params?.modalidade) {
            return undefined
        }

        const modalidade = modalidades.findByKey(route.params.modalidade)

        if (!modalidade) {
            return undefined
        }

        const cidadeIsValid = /(.+) - \w{2}/.test(route.query?.cidade)
        const filtro = {
            ramo: modalidade.ramo,
            modalidade: modalidade.key,
            cidade: cidadeIsValid && route.query.cidade || user?.cidade
        }

        if (route.query.entidade !== undefined) {
            filtro.entidade = route.query['entidade']
        }
        if (route.query.profissao !== undefined) {
            filtro.profissao = route.query['profissao']
        }
        if (route.query.credenciados !== undefined) {
            let credenciados = route.query['credenciados']
            if (credenciados instanceof Array) {
                credenciados = credenciados.map(p => +p)
            } else {
                credenciados = [+credenciados]
            }

            filtro.credenciados = credenciados
        }
        if (route.query.coparticipacao !== undefined) {
            filtro.coparticipacao = route.query['coparticipacao'].toString() === 'true'
        }
        if (route.query.compulsorio !== undefined) {
            filtro.compulsorio = route.query['compulsorio'].toString() === 'true'
        }

        return filtro
    },

    toQueryObject (filtro) {
        let queryObject = Object.assign({}, filtro)
        delete queryObject.ramo
        delete queryObject.modalidade

        if (!modalidades.isAdesao(filtro.modalidade)) {
            delete queryObject.entidade
            delete queryObject.profissao
        }

        return queryObject
    },

    toQueryString (filtro) {
        let queryObject = this.toQueryObject(filtro)
        let qs = queryString.stringify(queryObject)

        return qs
    },

    toProdutoUrl (filtro) {
        let modalidade = modalidades.findByKey(filtro.modalidade).urlKey
        let qs = this.toQueryString(filtro)
        return `/produtos/${modalidade}?${qs}`
    },

    areEqual (filtro1, filtro2) {
        return JSON.stringify(filtro1) === JSON.stringify(filtro2)
    }
}
