import redeCredenciadaMapa from '../components/rede-credenciada-mapa/store'

export default {
    namespaced: true,
    modules: {
        redeCredenciadaMapa,
        tarefas: {
            namespaced: true,
            state: {
                filtro: {
                    periodo: 'Até hoje',
                    status: 'todas',
                    responsavel: undefined,
                }
            },
            mutations: {
                SET_PERIODO (state, payload) {
                    state.filtro.periodo = payload
                },
                SET_STATUS (state, payload) {
                    state.filtro.status = payload
                },
                SET_RESPONSAVEL (state, payload) {
                    state.filtro.responsavel = payload
                }
            }
        }
    }
}
