<template>
    <AppMaskField
        ref="input"
        type="tel"
        autocomplete="no"
        :prepend-icon="icon"
        :mask="mask"
        :value="value"
        v-bind="$attrs"
        v-on="$listeners">
        <template v-slot:append>
            <v-btn icon class="whatsapp" @click="openWhatsApp" v-if="shareText && value.length == 11" title="Abrir WhatsApp">
                <v-icon color="green">mdi-whatsapp</v-icon>
            </v-btn>
        </template>
    </AppMaskField>
</template>

<script>
    export default {
        props: {
            value: String,
            shareText: String,
            icon: {
                type: String,
                default: "mdi-phone"
            }
        },
        data () {
            return {
                mask: ['(##) ####-####', '(##) #####-####']
            }
        },
        methods: {
            focus () {
                this.$refs.input.focus();
            },
            openWhatsApp () {
                const url = `https://api.whatsapp.com/send?phone=55${this.value}&text=${this.shareText}`

                window.open(url, '_blank', 'noopener')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .whatsapp {
        position: absolute;
        right: 0;
        top: -6px;
    }
</style>