const ContaLayout = () => import(/* webpackChunkName: "conta-chunk" */ './layouts/ContaPage')

export default [
    {
        path: '/conta',
        redirect: '/conta/perfil',
        component: ContaLayout,
        children: [
            {
                path: 'perfil',
                component: () => import(/* webpackChunkName: "conta-chunk" */ './components/conta-perfil')
            },
            {
                path: 'corretora',
                component: () => import(/* webpackChunkName: "conta-chunk" */ './components/conta-corretora'),
                meta: {
                    authorize: ['Administrador']
                }
            },
            {
                path: 'assinatura',
                component: () => import(/* webpackChunkName: "conta-chunk" */ './components/AssinaturaForm'),
                meta: {
                    authorize: ['Administrador']
                }
            },
            {
                path: 'usuarios',
                component: () => import(/* webpackChunkName: "conta-chunk" */ './components/usuario-page'),
                meta: {
                    authorize: ['Administrador']
                }
            },
            {
                path: 'equipes',
                component: () => import(/* webpackChunkName: "conta-chunk" */ './components/equipe-page'),
                meta: {
                    authorize: ['Administrador']
                }
            },
            {
                path: 'integracoes',
                component: () => import(/* webpackChunkName: "conta-chunk" */ './components/conta-integracao')
            },
            {
                path: '/preferencias/negocios',
                component: () => import(/* webpackChunkName: "conta-chunk" */ '@/pages/crm/negocio/pages/NegocioConfiguracaoPage'),
                meta: {
                    authorize: ['Administrador']
                }
            },
            {
                path: '/preferencias/cotacao',
                component: () => import(/* webpackChunkName: "conta-chunk" */ './components/CotacaoPreferenciasPage'),
                meta: {
                    authorize: ['Administrador']
                }
            }
        ]
    }
]
