<template>
    <div class="google-map" :class="{ grey: loading }" :id="mapName">
        <slot name="infoWindow"></slot>
    </div>
</template>
<script>
    var google = window.google
    var MarkerClusterer = window.MarkerClusterer

    import { debounce } from '@/shared/functions'

    export default {
        name: 'google-map',
        props: {
            name: String,
            markers: Array,
            zoom: Number,
            loading: Boolean,
            center: undefined,
            template: {
                type: String,
                default: "<strong>${this.nome}</strong><br><span>${this.logradouro}, ${this.numero} - ${this.bairro}</span>"
            }
        },
        data: function () {
            return {
                mapName: this.name + '-map',
                map: null,
                mapMarkers: [],
                bounds: null,
                markerCluster: undefined,
                infowindow: undefined
            }
        },
        methods: {
            setMarkers () {
                if (this.markerCluster) {
                    this.markerCluster.clearMarkers()
                }

                this.mapMarkers = this.markers.map((coord) => {
                    let position = new google.maps.LatLng(coord.latitude, coord.longitude)
                    let marker = new google.maps.Marker({
                        position,
                        map: this.map,
                        icon: coord.icon
                    })

                    google.maps.event.addListener(marker, 'click', () => {
                        let info = new Function(`return \`${this.template}\`;`).call(coord)

                        this.infowindow.setContent(info)
                        this.infowindow.open(this.map, marker)
                    })

                    return marker
                })

                let clusterOptions = {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                    maxZoom: 13
                }

                this.markerCluster = new MarkerClusterer(this.map, this.mapMarkers, clusterOptions)
            }
        },
        mounted: function () {
            google = window.google
            MarkerClusterer = window.MarkerClusterer

            let element = document.getElementById(this.mapName)
            let center = new google.maps.LatLng(this.center.latitude, this.center.longitude)
            let stylers = [{
                visibility: 'off'
            }]

            let options = {
                center: center,
                zoom: this.zoom,
                mapTypeControl: false,
                styles: [{
                    featureType: 'poi',
                    stylers: stylers
                }]
            }

            this.infowindow = new google.maps.InfoWindow()
            this.bounds = new google.maps.LatLngBounds()
            this.map = new google.maps.Map(element, options)
            this.setMarkers()

            this.map.addListener('center_changed', debounce(function () {
                let center = this.map.getCenter()
                this.$emit('centerChanged', {
                    latitude: center.lat(),
                    longitude: center.lng()
                })
            }, 500).bind(this))
        },
        watch: {
            center (value) {
                const center = new google.maps.LatLng(value.latitude, value.longitude)

                this.map.panTo(center)
                // this.map.fitBounds(this.bounds.extend(center))
            },
            markers () {
                this.setMarkers()
            }
        }
    }
</script>
<style lang="scss" scoped>
    .google-map {
        width: 100%;
        height: 500px;
        margin: 0 auto;
        background: gray;

        &.grey {
            filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="g"><feColorMatrix type="matrix" values="0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0 0 0 1 0"/></filter></svg>#g');
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
        }
    }
</style>