<template>
    <v-navigation-drawer
        v-model="drawer" app dark fixed
        :mini-variant="mini"
        :mobile-breakpoint="mobileBreakpoint"
        :touchless="touchless">

        <v-list dense class="pa-0">
            <v-list-item class="px-2 py-1">
                <v-list-item-avatar @click="$navigate('/conta')" class="pointer">
                    <avatar :src="$user.avatar" :username="$user.nome" :size="40" />
                </v-list-item-avatar>
                <v-list-item-content>
                    <app-tenant-menu @info="infoDialog = true" />
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <template v-for="item in items">
                <AppMenuGroup v-if="item.subLinks" :key="item.url" v-bind="item" />
                <AppMenuItem v-else v-bind="item" :key="item.title" />
            </template>
        </v-list>

        <template v-slot:append>
            <v-divider light></v-divider>
            <v-list class="pa-0" dense>
                <v-list-item @click="navigateToConfiguracao">
                    <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Configurações</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="support">
                    <v-list-item-icon>
                        <v-icon>mdi-help-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Ajuda</v-list-item-title>
                    </v-list-item-content>
                    <app-help v-model="infoDialog" />
                </v-list-item>
                <v-list-item @click="mini = !mini" class="mt-2" v-if="!$isMobile">
                    <v-list-item-icon>
                        <v-icon v-if="!mini">mdi-chevron-left</v-icon>
                        <v-icon v-else>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Minimizar</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <app-menu-install ref="installButton"></app-menu-install>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { emailConectadoService } from '@/pages/conta/components/conta-integracao';
    import { debounce } from '@/shared/functions';
    import * as types from '../../../auth/store/types';
    import AppMenuItem from './AppMenuItem';

    export default {
        name: 'app-menu',
        data () {
            return {
                mobileBreakpoint: 960,
                miniBreakpoint: 1264,
                touchless: false,
                infoDialog: false,
                items: []
            }
        },
        computed: {
            drawer: {
                get () {
                    return this.$store.state.core.drawer.opened
                },
                set (value) {
                    if (this.$store.state.core.drawer.opened !== value) {
                        this.$store.commit('TOGGLE_DRAWER', value)
                    }
                }
            },
            mini: {
                get () {
                    return this.$store.state.core.drawer.mini
                },
                set (value) {
                    if (this.$store.state.core.drawer.mini !== value) {
                        this.$store.commit('TOGGLE_DRAWER_MINI', value)
                    }
                }
            }
        },
        methods: {
            navigate (to) {
                if (!this.touchless) {
                    this.drawer = false
                }
                this.$navigate(to)
            },
            navigateToConfiguracao () {
                let target = '/conta'

                if (this.$route.path.startsWith('/crm/negocios'))
                    target = '/preferencias/negocios'

                this.$navigate(target)
            },
            logout () {
                this.$store.dispatch(types.SAIR).then(() =>
                    this.$router.push('/conta/entrar')
                )
            },
            support () {
                window.FreshworksWidget('open');
            },
            onResize: debounce(function () {
                this.touchless = window.innerWidth > this.mobileBreakpoint
            }, 250)
        },
        async mounted () {
            this.items = await getItems(this.$user)
            this.onResize()
            window.addEventListener('resize', this.onResize)

            if (this.$route.query.menu && this.$route.query.menu === 'mini')
                this.$store.commit('TOGGLE_DRAWER_MINI', true)
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.onResize)
        },
        components: {
            AppMenuItem,
            AppHelp: () => import( /* webpackChunkName: "layout-chunk" */ './AppHelp'),
            AppMenuInstall: () => import( /* webpackChunkName: "layout-chunk" */ './AppMenuInstall'),
            AppTenantMenu: () => import( /* webpackChunkName: "layout-chunk" */ './AppTenantMenu'),
            AppMenuGroup: () => import( /* webpackChunkName: "layout-chunk" */ './AppMenuGroup'),
        }
    }

    async function getItems (user) {
        const emailConectado = await emailConectadoService.buscar(user.email)

        const items = [
            {
                title: 'Início',
                icon: 'mdi-home',
                url: '/'
            },
            {
                title: 'Produtos',
                icon: 'mdi-view-dashboard',
                url: '/produtos'
            },
            {
                title: 'Cotações',
                icon: 'mdi-file-document',
                url: '/cotacoes'
            },
            {
                title: 'Contatos',
                icon: 'mdi-account-box-outline',
                url: '/crm/contatos'
            },
            {
                title: 'Comissão',
                icon: 'mdi-ticket-percent',
                url: '/comissao',
                perfil: "Administrador",
                release: 'BETA'
            },
            {
                title: 'Vendas',
                icon: 'mdi-finance',
                subLinks: [
                    {
                        title: 'Negócios',
                        url: '/crm/negocios'
                    },
                    {
                        title: 'Oportunidades',
                        url: '/crm/cross-selling'
                    },
                    {
                        title: 'Relatórios',
                        url: '/crm/dashboard'
                    },
                    {
                        title: 'Automações',
                        url: '/crm/automacao',
                        perfil: "Administrador"
                    }
                ]
            },
        ]

        if (emailConectado?.conectado === true) {
            items.splice(4, 0,
                {
                    title: 'E-mails',
                    icon: 'mdi-email-outline',
                    url: '/crm/conversas'
                })
        }

        return items
    }
</script>