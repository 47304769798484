import * as types from './types'

export default {
    [types.LISTAR] (state) {
        state.loading = true
    },

    [types.LISTADO_SUCESSO] (state, payload) {
        if (payload.skip === 0) {
            state.contatos = []
        }

        state.loading = false
        state.searchText = payload.searchText
        state.contatos = state.contatos.concat(payload.items)
        state.count = payload.count
    },

    [types.BUSCAR] (state) {
        state.loading = true
        state.contato = undefined
    },

    [types.BUSCAR_SUCESSO] (state, payload) {
        state.loading = false
        state.contato = payload
        state.conversa = []
    },

    [types.LISTAR_CONVERSA] (state, payload) {
        state.conversa = state.conversa.concat(payload)
    },
    [types.RESET_CONVERSA] (state) {
        state.conversa = []
    },

    [types.SELECIONAR] (state, payload) {
        if (!payload) {
            state.selecionados = []
            return
        }

        let index = state.selecionados.findIndex(p => p === payload)

        if (index > -1) state.selecionados.splice(index, 1)
        else state.selecionados.push(payload)
    },

    [types.EXCLUIR_SUCESSO] (state, payload) {
        for (let id of payload) {
            let index = state.contatos.findIndex(p => p.id === id)

            state.contatos.splice(index, 1)
        }

        state.selecionados = []
    }
}