<template>
    <v-row no-gutters>
        <v-col cols="3" class="hidden-xs-only">
            <v-subheader>{{header}}</v-subheader>
        </v-col>
        <v-col cols="12" sm="9" class="content">
            <slot></slot>
        </v-col>
    </v-row>
</template>

<style lang="scss" scoped>
    .content {
        position: relative;
    }

    .col,
    .col-12 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
</style>

<script>
    export default {
        name: 'app-form-row',
        props: {
            header: String
        }
    }
</script>