const state = {
    snackbar: {},
    chat: {
        opened: false,
        showIcon: false
    },
    drawer: {
        opened: null,
        mini: false
    }
}

const actions = {
    'NOTIFY' (context, payload) {
        context.commit('NOTIFY', {})

        setTimeout(() => {
            context.commit('NOTIFY', payload)
        }, 0)
    }
}

const mutations = {
    'TOGGLE_DRAWER' (state, value) {
        state.drawer.opened = value
    },
    'TOGGLE_DRAWER_MINI' (state, value) {
        state.drawer.mini = value
    },
    'SET_CHAT' (state, value) {
        state.chat.opened = value
    },
    'SET_CHAT_ICON' (state, value) {
        state.chat.showIcon = value
    },
    'NOTIFY' (state, value) {
        let payload = {}

        if (value.notification) {
            payload = value
        } else {
            payload.notification = value
        }

        state.snackbar = payload
    }
}

const getters = {
    snackbar: state => state.snackbar
}

export default {
    state,
    actions,
    mutations,
    getters
}
