<template>
    <v-container class="pa-0">
        <v-row class="no-vertical-gutters">
            <v-col cols="8" sm="4">
                <app-mask-field label="CEP" mask="#####-###" @input="cepChange" :rules="rules.default" v-model="value.cep" data-test="cep-input"></app-mask-field>
            </v-col>
            <v-col cols="8" sm="6">
                <v-text-field label="Endereço" v-model="value.logradouro" :rules="rules.default"></v-text-field>
            </v-col>
            <v-col cols="4" sm="2">
                <v-text-field label="nº / cmpl." v-model="value.numero" :rules="rules.default" ref="numero" data-test="numero"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="no-vertical-gutters">
            <v-col cols="12" sm="4">
                <v-text-field label="Bairro" v-model="value.bairro" :rules="rules.default"></v-text-field>
            </v-col>
            <v-col cols="8" sm="6">
                <v-text-field label="Cidade" v-model="value.cidade" :rules="rules.default"></v-text-field>
            </v-col>
            <v-col cols="4" sm="2">
                <v-text-field label="UF" v-model="value.uf" :rules="rules.default"></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Vue from 'vue'
    import { localizarPorCep } from './endereco-service'
    import rules from '@/shared/rules'

    export default {
        name: 'AppEndereco',
        props: {
            value: {
                type: Object,
                default () {
                    return {
                        logradouro: '',
                        bairro: ''
                    }
                }
            }
        },
        data () {
            return {
                rules: {
                    default: [rules.required()]
                }
            }
        },
        methods: {
            cepChange (cep) {
                if (cep.length === 8) {
                    localizarPorCep(cep).then(data => {
                        Vue.set(this.value, 'logradouro', data.logradouro)
                        Vue.set(this.value, 'bairro', data.bairro)
                        Vue.set(this.value, 'cidade', data.cidade)
                        Vue.set(this.value, 'uf', data.uf)
                        Vue.set(this.value, 'numero', '')
                        this.$refs.numero.focus()
                    })
                }
            }
        }
    }
</script>