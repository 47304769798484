import axios from 'axios'
import { AcessoSimultaneo } from '@/shared/services/acesso-simultaneo'

export default class {
    alterarLogotipo (file) {
        let url = 'api/corretoras/logotipo'
        let formData = new FormData()
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        formData.append('file', file)
        return axios.post(url, formData, config)
    }

    alterarAvatar ({ usuarioId, image }) {
        let url = `api/corretora/usuarios/${usuarioId}/avatar`
        let formData = new FormData()
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        formData.append('file', image)

        return axios.put(url, formData, config).then(response => response.headers.location)
    }

    alterarEmail (email) {
        return axios.put('api/corretora/usuarios/solicitar-alteracao-email', {
            email
        })
    }

    recuperar () {
        const sessionService = new AcessoSimultaneo()

        return axios.get('api/account/info', {
            params: {
                sessionId: sessionService.sessionId,
                cache: false
            }
        })
    }

    salvar (usuario) {
        return axios.put('api/corretora/usuarios/', usuario)
    }
}