import * as types from './types'
import EquipeService from '../services/equipe-service'

var equipeService = new EquipeService()

export default {
    async [types.EQUIPE_SALVAR] ({ commit }, payload) {
        const response = await equipeService.salvar(payload)

        commit(types.EQUIPE_SALVAR_SUCCESS, response)
    },

    [types.EQUIPE_REMOVER] ({ commit }, payload) {
        return equipeService
            .remover(payload)
            .then(() => commit(types.EQUIPE_REMOVER_SUCCESS, payload))
    }
}