<template>
  <v-checkbox :label="labelComposed" :indeterminate="indeterminate" v-model="checked" hide-details></v-checkbox>
</template>

<script>
  export default {
    name: 'tristate-checkbox',
    props: ['label', 'value'],
    computed: {
      indeterminate () {
        return this.value === undefined
      },
      labelComposed () {
        return this.label + ': ' + (this.value === undefined ? 'indiferente' : this.value ? 'sim ' : 'não')
      },
      checked: {
        get () {
          return this.value
        },
        set (value) {
          if (this.value === false) {
            value = undefined
          }

          this.$emit('input', value)
        }
      }
    }
  }

</script>
