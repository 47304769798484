import { List } from '@/shared/List';

export default {
    board: state => state.board,
    negocio: state => state.negocio,
    negocios: state => state.board.map(p => p.negocios).reduce((p, q) => p.concat(q), []),
    negociosSelecionados: state => state.negociosSelecionados,
    etapas: state => state.etapas,
    etiquetas: state => state.etiquetas,
    etapaSet: state => List.fromObject(state.etapas),
    etiquetasSet: state => List.fromObject(state.etiquetas).sortBy(p => p.nome),
    filtro: state => state.filtro,
    personalizacaoHabilitada: (state, getters, rootGetters) => rootGetters.conta.usuario.assinatura.plano.negocioPersonalizacao
}