import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  selecionada: undefined,
  isDirty: false,
  salvando: false,
  manuais: []
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
