<template>
    <div>
        <PageHeader title="Integrações" description="Conecte seu conta com outros aplicativos através de diversas opções." />

        <IntegracaoCard title="Chave da API" image="/img/integrations/api.png">
            Acesse nossa <a href="https://corretor.es/api" class="text-decoration-none" target="_blank">documentação</a> para integrar sua conta com outros sistemas.
            <ApiKeyDialog v-model="apiKeyDialog" />
            <template v-slot:action>
                <v-btn depressed @click="apiKeyDialog = true">Gerar chave</v-btn>
            </template>
        </IntegracaoCard>

        <h2 class="title font-weight-regular mt-12">Zapier</h2>
        <zapier-app-directory
            app="painel-do-corretor"
            link-target="new-tab"
            theme="light"
            introcopy="hide"
            create-without-template="hide"
            use-this-zap="show" />
    </div>
</template>

<script>
    export default {
        data () {
            return {
                apiKeyDialog: false,
                leadDialog: false
            }
        },
        components: {
            IntegracaoCard: () => import( /* webpackChunkName: "integracao-chunk" */ './components/IntegracaoCard'),
            ApiKeyDialog: () => import( /* webpackChunkName: "integracao-chunk" */ './components/ApiKeyDialog')         
        }
    }
</script>