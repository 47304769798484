import { distinct } from '@/shared/functions'
import isPointWithinRadius from 'geolib/es/isPointWithinRadius';
import { List } from '@/shared/List';

const proximaLabel = 'Próximos à sua região'

export default class {
    constructor (rede, search) {
        this.rede = rede
        this.search = search
    }

    mapear (coordenadas) {
        const credenciados = this._localizarCredenciados(coordenadas)

        return List.from(credenciados)
            .groupBy(p => p.localizacao)
            .map(localizacao => ({
                name: localizacao.key,
                items: localizacao.items
                    .groupBy(p => p.regiao)
                    .sortBy(p => p.key)
                    .map(regiao => ({
                        name: regiao.key,
                        items: regiao.items
                            .map(credenciado => ({
                                id: credenciado.id,
                                tipo: credenciado.tipo,
                                credenciado: credenciado.nome,
                                isProximo: credenciado.isProximo,
                                atendimentos: this.rede.planos.filter(p => p.atendimentos && credenciado.id in p.atendimentos).length,
                            }))
                            .sort((a, b) => b.atendimentos - a.atendimentos || a.credenciado.localeCompare(b.credenciado))
                    }))
                    .sortToEnd(p => p.name == 'Laboratórios')
            }))
            .sortToStart(p => p.name == proximaLabel)
    }

    reduce (rede) {
        const redePadrao = {
            plano: {},
            credenciados: []
        }

        return {
            legenda: rede.map(p => p ? p.legenda : []).reduce((t, p) => Object.assign(t, {}, p), {}),
            credenciados: [...new Map(rede.map(p => p.credenciados).reduce((p, q) => p.concat(q), []).map(item => [item.id, item])).values()],
            planos: rede.map(p => p && p.planos[0] ? p.planos[0] : redePadrao)
        }
    }

    sumario () {
        let tiposObservados = ['Hospital', 'Laboratório']
        let credenciados = this._localizarCredenciados()
        let tipos = credenciados.map(p => p.nome).filter(distinct).map(p => credenciados.find(q => q.nome == p).tipo).filter(p => tiposObservados.indexOf(p) > -1).sort()

        return tipos.filter(distinct).map(tipo => ({
            tipo: tipo.replace('Hospital', 'Hospitais').replace('Laboratório', 'Laboratórios'),
            count: tipos.filter(q => q === tipo).length
        }))
    }

    _localizarCredenciados (coordenadas) {
        if (!this.rede) {
            return []
        }

        const dupCredenciados = this.rede.planos.map(p => p.credenciados).reduce((t, p) => t.concat(p), []).map(p => ({ ...p }))
        const uniqueIds = [...new Set(dupCredenciados.map(p => p.id + p.localizacao))];
        let credenciados = uniqueIds
            .map(p => dupCredenciados.find(q => q.id + q.localizacao == p))

        if (this.search) {
            const search = this.search.normalizeToSearch()
            credenciados = credenciados.filter(p => p.regiao.normalizeToSearch().indexOf(search) !== -1 || p.nome.normalizeToSearch().indexOf(search) !== -1)
        }

        if (coordenadas) {
            credenciados = credenciados.map(p => {
                const radius = 5000
                const isProximo = isPointWithinRadius(p, { latitude: coordenadas[0], longitude: coordenadas[1] }, radius)
                return {
                    ...p,
                    localizacao: isProximo ? proximaLabel : p.localizacao
                }
            })
        }

        credenciados
            .filter(p => p.tipo == 'Laboratório')
            .forEach(p => p.regiao = 'Laboratórios')

        return credenciados
    }
}