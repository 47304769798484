export const USUARIO_LIMPAR = 'USUARIO_LIMPAR'
export const USUARIO_RECUPERAR = 'USUARIO_RECUPERAR'
export const USUARIO_RECUPERADO = 'USUARIO_RECUPERADO'
export const USUARIO_ALTERAR = 'USUARIO_ALTERAR'
export const EMAIL_ALTERAR = 'EMAIL_ALTERAR'
export const CORRETORA_ALTERAR = 'CORRETORA_ALTERAR'
export const ALTERAR_AVATAR = 'ALTERAR_AVATAR'

export const LOGOTIPO_ALTERAR = 'LOGOTIPO_ALTERAR'
export const LOGOTIPO_ALTERADO = 'LOGOTIPO_ALTERADO'

export const ASSINATURA_RECUPERAR = 'ASSINATURA_RECUPERAR'
export const ASSINATURA_RECUPERADA = 'ASSINATURA_RECUPERADA'

export const VENCIMENTO_ALTERAR = 'VENCIMENTO_ALTERAR'
export const VENCIMENTO_ALTERAR_PENDING = 'VENCIMENTO_ALTERAR_PENDING'
export const VENCIMENTO_ALTERAR_SUCCESS = 'VENCIMENTO_ALTERAR_SUCCESS'
export const VENCIMENTO_ALTERAR_FAIL = 'VENCIMENTO_ALTERAR_FAIL'

export const PAGAMENTO_ALTERAR = 'PAGAMENTO_ALTERAR'
export const PAGAMENTO_ALTERAR_PENDING = 'PAGAMENTO_ALTERAR_PENDING'
export const PAGAMENTO_ALTERAR_SUCCESS = 'PAGAMENTO_ALTERAR_SUCCESS'
export const PAGAMENTO_ALTERAR_FAIL = 'PAGAMENTO_ALTERAR_FAIL'

export const PLANO_ALTERAR = 'PLANO_ALTERAR'
export const PLANO_ALTERAR_PENDING = 'PLANO_ALTERAR_PENDING'
export const PLANO_ALTERAR_SUCCESS = 'PLANO_ALTERAR_SUCCESS'
export const USUARIO_LISTAR = 'USUARIO_LISTAR'
export const USUARIO_LISTAR_SUCCESS = 'USUARIO_LISTAR_SUCCESS'

export const PERFIL_ALTERAR = 'PERFIL_ALTERAR'
export const CONVITE_REENVIAR = 'CONVITE_REENVIAR'

export const USUARIO_REMOVER = 'USUARIO_REMOVER'
export const USUARIO_REMOVER_SUCCESS = 'USUARIO_REMOVER_SUCCESS'

export const USUARIO_INCLUIR = 'USUARIO_INCLUIR'
export const USUARIO_ATUALIZAR = 'USUARIO_ATUALIZAR'
export const USUARIO_INCLUIR_SUCCESS = 'USUARIO_INCLUIR_SUCCESS'

export const OPERADORAS_RECUPERAR = 'OPERADORAS_RECUPERAR'
export const OPERADORAS_RECUPERAR_SUCCESS = 'OPERADORAS_RECUPERAR_SUCCESS'
export const OPERADORAS_SELECIONAR = 'OPERADORAS_SELECIONAR'

export const ASSINATURA_ATIVAR = 'ASSINATURA_ATIVAR'
export const ASSINATURA_REATIVAR = 'ASSINATURA_REATIVAR'
export const ASSINATURA_ENCERRAR = 'ASSINATURA_ENCERRAR'
