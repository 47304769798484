<template>
    <v-app id="inspire" light>
        <router-view></router-view>
        <app-snackbar :notification="snackbar.notification" :callback="snackbar.callback" :label="snackbar.label" style="z-index: 9999999">
        </app-snackbar>
        <AppChat />
    </v-app>
</template>

<script>
    import { createDataLayer } from '@/plugins/vueAnalytics'
    import { redirectToBeta } from '@/shared/services/redirectToBeta'
    import * as tracking from '@/shared/services/tracking'
    import Bugsnag from '@bugsnag/js'
    import 'url-change-event'
    import { mapGetters } from 'vuex'
    import * as types from './pages/auth/store/types'
    import { AcessoSimultaneo } from './shared/services/acesso-simultaneo'

    export default {
        data () {
            return {
                sessionService: new AcessoSimultaneo()
            }
        },
        computed: {
            ...mapGetters({
                snackbar: 'snackbar'
            })
        },
        methods: {
            linkUserToPlugins () {
                if (window.dataLayer) {
                    window.dataLayer.push(createDataLayer(this.$user))
                }

                const _hsq = window._hsq = window._hsq || [];
                const splitedName = this.$user.nome.split(' ')
                const nome = splitedName[0]
                const sobrenome = splitedName[splitedName.length - 1]
                const role = this.$user.isAdmin ? 'Administrador' : 'Usuário'

                _hsq.push(["identify", {
                    email: this.$user.email,
                    firstName: nome,
                    lastName: nome !== sobrenome ? sobrenome : '',
                    phone: this.$user.telefone || '',
                    city: this.$user.cidade
                }]);

                if (window.delighted) {
                    window.delighted.survey({
                        name: this.$user.nome,
                        email: this.$user.email,
                        recurringPeriod: 15778476,
                        initialDelay: 604800,
                        properties: {
                            corretora: this.$user.corretora,
                            assinatura: this.$user.corretoraId,
                            trial: this.$user.assinatura.trial,
                            cidade: this.$user.cidade,
                            phone: this.$user.telefone,
                            role
                        }
                    });
                }

                Bugsnag.setUser(this.$user.id, this.$user.email, this.$user.nome)
                Bugsnag.addMetadata('user', 'phone', this.$user.telefone)
                Bugsnag.addMetadata('user', 'role', role)
                Bugsnag.addMetadata('corretora', {
                    nome: this.$user.corretora,
                    assinatura: this.$user.corretoraId,
                    cidade: this.$user.cidade,
                })
            }
        },
        mounted () {
            const trackPageView = () => {
                setTimeout(() => {
                    const path = location.pathname + location.search
                    var _hsq = window._hsq = window._hsq || [];

                    _hsq.push(['setPath', path]);
                    _hsq.push(['trackPageView']);
                    this.$track("Página visualizada", {
                        page_title: document.title
                    });
                }, 100);
            }

            window.addEventListener('urlchangeevent', trackPageView);
            window.addEventListener('load', trackPageView);
        },
        watch: {
            $user () {
                tracking.identify(this.$user);
                if (!this.$user?.nome)
                    return

                this.linkUserToPlugins()
                this.sessionService.subscribe(this.$user, () => {
                    this.$notify('Você se conectou em outro local')
                    this.$router.push('/conta/entrar?action=acesso-simultaneo')
                    this.$store.dispatch(types.SAIR)
                });
            },
            $route () {
                redirectToBeta()
            }
        },
        destroyed () {
            this.sessionService.unsubscribe()
        },
        components: {
            AppChat: () => import('@/pages/core/layout/app-menu/AppChat'),
        }
    }
</script>

<style lang="scss">
    @import "./styles/theme";
</style>