<template>
    <v-form v-model="valid" ref="form">
        <v-container fluid class="pa-0">
            <v-row no-gutters>
                <v-col cols="12" sm="6" md="4" class="modalidade">
                    <produto-modalidade :value="filtro" @change="filtro = { ...filtro, ...$event }"></produto-modalidade>
                    <slot></slot>
                    <cidade-autocomplete
                        label="Cidade"
                        v-model="filtro.cidade"
                        :rules="rules.cidade"
                        v-if="showDetails"
                        required>
                    </cidade-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="8" class="pl-sm-4 mt-sm-9 checkboxes" v-if="showDetails">
                    <v-row no-gutters>
                        <v-col cols="12" order="last" order-sm="first" class="mb-4">
                            <tristate-checkbox label="Coparticipação" v-model="filtro.coparticipacao" class="ma-0"></tristate-checkbox>
                            <tristate-checkbox label="Compulsório" v-model="filtro.compulsorio" class="ma-0"></tristate-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <credenciado-autocomplete v-model="filtro.credenciados" label="Credenciados" :cidade="filtro.cidade"></credenciado-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import Modalidade from '../../model/modalidade'

    export default {
        name: 'produto-filtro',
        props: {
            value: Object,
            hideDetails: Boolean
        },
        data () {
            return {
                valid: false,
                filtro: {
                    ramo: this.value.ramo,
                    modalidade: this.value.modalidade,
                    profissao: this.value.profissao,
                    entidade: this.value.entidade,
                    cidade: this.value.cidade,
                    credenciados: this.value.credenciados,
                    coparticipacao: this.value.coparticipacao,
                    compulsorio: this.value.compulsorio
                },
                rules: {
                    cidade: [
                        (v) => !!v || 'Selecione uma cidade'
                    ]
                }
            }
        },
        computed: {
            modalidades () {
                return Modalidade.filterByRamo(this.filtro.ramo)
            },
            isAdesao () {
                return this.filtro.modalidade.endsWith('Adesao')
            },
            showDetails () {
                return !this.hideDetails
            }
        },
        watch: {
            filtro: {
                deep: true,
                handler () {
                    if (this.$refs.form.validate()) {
                        this.$emit('input', this.filtro)
                    }
                }
            },
            valid (value) {
                this.$emit('validate', value)
            },
            value () {
                this.filtro.ramo = this.value.ramo
                this.filtro.modalidade = this.value.modalidade
                this.filtro.profissao = this.value.profissao
                this.filtro.entidade = this.value.entidade
                this.filtro.cidade = this.value.cidade
                this.filtro.credenciados = this.value.credenciados
                this.filtro.coparticipacao = this.value.coparticipacao
                this.filtro.compulsorio = this.value.compulsorio
            }
        },
        components: {
            'ProdutoModalidade': () => import( /* webpackChunkName: "produto-chunk" */ './ProdutoModalidade'),
            'CredenciadoAutocomplete': () => import( /* webpackChunkName: "produto-chunk" */ './CredenciadoAutocomplete')
        }
    }
</script>

<style lang="scss" scope>
    .v-btn-toggle {
        display: flex !important;

        .v-btn {
            flex: 1;
        }

        .v-btn.v-size--default {
            height: 36px !important;
        }
    }

    .spacer {
        height: 14px;
    }
</style>