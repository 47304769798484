import { isLocalhost } from '@/shared/functions'
import VueCookies from 'vue-cookies'
import initialState from './initial-state'
import * as types from './types'

export default {
    [types.ENTRAR] (state) {
        state.loading = true
        state.idToken = undefined
        state.accessToken = undefined
        state.expiresAt = undefined
    },
    [types.LOGADO] (state, payload) {
        const expires = new Date(new Date().getTime() + payload.expires_in * 1000)

        state.loading = false
        state.idToken = payload.id_token
        state.accessToken = payload.access_token
        state.expiresAt = expires

        setCookie('token', payload.id_token, expires)
        setCookie('tenant', state.tenant, expires)
    },

    [types.SELECIONAR_TENANT] (state, payload) {
        state.tenant = payload
        setCookie('tenant', state.tenant)
    },

    [types.LOGADO_FAIL] (state) {
        state.loading = false
        Object.assign(state, initialState)
    },

    [types.DESLOGADO] (state) {
        Object.assign(state, initialState)
        VueCookies.remove('token')
        VueCookies.remove('tenant')
    },

    [types.INDICAR_RETURN_URL] (state, payload) {
        state.returnUrl = payload
    },

    [types.INDICAR_CUPOM] (state, payload) {
        state.cupom = payload
    },

    [types.INDICAR_TRIAL] (state, payload) {
        state.cadastroState.trial = payload
    },

    [types.SET_PUBLIC_TOKEN] (state, payload) {
        state.publicToken = payload
    }
}

function setCookie (key, value, expires = '1d') {
    VueCookies.set(key, value, expires, '/', getDomain(), true, 'none')

    function getDomain () {
        if (isLocalhost())
            return ''

        const mainDomain = location.host.split('.').reverse().slice(0, 3).reverse().join('.')
        return '.' + mainDomain
    }
}