import * as types from './types'

export default {
    [types.FILTRO_ATUALIZADO] (state, payload) {
        state.filtro = { ...payload }
    },
    [types.COORDENADA_ATUALIZADA] (state, payload) {
        state.coordenadas = payload
    },
    [types.PLANOS_ATUALIZADOS] (state, payload) {
        state.planos = payload
    },

    [types.CREDENCIADOS_LOCALIZAR] (state) {
        state.localizandoCredenciados = true
    },
    [types.CREDENCIADOS_LOCALIZADOS] (state, payload) {
        if (payload.houveAlteracao) {
            state.credenciados = payload.credenciados
        }
        state.localizandoCredenciados = false
    },
    [types.USUARIO_RECUPERADO] (state, payload) {
        if (!state.filtro.cidade && payload.cidade) {
            state.filtro.cidade = payload.cidade
            state.coordenadas = undefined
        }
    }
}