import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VMain,{staticClass:"white"},[_c(VContainer,{staticClass:"host pa-0",style:([_vm.baseStyles])},[_c(VToolbar,{attrs:{"color":"white","flat":""}},[_vm._t("toolbarLogo"),_c(VSpacer),_c(VBtn,{staticClass:"grey--text text--darken-2",attrs:{"icon":""},on:{"click":_vm.print}},[_c(VIcon,[_vm._v("mdi-printer")])],1)],2),_c('div',{staticClass:"header"},[_c(VContainer,{staticClass:"py-0"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pt-0 text-center text-sm-left"},[_c('img',{staticClass:"logotipo hidden-sm-and-up mb-5",attrs:{"src":_vm.logotipo,"alt":"Logotipo"}}),_vm._t("info")],2),_c(VCol,{staticClass:"pt-0 hidden-xs-only text-right"},[_c('img',{staticClass:"logotipo",attrs:{"src":_vm.logotipo,"alt":"Logotipo"}})])],1)],1)],1),_vm._t("default")],2)],1),_c('footer',[_c(VRow,{staticClass:"text-sm-left text-center"},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('small',[_vm._v(" Informativo de caráter referencial: valores, regras de comercialização, rede credenciada e condições contratuais são determinadas pelas seguradoras/operadoras e podem ser alterados pelas mesmas a qualquer momento. Reservamos o direito de corrigir eventuais erros, não vinculando esta oferta à prestação do serviço, que se dará somente no ato da assinatura do contrato. ")])]),_c(VCol,{staticClass:"text-sm-right text-center",attrs:{"cols":"12","md":"4"}},[_c('small',[_vm._v(" ©"+_vm._s(new Date().getFullYear())+" "),_c('a',{attrs:{"href":"http://paineldocorretor.com.br","target":"_blank","rel":"noopener"}},[_vm._v("Painel do Corretor")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }