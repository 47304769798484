import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import packageInfo from '@/../package.json'
import { isLocalhost } from '@/shared/functions'

if (!isLocalhost()) {
    const statusToIgnore = [401, 403, 404]
    const bugsnagClient = Bugsnag.start({
        apiKey: 'f7857fb4b2c46443d32b5a4973f1e879',
        appVersion: packageInfo.version,
        releaseStage: process.env.NODE_ENV,
        enabledReleaseStages: ['production', 'staging'],
        onError: (event) => {
            const error = event.originalError
            const isError = error instanceof Error
            const status = error.response?.status
            const statusCanNotify = !status || !statusToIgnore.includes(status)
            const notify = isError && statusCanNotify

            return notify
        },
        plugins: [
            new BugsnagPluginVue()
        ]
    })

    window.bugsnagClient = bugsnagClient

    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
}