import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_vm._t("toolbar",function(){return [_c(VAppBar,{attrs:{"app":"","dark":"","flat":"","color":"primary","hide-on-scroll":_vm.hideOnScroll,"scroll-off-screen":_vm.scrollOffScreen}},[(!_vm.navigateBack)?_c('app-drawer-button'):_c(VBtn,{attrs:{"icon":"","title":"Voltar"},on:{"click":_vm.back}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VToolbarTitle,{staticClass:"mr-8"},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2),_vm._t("search"),_c(VSpacer),_vm._t("actions"),_vm._t("extension",null,{"slot":"extension"})],2)]}),(_vm.loading)?_c(VMain,[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1):_c(VMain,[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }