<template>
  <v-text-field ref="password" :append-icon="icon" @click:append="iconCallback" :type="type" v-model="password" v-bind="$attrs"></v-text-field>
</template>

<script>
  export default {
    name: 'app-password-input',
    props: {
      value: String,
      showReveal: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        visivel: false
      }
    },
    computed: {
      icon () {
        if (!this.showReveal) {
          return ''
        }

        return this.visivel ? 'mdi-eye-off' : 'mdi-eye'
      },
      type () {
        return this.visivel ? 'text' : 'password'
      },
      password: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        }
      }
    },
    methods: {
      iconCallback () {
        this.visivel = !this.visivel
      },
      focus () {
        this.$refs.password.focus()
      }
    }
  }

</script>
