import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import NegocioFilter from '../models/NegocioFilter'

const state = {
    board: [],
    negociosSelecionados: [],
    etapas: [],
    etiquetas: [],
    negocio: {},
    filtro: new NegocioFilter()
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}