import * as types from './types'

export default {
    [types.USUARIO_LIMPAR] (state) {
        state.usuario = undefined
    },
    [types.USUARIO_RECUPERADO] (state, payload) {
        state.usuario = {
            ...state.usuario,
            ...payload
        }
    },

    [types.ASSINATURA_RECUPERADA] (state, payload) {
        state.assinatura = payload
        if (!state.assinatura.cartao) {
            state.assinatura.cartao = {}
        }
        if (!state.assinatura.dadosCobranca) {
            state.assinatura.dadosCobranca = {
                endereco: {}
            }
        }
    },

    [types.USUARIO_LISTAR_SUCCESS] (state, payload) {
        state.usuarios.lista = payload.usuarios.sort((a, b) => a.nome.localeCompare(b.nome))
        state.equipes.lista = payload.equipes
        state.plano = payload.plano

    },
    [types.LOGOTIPO_ALTERADO] (state, payload) {
        console.log('Logotipo alterado', payload)
    },
    [types.ALTERAR_AVATAR] (state, payload) {
        state.usuario.avatar = payload
    },

    [types.OPERADORAS_RECUPERAR_SUCCESS] (state, payload) {
        state.operadorasSelecionadas = payload
    },

    [types.OPERADORAS_SELECIONAR] (state, payload) {
        state.operadorasSelecionadas.find(p => p.id === payload.id).comercializada = payload.comercializada
    },

    // VENCIMENTO
    [types.VENCIMENTO_ALTERAR_PENDING] (state) {
        state.loadingManager.start('vencimento')
    },
    [types.VENCIMENTO_ALTERAR_SUCCESS] (state, payload) {
        state.assinatura.vencimento = payload
        state.loadingManager.complete('vencimento')
    },
    [types.VENCIMENTO_ALTERAR_FAIL] (state, payload) {
        state.loadingManager.complete('vencimento', payload)
    },

    // PAGAMENTO
    [types.PAGAMENTO_ALTERAR_PENDING] (state) {
        state.loadingManager.start('pagamento')
    },
    [types.PAGAMENTO_ALTERAR_SUCCESS] (state, payload) {
        state.assinatura = payload
        state.loadingManager.complete('pagamento')
    },
    [types.PAGAMENTO_ALTERAR_FAIL] (state, payload) {
        state.loadingManager.complete('pagamento', payload)
    },

    // PLANO
    [types.PLANO_ALTERAR_PENDING] (state) {
        state.loadingManager.start('plano')
    },
    [types.PLANO_ALTERAR_SUCCESS] (state, payload) {
        state.assinatura.plano = { ...payload, id: payload.planoId }
        state.loadingManager.complete('plano')
    }
}