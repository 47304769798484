import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    getters,
    mutations,
    actions,
    state: {
        lista: []
    }
}
