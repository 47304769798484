import { getPeriodos } from './components/charts/periodos'
import { track } from '@/shared/services/tracking'
import { maxTime, minTime } from 'date-fns/constants'
import isWithinInterval from 'date-fns/isWithinInterval'
import parseISO from 'date-fns/parseISO'
import api from './api'

export default {
    namespaced: true,
    state: {
        valorRecebido: 0,
        valorPendente: 0,

        items: [],
        pageInfo: { hasNextPage: true },
        filtro: {
            periodo: getPeriodos().find(p => p.isCurrent)
        }
    },
    getters: {
        valorTotal: (state) => state.valorPendente + state.valorRecebido
    },
    mutations: {
        SET_FILTRO (state, payload) {
            state.items = []
            state.pageInfo = { hasNextPage: true }
            state.filtro = payload
        },
        LIST_SUCCESS (state, result) {
            state.items = state.items.concat(result.nodes)
            state.pageInfo = result.pageInfo

            state.valorRecebido = result.valorRecebido
            state.valorPendente = result.valorPendente
        },
        ADD_SUCCESS (state, item) {
            if (!shouldRefresh(state, item))
                return

            state.items.push(item)
            addToSummary(state, item)
        },
        DELETE_SUCCESS (state, item) {
            if (!shouldRefresh(state, item))
                return

            state.items = state.items.filter(p => p.id !== item.id)
            subtractFromSummary(state, item)
        },
        UPDATE_SUCCESS (state, item) {
            if (!shouldRefresh(state, item))
                return

            const previous = state.items.find(p => p.id === item.id)

            state.items = state.items.map(p => p.id !== item.id ? p : { ...p, ...item })
            subtractFromSummary(state, previous)
            addToSummary(state, item)
        }
    },
    actions: {
        async LIST ({ state, commit }) {
            const result = await api.get({
                somenteEmAberto: state.filtro.somenteEmAberto,
                inicio: state.filtro.periodo.inicio,
                final: state.filtro.periodo.final,
            }, state.pageInfo.endCursor)

            commit('LIST_SUCCESS', result)
        },
        async ADD ({ commit }, item) {
            const result = await api.post(item)

            commit('ADD_SUCCESS', result)
            track("Comissão Salva", {
                module: "CRM",
                submodule: "Comissão",
                entry_id: result.id
            })

            return result
        },
        async DELETE ({ commit }, item) {
            await api.delete(item.id)

            commit('DELETE_SUCCESS', item)
            track("Comissão Excluída", {
                module: "CRM",
                submodule: "Comissão",
                entry_id: item.id
            });
        },
        async BAIXAR ({ commit }, item) {
            await api.update(item)

            commit('UPDATE_SUCCESS', item)
            track("Comissão Baixada", {
                module: "CRM",
                submodule: "Comissão",
                entry_id: item.id
            });
        }
    }
}

const shouldRefresh = (state, item) => {
    const periodo = state.filtro.periodo
    const start = periodo.inicio ? parseISO(periodo.inicio) : new Date(minTime)
    const end = periodo.final ? parseISO(periodo.final) : new Date(maxTime)

    return isWithinInterval(parseISO(item.vencimento), { start, end })
}

const addToSummary = (state, item) => refreshSummary(state, item, 1)
const subtractFromSummary = (state, item) => refreshSummary(state, item, -1)

const refreshSummary = (state, item, multiplicador) => {
    const valor = item.negocio.valor * item.percentual * multiplicador
    const valorTipo = item.pagamento ? 'valorRecebido' : 'valorPendente'

    state[valorTipo] += valor
}