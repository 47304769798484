export default {
  selecionadas: state => state.selecionadas,
  conversa: state => state.conversa,

  lista: state => state.lista ? ({
    ...state.lista,
    items: state.lista.items
      .filter(item => state.excluindo.indexOf(item.id) === -1)
      .map(item => ({ ...item,
        selecionada: state.selecionadas.indexOf(item.id) > -1
      }))
  }) : undefined

}
