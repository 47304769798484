import Vue from 'vue'

import ContatoInput from './ContatoInput'
import ContatoForm from './ContatoForm'
import ContatoDialog from './ContatoDialog'
import ContatoTelefoneLista from './ContatoTelefoneLista'

Vue.component('ContatoInput', ContatoInput)
Vue.component('ContatoDialog', ContatoDialog)
Vue.component('ContatoTelefoneLista', ContatoTelefoneLista)
Vue.component('ContatoForm', ContatoForm)
