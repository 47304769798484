<template>
    <v-text-field
        ref="input"
        v-model="maskedValue"
        v-mask="mask"
        v-bind="$attrs"
        v-on="listeners"
        :rules="rules">

        <template v-slot:append>
            <slot name="append" />
        </template>

    </v-text-field>
</template>

<script>
    import { mask, masker } from '@rj-pkgs/vue-the-mask'

    const unmaskText = (text) => {
        return text ? text.replace(/\D+/g, "") : text
    }

    export default {
        directives: { mask },
        props: {
            value: String,
            mask: [String, Array],
            rules: Array
        },
        computed: {
            maskedValue: {
                get () {
                    return masker(this.value, this.mask)
                },
                set (value) {
                    this.change(value)
                }
            },
            listeners () {
                return {
                    ...this.$listeners,
                    change: this.change,
                    input: this.change
                }
            }
        },
        methods: {
            change (value) {
                const v = value || this.value
                const unmasked = unmaskText(v)

                this.$emit('update:maskedValue', v)

                if (this.value !== unmasked) {
                    this.$emit('input', unmasked)
                    this.$emit('change', unmasked)
                }
            },
            focus () {
                this.$refs.input.focus();
            }
        }
    }
</script>