<template>
  <div ref="panel" class="panel" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'app-menu-slidedown',
    props: {
      value: Boolean,
      absolute: Boolean
    },
    computed: {
      classes () {
        return ({
          expanded: this.value,
          absolute: this.absolute
        })
      }
    }
  }

</script>

<style lang="scss" scoped>
  .panel {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    z-index: 1;
  }

  .expanded {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
    box-shadow: 0px 13px 20px -15px #000;
  }

  .absolute {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

</style>
