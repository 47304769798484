import axios from 'axios'
import { serialize } from '@/shared/services/serializeToFormData'

var CancelToken = axios.CancelToken
var cancel

const baseUri = 'api/cotacao'

export default class {
    async listar (params) {
        if (cancel) {
            cancel('Requisição sobreposta')
        }

        const result = await axios
            .get(baseUri, {
                params: {
                    ...params,
                    cache: false
                },
                cancelToken: new CancelToken(c => {
                    cancel = c
                })
            })

        return result.data
    }

    async recuperar (id, authorize = false) {
        const response = await axios.get(`${baseUri}/${id}`, {
            params: { cache: false, authorize }
        })

        return response.data;
    }

    salvar (cotacao) {
        const payload = { ...cotacao }

        // Clear payload
        payload.grupos.forEach(grupo => {
            Object.keys(grupo.cenarios).forEach(key => {
                delete grupo.cenarios[key].redeCredenciada
                delete grupo.cenarios[key].valores
            });
        });

        return axios.post(baseUri, payload)
    }

    excluir (id) {
        return axios.delete(baseUri, {
            params: {
                id
            }
        })
    }

    enviar (payload) {
        const data = serialize(payload)

        data.append('destinatarios', JSON.stringify(payload.destinatarios))

        return axios.post(`${baseUri}/${payload.id}/enviar`, data)
    }
}