const Lixeira = () =>
    import(/* webpackChunkName: "lixeira-chunk" */ './Lixeira')

export default {
    default: [
        {
            path: '/lixeira/:data',
            name: 'Lixeira',
            component: Lixeira,
            props: true
        }
    ]
};