import store from '@/store'
import router from '../router'

router.beforeEach(async (to, from, next) => {
    if (!to.matched.some(record => record.meta.requiresAuth)) {
        return next()
    }

    if (!store.getters.isAuthed) {
        console.log('Redirecionando acesso não autenticado...')
        return next(getRedirection(to))
    }

    if (to.meta.authorize) {
        const user = store.getters['conta/usuario'] ?? await store.dispatch('conta/USUARIO_RECUPERAR')
        const authorized = to.meta.authorize.includes(user.perfil)

        if (!authorized) {
            return next({
                path: '/conta/perfil',
                query: to.query
            })
        }
    }

    next()
})

function getRedirection (to) {
    const redirect = {
        path: '/conta/entrar'
    }

    if (to.fullPath !== '/') {
        redirect.query = {
            returnUrl: to.fullPath
        }
    }

    return redirect;
}
