import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import * as types from './types'
import filtroHelper from '../model/filtro'

const state = {
  filtro: filtroHelper.create(),
  filtroSlim: false,
  lista: undefined,
  selecionado: {}
}

export default {
  state,
  getters,
  mutations,
  actions,
  types
}
