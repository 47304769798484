import addMonths from 'date-fns/addMonths'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import parseISO from 'date-fns/parseISO'
import { formatDate } from '@/shared/formatters/formatDate'

export class Periodos extends Array {
    static fromToday () {
        const periodos = []
        const date = startOfMonth(new Date())

        for (let index = -6; index <= 6; index++) {
            periodos.push(addMonths(date, index).toISOString())
        }

        return Periodos.from(periodos)
    }

    mapValues (data) {
        return this.map(periodo => data.find(p => getDatePart(p.periodo) === getDatePart(periodo)))
    }
}

export function getDatePart (value) { return value.split('T')[0] }

export function getPeriodos () {
    return Periodos.fromToday().map(periodo => ({
        value: periodo,
        text: formatDate(periodo, 'MMMM yyyy'),
        inicio: periodo,
        final: endOfMonth(parseISO(periodo)).toISOString(),
        isCurrent: periodo === startOfMonth(new Date()).toISOString()
    }))
}

export function getPeriodo (value) {
    const periodos = getPeriodos()

    return periodos.find(p => getDatePart(p.value) === getDatePart(value))
}