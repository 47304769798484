import Vue from 'vue'
import './third-party'
import DrawerButton from './DrawerButton'
import PageContent from './PageContent'
import FlexTable from './FlexTable'
import TristateCheckbox from './TristateCheckbox'
import EmptyAlert from './EmptyAlert'
import MenuSlideDown from './MenuSlideDown'
import EmailInput from './email-input/EmailInput'
import Expander from './Expander'
import FormRow from './FormRow'
import CidadeAutocomplete from './app-cidade-autocomplete/CidadeAutocomplete'
import Endereco from './endereco/Endereco'
import AppSnackbar from './app-snackbar'
import AppMaskField from './app-mask-field'
import AppTelefone from './app-telefone'
import AppCreditCard from './AppCreditCard'
import AppFlexTableArea from './AppFlexTableArea'
import AppPasswordInput from './AppPasswordInput'
import ShareLayout2 from '@/pages/core/layout/ShareLayout2'
import AppLayoutError from '@/pages/core/layout/AppLayoutError'
import AppPictureInput from './AppPictureInput'
import AppDatePicker from './app-datepicker/AppDatePicker'
import AppConfirmButton from './app-confirm-button/AppConfirmButton'

Vue.component(DrawerButton.name, DrawerButton)
Vue.component(PageContent.name, PageContent)
Vue.component(FlexTable.name, FlexTable)
Vue.component(TristateCheckbox.name, TristateCheckbox)
Vue.component(EmptyAlert.name, EmptyAlert)
Vue.component(MenuSlideDown.name, MenuSlideDown)
Vue.component(EmailInput.name, EmailInput)
Vue.component(Expander.name, Expander)
Vue.component(FormRow.name, FormRow)
Vue.component('CidadeAutocomplete', CidadeAutocomplete)
Vue.component(Endereco.name, Endereco)
Vue.component(AppSnackbar.name, AppSnackbar)
Vue.component('AppMaskField', AppMaskField)
Vue.component('AppTelefone', AppTelefone)
Vue.component('AppCreditCard', AppCreditCard)
Vue.component(AppFlexTableArea.name, AppFlexTableArea)
Vue.component(AppPasswordInput.name, AppPasswordInput)
Vue.component('AppPictureInput', AppPictureInput)
Vue.component('AppDatePicker', AppDatePicker)
Vue.component('AppConfirmButton', AppConfirmButton)

Vue.component(ShareLayout2.name, ShareLayout2)
Vue.component(AppLayoutError.name, AppLayoutError)