<template>
    <v-autocomplete
        ref="select"
        :value="value"
        :loading="loading"
        :items="items"
        :search-input.sync="search"
        :filter="filter"
        item-text="nome"
        item-value="id"
        autocomplete="off"
        :multiple="multiple"
        :small-chips="multiple"
        :cache-items="multiple"
        :return-object="returnObject"
        :deletable-chips="multiple"
        no-data-text="Digite o nome do usuário..."
        @change="emit"
        @input="blur($event)"
        v-bind="$attrs">
        <template v-slot:prepend-inner v-if="showAvatar">
            <avatar :username="value.nome" :src="value.avatar" :size="22" v-if="value && value.nome" />
        </template>

        <template v-slot:item="data">
            <v-list-item-avatar>
                <avatar :username="data.item.nome" :src="data.item.avatar" :size="40" v-if="data.item.nome" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
    import { debounce } from '@/shared/functions'
    import UsuariosService from '../../pages/conta/components/usuario-page/services/usuarios-service'
    import caseInsensitiveFilter from '../shared/caseInsensitiveFilter'

    export default {
        props: {
            value: [Object, Array, String],
            showAvatar: { type: Boolean, default: true },
            multiple: Boolean,
            returnObject: Boolean
        },
        data () {
            return {
                loading: false,
                search: '',
                items: [],
                service: new UsuariosService(),
                filter: (item, queryText) => caseInsensitiveFilter(item, queryText, item.nome + ' - ' + item.email)
            }
        },
        methods: {
            async querySelections (searchValue) {
                if (!searchValue || searchValue == this.value?.nome)
                    return

                this.loading = true

                try {
                    const response = await this.service.listar(searchValue)
                    this.items = response.usuarios.sort((a, b) => a.nome.localeCompare(b.nome))
                }
                finally {
                    this.loading = false
                }
            },
            blur (value) {
                if (!value)
                    return

                if (!this.multiple)
                    this.$nextTick(() => this.$refs.select.blur())

                this.search = ''
            },
            emit (value) {
                this.$emit('input', value)
                this.$emit('change', value)
            }
        },
        watch: {
            value: {
                immediate: true,
                handler (value) {
                    if (isObject(value)) {
                        this.items = [value]
                        this.search = value.nome
                        return
                    }

                    if (Array.isArray(value) && isObject(value[0])) {
                        this.items = value
                        return
                    }

                    this.querySelections(this.value?.join(','))
                }
            },
            search: debounce(function (value) {
                this.querySelections(value)
            }, 250)
        }
    }

    const isObject = (item) => item?.id !== undefined
</script>