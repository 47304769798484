import axios from 'axios'
import * as types from './types'
import filtroHelper from '../model/filtro'
import produtoService from '@/shared/services/produto'

export default {
    [types.FILTRO_ATUALIZAR] (context, filtro) {
        context.commit(types.FILTRO_ATUALIZADO, filtro)
        context.dispatch(types.LISTAR)

        history.pushState({}, null, filtroHelper.toProdutoUrl(filtro))
    },
    async [types.LISTAR] (context) {
        context.commit(types.LISTADO_SUCESSO, undefined)
        const data = await produtoService.listarOperadoras(context.state.filtro)
        context.commit(types.LISTADO_SUCESSO, data)
    },
    [types.SELECIONAR_SHARE] (context, payload) {
        axios.get(`api/produto-link/${payload}`).then(response => {
            context.commit(types.FILTRO_ATUALIZADO, response.data.filtro)
            context.dispatch(types.SELECIONAR, response.data)
            context.commit('SET_PUBLIC_TOKEN', "Token " + response.data.token, { root: true })
        })
    },
    [types.SELECIONAR] (context, payload) {
        context.commit(types.SELECIONAR, {})

        return produtoService.get(payload.operadora, payload.produto, payload.filtro)
            .then(produto => {
                if (payload.corretor) {
                    produto.corretor = payload.corretor
                    produto.opcoes = payload.opcoes
                }

                context.commit(types.SELECIONAR, produto)

                if (!payload.lazy) {
                    context.dispatch(types.CARREGAR_VALORES, payload)
                    context.dispatch(types.CARREGAR_REDE, payload)
                    context.dispatch(types.CARREGAR_MANUAL, payload)
                }

                return produto
            })
    },
    async [types.CARREGAR_VALORES] (context, payload) {
        const response = await produtoService.getValores(payload.operadora, payload.produto, payload.filtro)

        context.commit(types.VALORES_RECUPERADOS, response.data)
    },
    async [types.CARREGAR_REDE] (context, payload) {
        const data = await produtoService.getRedeCredencida(payload.operadora, payload.produto, payload.filtro)

        context.commit(types.REDE_RECUPERADA, data)
    },
    async [types.CARREGAR_MANUAL] (context, payload) {
        const response = await produtoService.getManual(payload.operadora, payload.produto, payload.filtro, payload.exibirInformacoesRestritas)

        context.commit(types.MANUAL_RECUPERADO, response.data)
    },
    async [types.CARREGAR_ARQUIVOS] (context, payload) {
        const response = await produtoService.getArquivos(payload.operadora, payload.produto, payload.filtro)

        context.commit(types.ARQUIVOS_RECUPERADOS, response.data)
    },

    [types.USUARIO_RECUPERADO]: {
        root: true,
        handler: (context, payload) => context.commit(types.USUARIO_RECUPERADO, payload)
    }
}