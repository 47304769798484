import axios from 'axios'
import { useQuery } from '@/shared/services/graphQLClient'
import { Comissoes } from './query.gql'

export default {
    async get (input, after = undefined) {
        const result = await useQuery(Comissoes, { input, after })

        return result.comissao
    },

    async post (comissao) {
        const payload = { ...comissao, negocioId: comissao.negocio.id }
        const response = await axios.post(`api/comissao`, payload)

        return { ...payload, ...response.data }
    },

    async update (comissao) {
        const response = await axios.put(`api/comissao/${comissao.id}`, comissao)

        return response.data
    },

    async delete (comissaoId) {
        await axios.delete(`api/comissao/${comissaoId}`)
    },
}