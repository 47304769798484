<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
            <AppMaskField
                :value="formattedDate"
                mask="##/##/####"
                append-icon="mdi-menu-down"
                v-bind="$attrs"
                v-on="on"
                @click:append="menu=true"
                @update:maskedValue="onInputChange">
            </AppMaskField>
        </template>

        <v-card>
            <v-card-text class="pa-0">
                <v-col>
                    <v-row align="baseline" justify="center">
                        <v-date-picker v-model="date" no-title @input="onChange" locale="pt-br" :allowedDates="allowedDates" @click.stop />
                    </v-row>
                </v-col>
                <v-col v-if="isDateTime">
                    <v-row align="baseline" justify="center">
                        <v-label>Horário:</v-label>
                        <v-autocomplete dense :items="items" v-model="hour" @input="onChange" class="ml-2" />
                    </v-row>
                </v-col>
            </v-card-text>
            <v-card-actions v-if="isDateTime">
                <v-spacer />
                <v-btn text color="primary" @click="menu=false">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    import parse from 'date-fns/parse'
    import format from 'date-fns/format'
    import isValid from 'date-fns/isValid'

    export default {
        props: {
            value: [String, Date],
            allowedDates: Function,
            dateIcon: {
                type: String,
                default: 'mdi-calendar mdi-18px'
            },
            dateFormat: {
                type: String,
                default: 'eee, dd/MM/yyyy'
            },
            type: {
                type: String,
                default: 'datetime'
            }
        },
        data () {
            return {
                menu: false,
                items: [],
                date: undefined,
                hour: undefined
            }
        },
        computed: {
            isDateTime () { return this.type == 'datetime' },
            formattedDate () {
                if (!this.date)
                    return ''

                let result = this.$options.filters.date(this.date, this.dateFormat)

                if (this.isDateTime)
                    result += ' ' + this.hour

                return result
            }
        },
        methods: {
            onInputChange (e) {
                if (e.length === 0) {
                    this.date = undefined
                    this.onChange()
                    return
                }

                if (e.length < 10)
                    return

                const parsed = parse(e, 'dd/MM/yyyy', new Date())
                if (isValid(parsed)) {
                    this.date = format(parsed, 'yyyy-MM-dd')
                    this.onChange()
                }
                else {
                    this.date = undefined
                }
            },
            onChange () {
                const hours = this.hour || '00:00:00'
                const value = this.type == 'date' ? this.date : (this.date + "T" + hours)

                this.$emit('input', value)
                this.$emit('change', value)

                if (!this.isDateTime)
                    this.menu = false
            }
        },
        watch: {
            value: {
                immediate: true,
                handler (value) {
                    if (value) {
                        if (!isValid(new Date(value)))
                            return

                        this.date = new Date(value).toISOString().split('T')[0]
                        this.hour = this.$options.filters.date(new Date(value), "HH:mm")
                    }
                    else {
                        this.date = this.hour = undefined
                    }
                }
            }
        },
        mounted () {
            for (let h = 0; h < 24; h++) {
                for (let m = 0; m < 60; m += 15) {
                    this.items.push(h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0'))
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-autocomplete {
        max-width: 80px;
    }
</style>