<template>
    <div style="position: relative">
        <div id="table-scroll" class="table-scroll wrapper" ref="body" :style="[baseStyles]">
            <v-icon class="scroll-button float-left" @click="sideScroll('left')" v-if="showLeftArrow">mdi-menu-left</v-icon>
            <v-icon class="scroll-button float-right" @click="sideScroll('right')" v-if="showRightArrow">mdi-menu-right</v-icon>

            <table class="flex-table" ref="bodyOverflow" :class="'overflowing-' + overflow">
                <thead>
                    <tr ref="header">
                        <slot name="headers" :headers="headers">
                            <th v-for="header of headers" :key="header.key">{{header}}</th>
                        </slot>
                    </tr>
                </thead>
                <tbody>
                    <tr class="flex-row" v-for="item of items" :key="item.key">
                        <slot name="items" :item="item">{{item}}</slot>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import HeaderScrollMixin from './mixins/FlexTableHeaderScroll'

    export default {
        name: 'flextable',
        mixins: [HeaderScrollMixin],
        props: {
            fixedColumnWidth: {
                type: Number,
                default: 100
            },
            headers: {
                type: Array,
                default () {
                    return []
                }
            },
            items: {
                type: Array,
                default () {
                    return []
                }
            }
        },
        data () {
            return {
                baseStyles: {}
            }
        },
        mounted () {
            setTimeout(() => {
                this.baseStyles = {
                    '--width': this.fixedColumnWidth + 'px',
                    '--tableLayout': this.$isMobile ? 'fixed' : 'auto',
                    '--headerHeight': this.$refs.header ? this.$refs.header.clientHeight + 'px' : '0',
                    '--minWidth': (this.fixedColumnWidth + this.headers.length * 110) + 'px'
                }
            }, 0)
        }
    }
</script>

<style lang="scss" scoped>
    $scrolButtonHeight: 58px;

    .table-scroll {
        --width: 100px;
        width: 100%;
        z-index: 1;
        margin: auto;
        overflow: auto;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: var(--tableLayout);
    }

    th,
    td {
        background: #fff;
        vertical-align: bottom;
        font-weight: normal;
        padding: 8px 4px;
        min-width: 100px;
    }

    th:first-child {
        // width: var(--width);
        padding-left: 24px;
    }

    tbody {
        tr th,
        tr td {
            border-top: solid 1px #eeeeee;
        }

        tr:nth-child(odd) > th,
        tr:nth-child(odd) > td {
            background-color: #fbfbfb;
        }
    }

    .scroll-button {
        position: absolute;
        // top: calc(var(--headerHeight) - #{$scrolButtonHeight});
    }

    @media screen {
        table {
            min-width: var(--minWidth);

            th:first-child {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
            }

            &.overflowing-both,
            &.overflowing-left {
                tbody th:first-child {
                    box-shadow: -2px 20px 20px rgba(135, 135, 135, 0.5);
                }
            }
        }
    }

    @media print {
        table {
            table-layout: fixed !important;

            th,
            td {
                padding: 0 4px !important;
                -webkit-print-color-adjust: exact;
            }
        }

        .scroll-button {
            display: none !important;
        }
    }
</style>