export default {
    arquivos: state => state.selecionado && state.selecionado.arquivos ? state.selecionado.arquivos.map(p => ({
        ...p,
        ...getFileIcon(p.url)
    })) : undefined
}

function getFileExtension (filename) {
    let pattern = /\.[0-9a-z]+$/i
    let match = pattern.exec(filename)

    return match[0].replace('.', '')
}

function getFileIcon (filename) {
    let extension = getFileExtension(filename)

    switch (extension.toLowerCase()) {
        case 'pdf':
            return {
                icon: 'mdi-file-pdf-box',
                class: 'red--text darken-1',
                type: 'PDF'
            }
        case 'xls':
        case 'xlsx':
            return {
                icon: 'mdi-file-excel',
                class: 'green--text darken-1',
                type: 'Excel'
            }
        case 'doc':
        case 'docx':
            return {
                icon: 'mdi-file-word',
                class: 'blue--text darken-1',
                type: 'Word'
            }
        default:
            return {
                icon: 'mdi-file',
                class: '',
                type: ''
            }
    }
}
