import WebFontLoader from 'webfontloader'

WebFontLoader.load({
    google: {
        families: ['Roboto:300,400,500,700']
    },
    custom: {
        urls: ['//cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css']
    }
})
