import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,_vm._b({attrs:{"dense":"","hide-details":"","item-text":"nome","item-value":"id","menu-props":"offset-y","items":_vm.$user.tenants,"value":_vm.value},on:{"change":_vm.selecionar},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c(VListItemContent,[_vm._v(" "+_vm._s(item.nome)+" ")]),_c(VListItemAction,[(!item.ativo)?_c(VChip,{attrs:{"color":"red","text-color":"white","x-small":""}},[_vm._v("Inativa")]):_vm._e()],1)],1)]}}])},'v-select',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }