import axios from 'axios'
import queryString from 'query-string'

const auth0DefaultConnection = 'Username-Password-Authentication'

export default class AuthService {
    constructor () {
        this.login = this.login.bind(this)
        this.loginSocial = this.loginSocial.bind(this)
        this.baseUrl = process.env.VUE_APP_IDSVR_AUTHORIY
        this.options = {
            audience: process.env.VUE_APP_IDSVR_AUDIENCE,
            client_id: process.env.VUE_APP_IDSVR_CLIENTID,
            scope: 'openid profile offline_access email',
            grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
            redirect_uri: location.origin + '/conta/callback',
            response_type: 'token id_token',
            state: randomString(32),
            nonce: randomString(32)
        }
    }

    login (email, senha) {
        let url = this.baseUrl + 'oauth/token'
        let options = {
            ...this.options,
            realm: auth0DefaultConnection,
            username: email,
            password: senha
        }

        return axios.post(url, options).then(
            response => response.data,
            error => {
                if (!error.response)
                    throw Error('Falha no serviço de autenticação.')

                return Promise.reject(errorParse(error.response.data))
            }
        )
    }

    loginSocial (social) {
        let url = this.baseUrl + 'authorize?' + queryString.stringify({
            ...this.options,
            realm: auth0DefaultConnection,
            connection: social
        })

        window.location.replace(url)
    }

    redefinirSenha (email) {
        let url = this.baseUrl + 'dbconnections/change_password'
        let options = {
            ...this.options,
            connection: auth0DefaultConnection,
            email: email
        }

        return axios.post(url, options).then(response => response.data)
    }

    registrarConta (email, senha) {
        let url = this.baseUrl + 'dbconnections/signup'
        let options = {
            ...this.options,
            connection: auth0DefaultConnection,
            email: email,
            password: senha
        }

        return axios.post(url, options)
            .then(
                response => response.data,
                error => Promise.reject(errorParse(error.response.data))
            )
    }

    registrarContaComConvite (payload) {
        return axios.post('api/corretora/usuarios/registrar-com-convite', payload)
    }

    async redefinirApiKey () {
        const response = await axios.post('api/corretora/usuarios/api-key/redefinir', {})

        return response.data
    }

    async validarCupom (cupom) {
        const response = await axios.get(`api/assinatura/cupom/${cupom}/validar`)

        return response.data
    }
}

function errorParse (error) {
    if (error.message) {
        return new Error(error.message)
    }

    switch (error.code) {
        case 'too_many_attempts':
            return new Error('Número de tentivas excedido.<br>Confira as instruções de desbloqueio em seu e-mail')
        case 'user_exists':
            return new Error('E-mail já cadastrado')

        default:
            return new Error('Usuário ou senha inválidos')
    }
}

function randomString (length) {
    // eslint-disable-next-line
    var bytes = new Uint8Array(length);
    var result = []
    var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

    var cryptoObj = window.crypto || window.msCrypto
    if (!cryptoObj) {
        return null
    }

    var random = cryptoObj.getRandomValues(bytes)

    for (var a = 0; a < random.length; a++) {
        result.push(charset[random[a] % charset.length])
    }

    return result.join('')
}