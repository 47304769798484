<template>
  <div class="host">
    <div class="header">
      <slot name="check"></slot>
      <label @click="trigger" class="pointer text-no-wrap">{{title}}</label>
      <v-progress-circular indeterminate :width="3" :size="24" v-if="loading" class="mr-1"></v-progress-circular>
      <v-icon @click="trigger" v-else class="pointer">{{icon}}</v-icon>
    </div>
    <div v-if="isActive">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .host .header {
    display: flex;

    .v-input--checkbox {
      height: 0;
      padding: 0;
      flex: 0;
    }

    label {
      flex: 1 100%;
    }
  }

</style>

<script>
  import Toggleable from '@/components/mixins/toggleable'
  export default {
    name: 'app-expander',
    mixins: [Toggleable],
    props: {
      title: String
    },
    data () {
      return {
        loading: false
      }
    },
    computed: {
      icon () {
        return this.isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'
      }
    },
    methods: {
      trigger () {
        if (this.loading) {
          return
        }

        this.loading = !this.isActive
        setTimeout(() => {
          this.loading = false
          this.isActive = !this.isActive
          this.$emit('change', this.isActive)
        })
      }
    }
  }

</script>
