import * as types from './types'

export default {
    [types.EQUIPE_SALVAR_SUCCESS] (state, payload) {
        const item = state.lista.find(p => p.id == payload.id)
        const isNew = item === undefined
        const index = isNew ? 0 : state.lista.findIndex(p => p == item)
        const replace = isNew ? 0 : 1

        state.lista.splice(index, replace, payload)
    },

    [types.EQUIPE_REMOVER_SUCCESS] (state, payload) {
        state.lista = state.lista.filter(p => p.id !== payload)
    }
}