import uuidv4 from '@/shared/services/uuid'
import { useSubscription } from '@/shared/services/graphQLClient'
import { OnUsuarioLogado } from './usuarioLogado.gql'

export class AcessoSimultaneo {
    constructor () {
        this.subscription = undefined
        this.userId = undefined
    }

    get sessionId () {
        const KEY = '.SESSION'
        if (localStorage.getItem(KEY) == null)
            localStorage.setItem(KEY, uuidv4())

        return localStorage.getItem(KEY)
    }

    subscribe (user, callback) {
        if (!this.shouldSubscribe(user))
            return

        this.userId = user.id
        this.subscription = useSubscription(OnUsuarioLogado, {}, payload => {
            const { sessionId, usuarioId } = payload;

            if (usuarioId === this.userId && sessionId && sessionId !== this.sessionId) {
                console.log('Encerrando sessão simultânea...')
                callback()
            }
        })
    }

    unsubscribe () {
        this.subscription?.unsubscribe()
    }

    shouldSubscribe (user) {
        const ignoreDomains = ['pdc-staging.web.app', 'pdc.netlify.app']

        if (!user)
            return false

        if (process.env.NODE_ENV !== 'production')
            return false

        if (ignoreDomains.some(p => window.location.host.includes(p)))
            return false

        if (this.subscription)
            return this.userId !== user.id

        return true
    }
}
