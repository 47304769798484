const queryString = require('query-string')

export default (route) => {
  let path = route.path
  let query = Object.assign({}, {}, route.query)
  delete query.returnUrl
  let qs = queryString.stringify(query)

  if (queryString) {
    path += '?' + qs
  }

  return encodeURIComponent(path)
}
