import * as types from './types'
import Service from '../services/usuarios-service'

var service = new Service()

export default {
    [types.USUARIO_LISTAR] (context) {
        return service.listar().then(data => context.commit(types.USUARIO_LISTAR_SUCCESS, data))
    },

    [types.USUARIO_INCLUIR] (context, payload) {
        return service.incluir(payload).then(data => context.commit(types.USUARIO_INCLUIR_SUCCESS, data))
    },

    [types.USUARIO_ATUALIZAR] (context, payload) {
        return service.atualizar(payload).then(data => context.commit(types.USUARIO_INCLUIR_SUCCESS, data))
    },

    [types.USUARIO_REMOVER] (context, payload) {
        return service.remover(payload).then(() => context.commit(types.USUARIO_REMOVER_SUCCESS, payload))
    },

    [types.PERFIL_ALTERAR] (context, payload) {
        return service.alterarPerfil(payload)
    },

    [types.CONVITE_REENVIAR] (context, payload) {
        return service.reenviarConvite(payload)
    }
}