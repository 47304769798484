import store from '@/store'

export default class NegocioFiltro {
    constructor (value) {
        Object.assign(this, value)
    }

    busca;
    etiquetas;
    vendedores;
    produtos;
    periodo = { inicio: undefined, final: undefined };

    get descricao () {
        const descricao = []

        if (this.vendedores?.length)
            descricao.push(`vendedores="${this.vendedores.map(v => v.nome).join(',')}"`)

        if (this.periodo.descricao)
            descricao.push(this.periodo.descricao)

        if (this.produtos)
            descricao.push(`produtos="${this.produtos.map(p => p.nome).join(',')}"`)

        if (this.etiquetas?.length) {
            const map = store.getters['crm/negocios/etiquetas']
            descricao.push(`etiquetas="${this.etiquetas.map(p => map[p]?.nome)}"`)
        }

        if (this.etapas?.length) {
            const map = store.getters['crm/negocios/etapas']
            descricao.push(`etapas="${this.etapas.map(p => map.find(q => q.id === p)?.nome)}"`)
        }

        return descricao.join(', ')
    }

    get isEmpty () {
        return !this.busca && !this.descricao
    }

    get vendedoresIds () {
        return this.vendedores ? this.vendedores?.map(p => p.id ? p.id : p) : []
    }

    get produtosIds () {
        return this.produtos ? this.produtos.map(p => p.id ? p.id : p) : []
    }

    toQueryParams () {
        return {
            busca: this.busca,
            usuarios: this.vendedoresIds,
            contatoId: this.contatoId,
            etapas: this.etapas,
            etiquetas: this.etiquetas,
            produtos: this.produtosIds,
            skip: this.skip,
            take: this.take,
            periodoInicial: this.periodo.inicio,
            periodoFinal: this.periodo.final
        }
    }
}