import store from '@/store'

import '@/pages/produto/components'

const ProdutoLista = () =>
    import(/* webpackChunkName: "produto-chunk" */ '@/pages/produto/ProdutoLista')
const Produto = () =>
    import(/* webpackChunkName: "produto-chunk" */ '@/pages/produto/Produto')
const ProdutoShare = () =>
    import(/* webpackChunkName: "produto-chunk" */ '@/pages/produto/ProdutoShare')
const ProdutoPrint = () =>
    import(/* webpackChunkName: "produto-chunk" */ '@/pages/produto/ProdutoPrint')

const registerModule = (to, from, next) => {
    const moduleName = 'produto'
    if (store._modules.root._children[moduleName]) {
        return next()
    }

    import(/* webpackChunkName: "produto-chunk" */ '@/pages/produto/store').then(m => {
        store.registerModule(moduleName, m.default)
        next()
    })
}

export default {
    default: [

        {
            path: 'produtos/:modalidade?',
            name: 'Operadoras',
            beforeEnter: registerModule,
            component: ProdutoLista
        },
        {
            path: 'produtos/:modalidade/:operadoraId/:produtoId',
            name: 'Produto',
            beforeEnter: registerModule,
            component: Produto,
            props: true
        }
    ],
    root: [
        {
            path: '/produtos/share/:id/',
            name: 'ProdutoShare',
            component: ProdutoShare,
            beforeEnter: registerModule,
            props: true
        },
        {
            path: '/produtos/:modalidade/:operadoraId/:produtoId/print',
            name: 'ProdutoPrint',
            component: ProdutoPrint,
            beforeEnter: registerModule,
            props: true
        }
    ]
}
