import axios from 'axios'

export default {
    async descobrirProvedor (email) {
        const response = await axios.get(`api/emails/${email.split('@')[1]}/descobrir-provedor`)
        const provedor = response.data

        if (provedor.success === false)
            provedor.authenticationType = 'Password'
        else if (provedor.imapHost.endsWith('office365.com'))
            provedor.authenticationType = 'Office365'
        else if (provedor.imapHost.endsWith('gmail.com'))
            provedor.authenticationType = 'Gmail'

        return provedor
    },

    async buscar (email) {
        const response = await axios.get(`api/emails/${email}`, { params: { cache: false } })

        return response.data
    },

    async conectar (configuracao) {
        const response = await axios.put(`api/emails/${configuracao.email}`, configuracao)

        return response.data
    },

    async desconectar (email) {
        const response = await axios.delete(`api/emails/${email}`)

        return response.data
    }
}