import axios from 'axios'
import { useQuery } from '@/shared/services/graphQLClient'
import { Usuarios } from './usuarios.gql'

const BASEURL = 'api/corretora/usuarios/'

export default class {
    async listar (search) {
        const response = await useQuery(Usuarios, { search })

        return {
            equipes: response.assinatura.equipes,
            plano: response.assinatura.plano,
            usuarios: response.assinatura.usuarios.nodes
        }
    }

    async incluir (usuario) {
        const payload = formatPayload(usuario)
        const response = await axios.post(BASEURL, payload)

        return formatResponse(usuario, response)
    }

    async atualizar (usuario) {
        const url = BASEURL + usuario.id
        const payload = formatPayload(usuario)
        const response = await axios.put(url, payload)

        return formatResponse(usuario, response)
    }

    remover (usuarioId) {
        return axios.delete(BASEURL + usuarioId)
    }

    reenviarConvite (usuarioId) {
        return axios.post(BASEURL + 'reenviar-convite', {
            usuarioId
        })
    }
}

function formatPayload (usuario) {
    return { ...usuario, usuarioId: usuario.id, equipeId: usuario.equipe?.id }
}

function formatResponse (usuario, response) {
    return {
        ...usuario,
        ...response.data,
        id: response.data.id ?? response.data.usuarioId,
        perfil: response.data.perfil.toUpperCase()
    }
}