<template>
    <v-autocomplete ref="select"
        :loading="loading" cache-items :items="cidades"
        :search-input.sync="search" v-model="cidade" no-data-text="Selecione uma cidade..." :filter="caseInsensitiveFilter"
        @input="blur($event)" v-bind="$attrs" />
</template>

<script>
    import caseInsensitiveFilter from '../shared/caseInsensitiveFilter'
    import { debounce } from '@/shared/functions'
    import CidadeService from '@/shared/services/cidade'

    export default {
        props: {
            value: String
        },
        data () {
            return {
                cidades: [this.value],
                loading: false,
                search: this.value,
                caseInsensitiveFilter: caseInsensitiveFilter,
                cidadeService: new CidadeService()
            }
        },
        computed: {
            cidade: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value || '')
                }
            }
        },
        methods: {
            querySelections (v) {
                this.loading = true
                this.cidadeService.get(v)
                    .then(data => {
                        this.cidades = data.map(p => p.id)
                        this.loading = false
                    })
            },
            blur (value) {
                if (value) {
                    this.$nextTick(() => this.$refs.select.blur())
                }
            }
        },
        watch: {
            value (value) {
                if (this.cidades.indexOf(value) === -1 && value) {
                    this.cidades.push(value)
                }
            },
            search: debounce(function (value) {
                value && this.querySelections(value)
            }, 250)
        }
    }
</script>