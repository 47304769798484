import Vue from 'vue'

Vue.mixin({
    methods: {
        $setQueryString (query) {
            const obj = Object.assign({}, this.$route.query);

            Object.keys(query).forEach(key => {
                let value = query[key];
                if (value) {
                    obj[key] = value
                } else {
                    delete obj[key]
                }
            })

            this.$router.replace({
                query: obj
            })
        }
    }
})