import Vue from 'vue'
import Router from 'vue-router'

import AppLayout from '@/pages/core/layout/AppLayout'
import coreRoutes from '@/pages/core/routes'
import authRoutes from '@/pages/auth/routes'
import contaRoutes from '@/pages/conta/routes'
import produtoRoutes from '@/pages/produto/routes'
import cotacaoRoutes from '@/pages/cotacao/routes'
import comissaoRoutes from '@/pages/comissao/routes'
import crmRoutes from '@/pages/crm/routes'
import suporteRoutes from '@/pages/suporte/routes'
import homeRoutes from '@/pages/home/routes'
import lixeira from '@/pages/lixeira/routes'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [

        {
            path: '',
            component: AppLayout,
            meta: {
                requiresAuth: true
            },
            children: [
                ...homeRoutes,
                ...contaRoutes,
                ...suporteRoutes,
                ...comissaoRoutes,
                ...crmRoutes.default,
                ...cotacaoRoutes.default,
                ...produtoRoutes.default,
                ...lixeira.default,
            ]
        },
        ...authRoutes,
        ...coreRoutes,
        ...cotacaoRoutes.root,
        ...produtoRoutes.root,
        ...crmRoutes.root,
        {
            path: '**',
            redirect: 'erro/status/404'
        }
    ]
})