<template>
    <div>
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        name: 'app-flex-table-area',
        mounted () {
            setTimeout(() => {
                let alvos = []
                let children = this.$slots.default.filter(q => q.tag)

                let callback = (distance, source) => {
                    if (alvos.length) {
                        // Não reexecutar sincronismo enquanto houver alvos
                        alvos.splice(alvos.indexOf(source), 1)
                        return
                    }

                    let sync = () => {
                        alvos = children.filter(p => p !== source)
                        alvos.forEach(alvo => {
                            alvo.elm.scrollLeft = source.elm.scrollLeft
                        })
                    }

                    sync()

                    // Sincronismo final após rolar
                    setTimeout(sync, 150)
                }

                children.forEach(elemento => {
                    elemento.elm.onscroll = scroll.bind(this, callback, elemento)
                })
            }, 0)
        }
    }

    var ticking = false

    function scroll (callback, source) {
        let lastPosition = window.scrollY
        if (!ticking) {
            window.requestAnimationFrame(function () {
                callback(lastPosition, source)
                ticking = false
            })
        }
        ticking = true
    }

</script>
