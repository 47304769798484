<template>
    <div>
        <portal-target name="alerts" />
        <v-app :style="[baseStyles]" v-if="$user">
            <app-menu></app-menu>
            <portal-target name="root" />

            <!-- <keep-alive> :key="$route.fullPath" -->
            <router-view></router-view>
            <!-- </keep-alive> -->

        </v-app>
    </div>
</template>

<script>
    import AppMenu from './app-menu'
    export default {
        computed: {
            baseStyles () {
                return {
                    '--applicationTop': this.$vuetify.application.top + 'px',
                    '--applicationLeft': this.$vuetify.application.left + 'px',
                    '--scrollWidth': this.$isMobile ? '0px' : '18px'
                }
            }
        },
        components: {
            AppMenu
        }
    }
</script>