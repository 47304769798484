<template>
    <div>
        <v-row class="no-vertical-gutters">
            <v-col sm="8" cols="12">
                <AppMaskField label="Número do cartão de crédito"
                    v-model="value.number"
                    mask="#### #### #### ####"
                    :class="bandeira"
                    :rules="rules.number" />
            </v-col>
            <v-col sm="2" cols="12">
                <AppMaskField label="Validade" placeholder="MM / AA" v-model="expiry" @input="value.expiry = vencimento" mask="##/##" :rules="rules.expiration" />
            </v-col>
            <v-col sm="2" cols="12">
                <AppMaskField label="CVC" v-model="value.cvc" mask="####" :rules="rules.cvc" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import rules from '@/shared/rules'
    import {
        isValid,
        isExpirationDateValid,
        isSecurityCodeValid,
        getCreditCardNameByNumber
    } from 'creditcard.js';

    export default {
        props: {
            value: {
                type: Object,
                default () {
                    return {
                        number: '',
                        expiry: ''
                    }
                }
            }
        },
        data () {
            return {
                expiry: '',
                rules: {
                    default: [rules.required()],
                    number: [v => isValid(v) || "Cartão inválido"],
                    cvc: [v => isSecurityCodeValid(this.value.number, v) || "Verificação inválida"],
                    expiration: [() => {
                        if (!this.vencimento)
                            return "Data inválida"

                        const month = this.vencimento.getMonth() + 1
                        const year = this.vencimento.getFullYear()

                        return isExpirationDateValid(month.toString(), year.toString()) || "Data inválida"
                    }]
                }
            }
        },
        computed: {
            vencimento () {
                const month = +this.expiry.substring(0, 2) - 1
                const year = '20' + this.expiry.substring(2)

                if (month < 0 || month > 11)
                    return undefined

                return new Date(year, month, 1)
            },
            bandeira () {
                const valid = this.value.number && isValid(this.value.number)

                if (!valid)
                    return ''

                const bandeira = getCreditCardNameByNumber(this.value.number).toLowerCase()

                return "cc-num " + bandeira
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cc-num {
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: 18px;

        &.mastercard {
            background-image: url("https://d2fz1tzabfzgo4.cloudfront.net/demos/cards/mastercard.png");
        }

        &.visa {
            background-image: url("https://d2fz1tzabfzgo4.cloudfront.net/demos/cards/visa.png");
        }

        &.amex {
            background-image: url("https://d2fz1tzabfzgo4.cloudfront.net/demos/cards/amex.png");
        }

        &.diners {
            background-image: url("https://d2fz1tzabfzgo4.cloudfront.net/demos/cards/diners.png");
        }

        &.discover {
            background-image: url("https://d2fz1tzabfzgo4.cloudfront.net/demos/cards/discover.png");
        }
    }
</style>