import axios from 'axios'
import produtoService from '@/shared/services/produto'
import Modalidade from '@/pages/produto/model/modalidade'
import faixas from '@/pages/produto/model/faixaEtaria'
import { List } from '@/shared/List';

export default async function mapearCotacaoAsync (payload) {
    const cotacao = createCotacao(payload)
    const responses = await buscarValores(cotacao)

    cotacao.cenarios = await mapearCenarios(cotacao, responses)
    cotacao.grupos = await mapearGrupos(cotacao, responses)

    return cotacao
}

const createCotacao = (payload) => {
    const filtro = {
        ...payload.filtro,
        ramo: Modalidade.findByKey(payload.filtro.modalidade).ramo
    }

    const opcoes = {
        rede: true,
        valores: true,
        manual: true,
        manualSelecao: [],
        ...payload.opcoes
    }

    return {
        ...payload,
        id: payload.id != 'nova' ? payload.id : undefined,
        contato: payload.contato || undefined,
        cenarios: payload.cenarios || [],
        grupos: payload.grupos ? payload.grupos.map(createGrupo) : [createGrupo({})],
        filtro,
        opcoes
    }
}

const createGrupo = (dataRaw) => {
    const vidas = dataRaw.vidas || []

    const getVidas = () => {
        const result = faixas.map(faixa => ({ [faixa]: vidas[faixa] || 0 }))

        return Object.assign({}, ...result)
    }

    return {
        nome: dataRaw.nome || 'Geral',
        vidas: getVidas(),
        regioes: dataRaw.regioes || [],
        cenarios: dataRaw.cenarios || {}
    }
}

async function buscarValores (cotacao) {
    let requests = cotacao.grupos.map(grupo => Object.entries(grupo.cenarios).map(p => ({ key: p[0], ...p[1] }))).reduce((p, q) => p.concat(q))
    let vidas = cotacao.grupos.map(grupo => Object.values(grupo.vidas).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)

    await axios.all(
        List.from(requests)
            .groupBy(p => p.operadora + ':' + p.modalidade)
            .map(async group => {
                const cenario = group.items[0]
                const filtro = {
                    ...cotacao.filtro,
                    modalidade: cenario.modalidade,
                    entidade: cenario.entidade
                }

                // Busca de cenários com grupo reduzido: cache otimizado.
                await produtoService.recuperarCenariosDaOperadora(cenario.operadora, filtro, vidas)
            }))


    return axios.all(
        List.from(requests)
            .groupBy(p => p.administradora + ':' + p.produto + ':' + p.modalidade + ':' + p.entidade)
            .map(async group => {
                const cenario = group.items[0]
                const filtro = {
                    ...cotacao.filtro,
                    modalidade: cenario.modalidade,
                    entidade: cenario.entidade
                }
                const valores = await produtoService.getValores(cenario.administradora, cenario.produto, filtro)
                const rede = await produtoService.getRedeCredencida(cenario.administradora, cenario.produto, filtro)
                const opcoes = await produtoService.recuperarCenariosDaOperadora(cenario.operadora, filtro, vidas)

                return {
                    groupKey: group.key,
                    cenarioKeys: group.items.map(p => p.key),
                    valores,
                    rede,
                    opcoes
                }
            }))
}

async function mapearCenarios (cotacao, valores) {
    let keys = cotacao.cenarios.map(p => p.key).filter(key => cotacao.grupos.some(q => Object.keys(q.cenarios).includes(key)))
    let responses = valores || await buscarValores(cotacao)

    return keys.map(key => ({ key, ...responses.find(p => p.cenarioKeys.includes(key)).opcoes })).reduce((a, b) => a.concat(b), []).filter(p => p.operadora)
}

async function mapearGrupos (cotacao, valores) {
    let keys = cotacao.cenarios.map(p => p.key).filter(key => cotacao.grupos.some(q => Object.keys(q.cenarios).includes(key)))
    let responses = valores || await buscarValores(cotacao)

    cotacao.grupos.forEach(grupo => {
        const cenarios = keys.map(key => ({
            [key]: popularCenario(key, grupo, responses)
        }));

        grupo.cenarios = cenarios && cenarios.length ? Object.assign(...cenarios) : {}
    });

    return cotacao.grupos;
}

const popularCenario = (key, grupo, responses) => {
    let cenario = grupo.cenarios[key]
    if (!cenario)
        return;

    cenario.id = `${cenario.produto}-${cenario.tabela}-${cenario.plano}`;
    if (cenario.entidade)
        cenario.id += `-${cenario.entidade}`;

    let responseKey = cenario.administradora + ':' + cenario.produto + ':' + cenario.modalidade + ':' + cenario.entidade
    let response = responses.find(p => p.groupKey == responseKey)
    if (!response.valores.data)
        return cenario;

    let tabela = response.valores.data.find(p => p.id === cenario.tabela)
    if (!tabela)
        return cenario;

    let plano = tabela.planos.find(p => p.id === cenario.plano)
    if (!plano)
        return cenario;

    cenario.valores = Object.keys(plano.valores).map(q => ({
        de: parseInt(q.split('-')[0]),
        ate: parseInt(q.split('-')[1]),
        valor: plano.valores[q]
    }))

    cenario.redeCredenciada = {
        legenda: response.rede.legenda,
        credenciados: response.rede.credenciados,
        planos: response.rede.planos.filter(p => p.id === cenario.plano)
    }

    return cenario
}