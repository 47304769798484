import Vue from 'vue'
import ProdutoFiltro from './filtro/ProdutoFiltro'

Vue.component(ProdutoFiltro.name, ProdutoFiltro)
Vue.component('ProdutoSearchLayout', () =>
  import(/* webpackChunkName: "produto-chunk" */ './ProdutoSearchLayout'))
Vue.component('ProdutoValorLista', () =>
  import(/* webpackChunkName: "produto-chunk" */ './ProdutoValorLista'))
Vue.component('ProdutoRedeCredenciada', () =>
  import(/* webpackChunkName: "produto-chunk" */ './rede-credenciada/ProdutoRedeCredenciada'))
Vue.component('ProdutoManual', () =>
  import(/* webpackChunkName: "produto-chunk" */ './ProdutoManual'))
