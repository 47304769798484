const Login = () =>
    import( /* webpackChunkName: "account-chunk" */ './components/Login/Login')
const LoginCallback = () =>
    import( /* webpackChunkName: "account-chunk" */ './components/Login/LoginCallback')
const Cadastro = () =>
    import( /* webpackChunkName: "account-chunk" */ './components/Cadastro/Cadastro')
const Corretora = () =>
    import( /* webpackChunkName: "account-chunk" */ './components/Corretora/Corretora')
const Recuperacao = () =>
    import( /* webpackChunkName: "account-chunk" */ './components/Recuperacao')

export default [
    {
        path: '/conta/entrar',
        component: Login
    },
    {
        path: '/conta/cadastrar',
        component: Cadastro
    },
    {
        path: '/conta/callback',
        component: LoginCallback
    },
    {
        path: '/conta/finalizar',
        component: Corretora
    },
    {
        path: '/conta/recuperacao',
        component: Recuperacao
    }
]