import Vue from 'vue'
import Service from './service'
import compareAsc from 'date-fns/compareAsc'
import { relativeDateFormat } from '@/shared/formatters/formatDate'
import { List } from '@/shared/List'

var service = new Service()

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {
        items: state => state.items.groupBy(p => Vue.options.filters.date(p.venceEm, 'MMMM \'de\' yyyy')),
        dialog: state => state.dialog,
        agenda: state => {
            return List.from(state.items)
                .map(p => {
                    const vencimento = new Date(p.venceEm)
                    return {
                        ...p,
                        link: p.contato?.id ? `crm/contatos/${p.contato.id}?tab=tarefa` : '',
                        venceEm: vencimento,
                        atrasada: vencimento < new Date()
                    }
                })
                .sort((a, b) => compareAsc(b.venceEm, a.venceEm))
                .groupBy(p => relativeDateFormat(p.venceEm))
                .map(g => [{ header: g.key }, ...g.items.sortBy(a => a.venceEm)])
                .reduce((p, q) => p.concat(q), [])
        }
    },
    mutations: {
        reset (state) {
            state.items = []
            state.pageInfo = {}
        },
        listar (state, payload) {
            if (state.pageInfo.endCursor !== payload.pageInfo.endCursor) {
                state.items = List.from(state.items.concat(payload.nodes)).distinctBy(p => p.id)
                state.pageInfo = payload.pageInfo
            }
        },
        append (state, payload) {
            state.items.splice(0, 0, payload)
            state.items = List.from(state.items).distinctBy(p => p.id)
        },
        alternar (state, payload) {
            state.items = state.items.map(p => p.id == payload.tarefaId ? ({ ...p, concluido: payload.concluido }) : p)
        },
        excluir (state, payload) {
            let index = state.items.findIndex(p => p.id === payload)
            state.items.splice(index, 1)
        }
    },
    actions: {
        async listar ({ state, commit }, payload) {
            const tarefas = await service.listar({
                input: payload,
                after: state.pageInfo?.endCursor
            })

            commit('listar', tarefas)

            return tarefas
        },
        registrar (context, payload) {
            const tarefa = {
                ...payload,
                tarefaId: payload.id,
                responsavelId: payload.responsavel.id,
                contatoId: payload.contato?.id
            }

            return service
                .registrar(tarefa)
                .then(data => context.commit('append', data))
        },
        alternar ({ commit, dispatch }, payload) {
            return service
                .alternar(payload)
                .then(() => commit('alternar', payload))
                .catch(error => dispatch('NOTIFY', error, {
                    root: true
                }))
        },
        atualizar (context, payload) {
            let tarefa = {
                ...payload,
                tarefaId: payload.id,
                responsavelId: payload.responsavel.id
            }

            return service
                .atualizar(tarefa)
                .catch(error => context.dispatch('NOTIFY', error, {
                    root: true
                }))
        },
        excluir (context, payload) {
            return service
                .excluir(payload)
                .then(() => context.commit('excluir', payload))
                .catch(error => context.dispatch('NOTIFY', error, {
                    root: true
                }))
        }
    }
}