import Vue from 'vue'
import * as types from './types'

export default {
    [types.RECUPERAR_SUCESSO] (state, payload) {
        state.manuais = []
        state.selecionada = payload
        state.isDirty = false
    },

    [types.SET_COTACAO] (state, payload) {
        state.isDirty = true
        state.selecionada = { ...state.selecionada, ...payload }
    },

    [types.OPCOES_ATUALIZADAS] (state, payload) {
        state.isDirty = true
        state.selecionada.opcoes = Object.assign({}, payload)
    },

    [types.CONTATO_VINCULAR] (state, payload) {
        let contato = payload
        if (typeof payload === 'string') {
            contato = {
                nome: payload
            }
        }

        state.isDirty = true
        state.selecionada.contato = contato
    },

    [types.GRUPO_ATUALIZAR] (state, payload) {
        state.isDirty = true
        state.selecionada.grupos[payload.index].nome = payload.nome
        state.selecionada.grupos[payload.index].vidas = payload.vidas
    },

    [types.GRUPO_INCLUIR] (state, payload) {
        state.isDirty = true
        state.selecionada.grupos.push(payload)
    },

    [types.GRUPO_REMOVER] (state, payload) {
        state.isDirty = true
        state.selecionada.grupos = state.selecionada.grupos.filter(p => p.nome !== payload.nome)
    },

    [types.FILTRO_ATUALIZADO] (state, payload) {
        state.isDirty = true
        state.selecionada.filtro = payload
    },

    [types.CENARIO_ADICIONAR] (state, payload) {
        state.isDirty = true
        state.selecionada.cenarios.push(payload.cenario)
        state.selecionada.grupos.forEach(grupo => Vue.set(grupo.cenarios, payload.cenario.key, payload.opcao))
    },
    [types.CENARIO_SELECIONADO] (state, payload) {
        state.isDirty = true
        const atual = state.selecionada.grupos[payload.grupoIndex].cenarios[payload.cenarioKey]

        if (atual) {
            if (!payload.opcao || !payload.opcao.id) {
                payload.opcao = {
                    operadora: atual.operadora,
                    modalidade: atual.modalidade,
                }
            }

            payload.opcao.lastId = atual.id || atual.lastId
        }

        Vue.set(state.selecionada.grupos[payload.grupoIndex].cenarios, payload.cenarioKey, payload.opcao)
    },
    [types.CENARIOS_ATUALIZADOS] (state, payload) {
        const index = state.selecionada.cenarios.findIndex(p => p.key === payload.key)
        const cenario = state.selecionada.cenarios[index]

        state.selecionada.cenarios.splice(index, 1, {
            ...cenario,
            avisos: payload.opcoes.length ? payload.avisos : ['Não atendido'],
            opcoes: payload.opcoes || []
        })
    },
    [types.CENARIO_REMOVER] (state, payload) {
        state.isDirty = true
        let cenario = state.selecionada.cenarios.find(p => p.key === payload.cenarioKey)
        let index = state.selecionada.cenarios.indexOf(cenario)

        state.selecionada.cenarios.splice(index, 1)
        state.selecionada.grupos = state.selecionada.grupos.map(grupo => ({
            ...grupo,
            cenarios: filtrarCenarios(state.selecionada, grupo)
        }))
    },

    ['CENARIO_DESTACAR'] (state, payload) {
        state.selecionada = { ...state.selecionada, destaque: payload }
    },

    [types.ALTERAR_FAIXA_ETARIA] (state, payload) {
        state.isDirty = true
        let vidas = state.selecionada.grupos[payload.grupoIndex].vidas

        state.selecionada.grupos[payload.grupoIndex].vidas = { ...vidas, [payload.faixa]: payload.qtd }
    },

    [types.SALVAR] (state) {
        state.salvando = true
    },
    [types.SALVAR_SUCESSO] (state, payload) {
        state.selecionada.id = payload.id
        state.salvando = false
        state.isDirty = false
    },
    [types.SALVAR_FALHA] (state) {
        state.salvando = false
    },
    [types.REDE_TOGGLE] (state, payload) {
        state.isDirty = true
        state.selecionada.grupos.forEach((grupo, index) => {
            if (!payload.grupoIndex || payload.grupoIndex === index) {
                grupo.regioes = payload.regioes
            }
        })
    },
    [types.MANUAL_RECUPERAR_SUCESSO] (state, payload) {
        state.manuais.push(payload)
    }
}

function filtrarCenarios (cotacao, grupo) {
    const cenariosValidos = Object.entries(grupo.cenarios).filter(p => cotacao.cenarios.some(q => q.key == p[0]))

    if (!cenariosValidos.length)
        return {}

    return Object.assign(...cenariosValidos.map(p => ({
        [p[0]]: p[1]
    })))
}