export default class Board {
    constructor (board) {
        this.board = board
    }

    getColumn (etapa) {
        return this.board.find(p => p.etapa.id === etapa)
    }

    getNegocio (negocioId) {
        const negocios = this.board.map(p => p.negocios).reduce((p, q) => p.concat(q), [])
        const negocio = negocios.find(p => p.id === negocioId)

        return { ...negocio }
    }

    setNegocio (payload, insertAt = undefined) {
        const actual = this.getNegocio(payload.id)

        const negocio = { ...actual, ...payload }
        const etapa = this.board.find(p => p.etapa.id == negocio.etapa)
        const index = insertAt || Math.max(0, etapa.negocios.findIndex(p => p.id === payload.id))

        this.removeNegocio(payload.id)

        etapa.negocios.splice(index, 0, negocio)
        etapa.valor += negocio.valor
        etapa.quantidade++
    }

    removeNegocios (negociosIds) {
        for (const id of negociosIds) {
            this.removeNegocio(id)
        }
    }

    removeNegocio (negocioId) {
        const negocio = this.getNegocio(negocioId)
        const etapa = this.board.find(p => p.etapa.id === negocio.etapa)

        if (!etapa)
            return

        etapa.negocios = etapa.negocios.filter(p => p.id !== negocio.id)
        etapa.valor -= negocio.valor
        etapa.quantidade--
    }
}