<template>
    <v-app>
        <v-main>
            <v-container class="fill-height">
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="8">
                        <empty-alert icon="">
                            <slot name="title">
                                <h1 class="mb-5">{{title}}</h1>
                            </slot>
                            <slot></slot>
                            <slot name="actions"></slot>
                        </empty-alert>
                    </v-col>
                    <v-col sm="4" class="hidden-xs-only" v-if="image">
                        <img :src="image" />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<style lang="scss" scoped>
    h1 {
        font-size: 42px;
    }

    #app ::v-deep p {
        font-size: 18px;
        max-width: 510px;
        margin-left: auto;
        margin-right: auto;
    }
</style>

<script>
    export default {
        name: 'app-layout-error',
        props: {
            title: String,
            image: String
        }
    }
</script>