import axios from 'axios'

export default class {
    recuperar () {
        return axios.get('api/assinatura')
    }

    alterarPlano ({ planoId, assentos }) {
        return axios.put('api/assinatura/plano', {
            planoId,
            assentos
        })
    }

    alterarMetodoPagamento (data) {
        return axios.put('api/assinatura/metodo-pagamento', data)
    }

    alterarVencimento (vencimento) {
        return axios.put('api/assinatura/vencimento', {
            vencimento
        })
    }

    recuperarOperadoras () {
        return axios.get('api/assinatura/operadoras')
    }

    selecionarOperadora (payload) {
        return axios.put('api/assinatura/operadoras', payload)
    }

    ativar (payload) {
        return axios.put('api/assinatura/ativar', payload)
    }

    reativar () {
        return axios.put('api/assinatura/reativar')
    }

    encerrar (payload) {
        return axios.delete('api/assinatura', { data: payload })
    }
}