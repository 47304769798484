import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  filtro: {
    ramo: 'Saúde',
    modalidade: 'SaudePF',
    cidade: undefined
  },
  planos: undefined,
  credenciados: [],
  coordenadas: undefined,
  localizandoCredenciados: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
