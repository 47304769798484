import { render, staticRenderFns } from "./AppFlexTableArea.vue?vue&type=template&id=f7d42d00&scoped=true&"
import script from "./AppFlexTableArea.vue?vue&type=script&lang=js&"
export * from "./AppFlexTableArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7d42d00",
  null
  
)

export default component.exports