import axios from 'axios'

const BASE_URL = 'api/corretoras/'

export default class CorretoraService {
    registrar (payload) {
        return axios.post(BASE_URL, payload)
    }

    async buscarPorDominio (email) {
        var response = await axios.get(`${BASE_URL}buscar-dominio-existente?email=${email}`, {
            params: { cache: false }
        })

        return response.data
    }
}