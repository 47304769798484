import store from '@/store'
import router from '../router'

const pathsAllowed = ['/erro', '/conta']

router.beforeEach(async (to, from, next) => {
    if (!to.matched.some(record => record.meta.requiresAuth)) {
        return next()
    }

    const usuario = store.getters['conta/usuario'] ?? await store.dispatch('conta/USUARIO_RECUPERAR')

    if (usuario?.assinatura && !pathsAllowed.some(path => to.path.startsWith(path))) {
        if (usuario.assinatura.expirada) {
            return next('/erro/conta-expirada')
        }
        if (usuario.assinatura.desativada) {
            return next('/conta/assinatura?status=desativada')
        }
        if (!usuario.assinatura.trial && !usuario.cadastroCompleto) {
            if (usuario.isAdmin)
                return next('/conta/assinatura?action=nf')

            return next('/erro/dados-incompletos')
        }
    }

    next()
})