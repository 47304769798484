<template>
    <div>
        <div class="phone-row" v-for="(telefone, i) in items" :key="i">
            <AppTelefone :ref="'fone' + i"
                label="Telefones"
                v-model="items[i]"
                @change="emit"
                shareText="Olá"
                :disabled="disabled"
                :hide-details="disabled && items.indexOf(telefone) !== items.length -1"
                v-bind="$attrs" />
            <v-btn icon v-if="i==0">
                <v-icon color="primary" @click="append()">mdi-plus-circle-outline</v-icon>
            </v-btn>
            <v-btn icon class="btn-remove mb-n6" v-else>
                <v-icon color="grey" @click="removeAt(i)">mdi-close-circle-outline</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {
        distinct
    } from '@/shared/functions'

    export default {
        name: 'contato-telefone-lista',
        props: {
            value: Array,
            disabled: Boolean
        },
        data () {
            return {
                items: this.getItemsFromValue()
            }
        },
        computed: {
            lastFone () {
                let lastIndex = this.items.length - 1

                return this.items[lastIndex]
            },
            lastRef () {
                let lastRefIndex = this.items.length - 1
                let lastRefName = 'fone' + lastRefIndex
                let lastRef = this.$refs[lastRefName][0]

                return lastRef
            }
        },
        methods: {
            emit () {
                this.$emit('input', this.items.filter(distinct))
            },
            append () {
                if (this.lastFone !== '') {
                    this.items.push('')
                }
                this.$nextTick(() => this.lastRef.focus())
            },
            removeAt (index) {
                this.items.splice(index, 1)
                this.emit()

                if (this.items.length === 0) {
                    this.items = ['']
                    this.$nextTick(() => this.lastRef.focus())
                }
            },
            getItemsFromValue () {
                return this.value && this.value.length ? this.value.slice() : ['']
            }
        },
        watch: {
            value () {
                this.items = this.getItemsFromValue()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .phone-row:not(:first-child) ::v-deep {
        .v-input__prepend-outer,
        .v-label {
            opacity: 0;
        }

        .v-text-field {
            padding-top: 0;
        }
    }

    .phone-row {
        align-items: baseline;
        display: flex;
        flex-direction: row;
    }

    ::v-deep {
        .v-input__prepend-outer {
            margin-top: 0;
        }
    }
</style>